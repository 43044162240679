import React, { useState } from "react"

const ListItem = (props)=>{

   const [activeDelete, setActiveDelete]=useState(false);

   const RenderDelete=()=>{
      if(activeDelete)
      {
          return <>
          <button className="btn btn-sm btn-danger" onClick={props.delete}>Delete ?</button>&nbsp;
          <button className="btn btn-sm btn-primary" onClick={()=>setActiveDelete(false)}>Cancel</button>
          </>
      }

      return <i className="fa fa-trash-alt" title="Delete" onClick={()=>setActiveDelete(true)}></i>
   }

    return(
        <li><div className="row">
              <div className="col-9">
                  <span className="handle" style={{float:"left"}}>
                    <i className={`${props.listIcon || "fas fa-ellipsis-v"}`}></i>
                    {/* <i className="fas fa-ellipsis-v"></i> */}
                  </span>
                  
                  <span className="text">{props.title}
                    {props.subtitle && <p className="text-muted" style={{fontSize:"13px"}}>{props.subtitle}</p>}
                    </span>
              </div>
              {/* <div className="col-4">
                   {props.component}
              </div> */}
              <div className="col-3">
                {props.badge && <small className="badge badge-danger"><i className="far fa-clock"></i> 2 mins</small> }
                <div className="tools">
                  {props.edit && !activeDelete && <i className="fas fa-edit" title="Change" onClick={props.edit}></i>}
                  {props.delete && <RenderDelete/>}
                </div>
              </div>
        </div>
           
     </li>
    )
}

export default ListItem