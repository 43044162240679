/* eslint-disable import/no-anonymous-default-export */
import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('eschool@user');
const apiHost=dictionary.apiHost

export default {


   async getClassLevels() {
     try {
       let response = await fetch(apiHost + 'class/level/list',
         {
           method: 'GET',
           headers: {
               'Authorization': 'Bearer ' + ACCESS_TOKEN,
               'Accept': 'application/json',
               'Content-Type': 'application/json'
           }
         });
       let result = await response.json();
       return result;
     } catch (error) {
       let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
       return connError;
     }
   },

   async createClassLevel(level_name) {
     let data = {
       level: level_name
     }
     try {
       let response = await fetch(apiHost + 'class/level/add',
         {
           method: 'POST',
           headers: {
               'Authorization': 'Bearer ' + ACCESS_TOKEN,
               'Accept': 'application/json',
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
         });
       let result = await response.json();
       return result;
     } catch (error) {
       let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
       return connError;
     }
   },

   async updateClassLevel(level_id, level_name) {
    let data = {
      level: level_name,
      level_id: level_id
    }
    try {
      let response = await fetch(apiHost + 'class/level/update',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getClassRooms() {
    try {
      let response = await fetch(apiHost + 'classes/list',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async createClass(level_id, class_name) {
    let data = {
      level_id: level_id,
      class_name: class_name
    }
    try {
      let response = await fetch(apiHost + 'class/add',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },
   
  async updateClass(class_id, level_id, class_name) {
    let data = {
      level_id: level_id,
      class_name: class_name,
      class_id: class_id
    }
    try {
      let response = await fetch(apiHost + 'class/update',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getClassStreams(class_id) {

    let data = {
      class_id: class_id
    }
    try {
      let response = await fetch(apiHost + 'class/stream/list',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async createStream(class_id, stream_name) {
    let data = {
      class_id: class_id,
      stream: stream_name
    }
    try {
      let response = await fetch(apiHost + 'class/stream/add',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },
 

  async updateStream(stream_id, stream_name) {
    let data = {
      stream_id: stream_id,
      stream: stream_name
    }
    try {
      let response = await fetch(apiHost + 'class/stream/update',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getTerms() {
    try {
      let response = await fetch(apiHost + 'term/list',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async createTerm(term_name) {
    let data = {
      term: term_name
    }
    try {
      let response = await fetch(apiHost + 'term/add',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },
  
  async updateTerm(term_id, term_name) {
    let data = {
      term: term_name,
      term_id: term_id
    }
    try {
      let response = await fetch(apiHost + 'term/update',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getExams() {
    try {
      let response = await fetch(apiHost + 'termly_exam/list',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async createAssessment(exam_name, description, assessment_type) {
    let data = {
      exam_name : exam_name,
      description : description,
      assessment_type : assessment_type
    }
    try {
      let response = await fetch(apiHost + 'termly_exam/add',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async updateAssessment(exam_id, exam_name, description, assessment_type) {
    let data = {
      exam_name : exam_name,
      description : description,
      exam_id : exam_id,
      assessment_type : assessment_type

    }
    try {
      let response = await fetch(apiHost + 'termly_exam/update',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getCurrentPeriod() {
    try {
      let response = await fetch(apiHost + 'period/current',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getCurrentExams() {
    try {
      let response = await fetch(apiHost + 'period/class/list',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async setCurrentPeriod(term_id, startDate, endDate) {

    let data = {
      term_id : term_id,
      start_date : startDate,
      end_date : endDate
    }
    try {
      let response = await fetch(apiHost + 'period/set',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getSubjects() {
    try {
      let response = await fetch(apiHost + 'subject/list',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getSubjectCategories() {
    try {
      let response = await fetch(apiHost + 'subject/category/list',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getSubjectTypes() {
    try {
      let response = await fetch(apiHost + 'subject/type/list',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },


  async createSubject(subject, level_id, category_id, type_id) {
    let data = {
      subject : subject,
      level_id : level_id,
      category_id : category_id,
      type_id : type_id
    }
    try {
      let response = await fetch(apiHost + 'subject/add',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async updateSubject(subjectId, subject, level_id, category_id, type_id) {
    let data = {
      subject_id : subjectId,
      subject : subject,
      level_id : level_id,
      category_id : category_id,
      type_id : type_id
    }
    try {
      let response = await fetch(apiHost + 'subject/update',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async createSubjectPaper(subject_id, paper_code) {
    let data = {
      subject_id : subject_id,
      paper_code : paper_code
    }
    try {
      let response = await fetch(apiHost + 'subject/paper/add',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async deletePaper(paper_id) {
    let data = {
      paper_id : paper_id,
    }
    try {
      let response = await fetch(apiHost + 'subject/paper/delete',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async updateSubjectPaper(paper_id, paper_code) {
    let data = {
      paper_id : paper_id,
      paper_code : paper_code
    }
    try {
      let response = await fetch(apiHost + 'subject/paper/update',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getSubjectPapers(subject_id) {
    let data = {
      subject_id : subject_id,
    }
    try {
      let response = await fetch(apiHost + 'subject/paper/list',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getSubjectUnits(subject_id) {
    let data = {
      subject_id : subject_id,
    }
    try {
      let response = await fetch(apiHost + 'subject/unit/list',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async createSubjectUnit(subject_id, title, description) {
    let data = {
      subject_id : subject_id,
      title : title,
      description : description
    }
    try {
      let response = await fetch(apiHost + 'subject/unit/add',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },


  async updateSubjectUnit(unit_id, title, description) {
    let data = {
      unit_id : unit_id,
      title : title,
      description : description
    }
    try {
      let response = await fetch(apiHost + 'subject/unit/update',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async deleteSubjectUnit(unit_id) {
    let data = {
      unit_id : unit_id,
    }
    try {
      let response = await fetch(apiHost + 'subject/unit/delete',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },
  

  async getAllSubjectPapers() {
    let data = {
      source : "admin",
    }
    try {
      let response = await fetch(apiHost + 'subject/paper/list/all',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async setCurrentExam(class_id, exam_id) {

    let data = {
      class_id : class_id,
      exam_id : exam_id,
    }
    try {
      let response = await fetch(apiHost + 'period/class/set',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async countSubjects() {
    let data = {
      source : "admin",
    }
    try {
      let response = await fetch(apiHost + 'subject/count',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async countClasses() {
    let data = {
      source : "admin",
    }
    try {
      let response = await fetch(apiHost + 'class/count',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async createUserRole(role_name) {
    let data = {
      role_name : role_name,
    }
    try {
      let response = await fetch(apiHost + 'user/role/add',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getUserRoles() {
    try {
      let response = await fetch(apiHost + 'user/role/list',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async countUsers() {
    try {
      let response = await fetch(apiHost + 'user/count',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getUsers() {
    try {
      let response = await fetch(apiHost + 'user/list',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async createUser(data) {
    
    try {
      let response = await fetch(apiHost + 'user/create',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async countClassLevels() {
    try {
      let response = await fetch(apiHost + 'class/level/count',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async countTerms() {
    try {
      let response = await fetch(apiHost + 'term/count',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async countTests() {
    try {
      let response = await fetch(apiHost + 'termly_exam/count',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getSchoolInfo() {
    try {
      let response = await fetch(apiHost + 'school/info',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async updateSchoolInfo(data) {
   
    let response = await apiCall("school/update", data);
    return response;
  },

  async fetchAssessmentTypes(){
    let response = await apiCall("assessment/type/list");
    return response;
  },

  async fetchResidenceListing(){
    let response = await apiCall("residence/list");
    return response;
  },

  async updateSignature(data){
    let response = await apiCall("school/signature/update", data);
    return response;
  }




}