/* eslint-disable import/no-anonymous-default-export */
import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('eschool@user');
const apiHost=dictionary.apiHost

export default {

    async getReports(data) {
        
      try {
        let response = await fetch(apiHost + 'report/list',
          {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },

    async createReport(data) {
        
        try {
          let response = await fetch(apiHost + 'report/create',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

    async generateReport(data) {
        try {
          let response = await fetch(apiHost + 'report/generate',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

    async listReportCommentConfig() {

        const response = await apiCall("report/conf/comments/list", []);
        return response;

      },

      async getReportComments(reportId) {

        let data={
          "report_id":reportId
        }
        
        try {
          let response = await fetch(apiHost + 'report/comments/list',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async addReportComment(report_id, comment_id) {

        let data={
          "report_id":report_id,
          "comment_id":comment_id
        }
        
        try {
          let response = await fetch(apiHost + 'report/comments/add',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async writeReportComment(report_id, comment_id, student_id, comment) {

        let data={
          "report_id":report_id,
          "comment_id":comment_id,
          "comment_value":comment,
          "student_id":student_id
        }
        try {
          let response = await fetch(apiHost + 'report/comment/write',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async writeGeneralReportComment(field_id, comment) {

        let data={
          "field_id":field_id,
          "comment_value":comment,
        }
        try {
          let response = await fetch(apiHost + 'report/comment/general/write',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async deleteReportCommentField(field_id) {

        let data={
          "field_id":field_id
        }
        try {
          let response = await fetch(apiHost + 'report/comment/delete',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },
      
      async getCurrentReports() {
        try {
          let response = await fetch(apiHost + 'report/current',
            {
              method: 'GET',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              }
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async generateMarksheet(data) {
        
        try {
          let response = await fetch(apiHost + 'mark/marksheet',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },


      async updateReport(data) {
        
        try {
          let response = await fetch(apiHost + 'report/update',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },


      async addReportInitials(report_id, teacher_id, paper_id) {

        let data={
          "report_id":report_id,
          "teacher_id":teacher_id,
          "paper_id" : paper_id
        }
        
        try {
          let response = await fetch(apiHost + 'report/initials/add',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },


      async callCountAllReports(data){
         const response = apiCall("report/count/all", data);
         return response
      },

      async callCountCurrentReports(data){
        const response = apiCall("report/count/current", data);
        return response
     },

     async fetchStudentReports(data){
      const response = apiCall("report/student/list", data);
      return response
    },

    async configureNewComment(data){
      const response = apiCall("report/config/comment", data);
      return response
    },

    async configUpdateComment(data){
      const response = apiCall("report/config/comment/update", data);
      return response
    },

    async syncSchoolFees(classId){
      const response = apiCall("student/balance/sync", {class_id:classId});
      return response
    },


    async addCommentStaff(reportCommentId, staffId){
      const response = apiCall("report/comment/staff/update", {report_comment_id:reportCommentId, staff_id:staffId});
      return response
    }


}