import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ConfigContext from '../../Context/ConfigContext';
import MarksContext from '../../Context/MarksContext';
import ActivityLoader from '../Common/ActivityLoader';
import CheckList from '../Common/List/CheckList';
import RadioList from '../Common/List/RadioList';

const MarksFilter =(props)=>{
   
    const { currentExams, allSubjects, allPapers } = useContext(ConfigContext);
    const {classId, paperId, setClassId, setPaperId } = useContext(MarksContext);
    

    const classList=[];
    Array.isArray(currentExams) && currentExams.map((item, key)=>{
        let examTitle = item.exam?item.exam.exam.exam_name : "Not set";
        classList.push({
            label:item.class.class_name + " (" + examTitle  + ")", 
            id:item.class.class_id
        })
    })

    const subjectList = [];
    Array.isArray(allSubjects) && allSubjects.map((item, key)=>{
        subjectList.push({label:item.subject, id:item.subject_id})
    })

    const paperList = [];
    Array.isArray(allPapers) && allPapers.map((item, key)=>{
        paperList.push({
            label:item.subject.subject + " (" + item.paper_code + ")", 
            id:item.paper_id
        })
    })

        return (
            <div className="col-xl-3 col-lg-12 d-none d-xl-block custom-leftnav">
                <div className="main-content-left-components">
                  {!currentExams && <ActivityLoader/>}  
                 
                     <RadioList 
                        title="Subjects" 
                        id="subject-papers"
                        list={paperList}
                        onSelect={setPaperId}
                        activeId={paperId}
                        />

                        <RadioList 
                            title="Classes" 
                            id="class"
                            list={ classList }
                            onSelect={setClassId}
                            activeId={classId}
                            />
                    



                    {/* <RadioList 
                        title="Streams" 
                        id="class_streams"
                        list={
                                [
                                    {label:"S.1 East", id:"1"},
                                    {label:"S.1 West", id:"2"}
                                ]
                            }
                        /> */}

                    
                   


                </div>
        </div>
        );
    
}

export default MarksFilter;