import React, { useEffect, useState } from 'react';
import ajaxStudent from '../util/remote/ajaxStudent';

const StudentContext = React.createContext();

export const StudentConsumer=StudentContext.Consumer;

export const StudentProvider=(props)=> {

    const [studentList, setStudentList] = useState(false);
    const [studentPopulation, setStudentPopulation] = useState(false) 
    
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1", "status":"1"})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);


     useEffect(()=>{
       getStudentPopulation();
     }, [])
       

     useEffect(()=>{
        getStudents();
     }, [data])
 
     useEffect(()=>{
         if(currentPage>1)
         {
             fetchMoreTrigger()
         }
     }, [currentPage])
 
    
 
     const getStudents=async()=>{
         const server_response = await ajaxStudent.getStudents(data);
         if(server_response.status==="OK")
         {
            setStudentList(server_response.details.list);
            setMetaData(server_response.details.meta);
         }else{
             setStudentList("404");
             setMetaData(false);
         }
     }

     


     const getStudentPopulation=async()=>{
      const server_response = await ajaxStudent.getStudentPopulation()
      if(server_response.status==="OK")
      {
         setStudentPopulation(server_response.details)
      }
  }




  const fetchMoreTrigger=()=>{
    const newData={
        ...data,
        "page":currentPage,
    }
    fetchMore(newData)
}


const fetchMore = async(newData) => {
    setLoading(true);

    const server_response = await ajaxStudent.getStudents(newData);

    setLoading(false);
       

    if (server_response.status === "OK") {
        const incoming = Array.from(server_response.details.list);
        setStudentList(studentList.concat(incoming));
        setMetaData(server_response.details.meta);
    }
   
}


const goToNextPage=()=>{
    if(currentPage < metaData.total_pages){
        setCurrentPage(currentPage + 1);
    }
}




  const resetData=()=>{
    setCurrentPage(1)
    setData({"page":"1"})
  }

    const filterData=(options)=>{
        setData({
            "class_id":options.studentClass,
            "stream_id":options.studentStream,
            "residence_id":options.studentResidence,
            "sex":options.gender,
            "page":"1"
        })
        
    }





     
        return (
           <StudentContext.Provider value={
                {
                  studentList,
                  studentPopulation,
                  metaData,
                  loading,
                  goToNextPage,
                  setData,
                  resetData,
                  filterData,
                  getStudents
                }
               }>
               {props.children}
           </StudentContext.Provider>
        );
    
}

export default StudentContext;