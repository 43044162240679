import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import InitialAvatar from '../../../Components/Common/InitialAvatar';
import ConfigMenu from '../../../Components/Config/ConfigMenu';
import EditSchoolProfile from '../../../Components/Config/SchoolProfile/EditSchoolProfile';
import NewUser from '../../../Components/Config/User/NewUser';
import PageContainer from '../../../Components/Headings/PageContainer';
import ConfigContext, { ConfigConsumer } from '../../../Context/ConfigContext';
import functions from '../../../util/functions';
import EditSignature from '../../../Components/Config/SchoolProfile/EditSignature';

class SchoolProfilePage extends Component {

    static contextType = ConfigContext;

    state={
        currentRecord:false,
        transactionId:'',
        mdlCrud:false
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }

    editRecord=()=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <EditSchoolProfile refresh={props.getSchoolProfile}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-edit-school"))
        })
    }


    editSignature=()=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <EditSignature refresh={props.getSchoolProfile}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-edit-signature"))
        })
    }


    

    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                    <li>
                        <Link to="#" className="btn ripple btn-success mb-1" onClick={this.editRecord}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;Edit Profile</Link>
                    </li>
                    <li>
                        <Link to="/configurations" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    

    

    render() {
        const { schoolProfile } = this.context;
        return (
            <PageContainer 
                title="School Profile"
                history={{title:"Configurations", link:"/configuration"}}
                headerNav={<this.RenderControls/>}
                >
                        {this.state.mdlCrud}
                        <div className="row">
                            <div className='col-md-9 col-sm-12'>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage your school details: these appear on the report letter heads</h3>
                                    </div>
                                    <div className="card-body">
                                        {!schoolProfile && <ActivityLoader/> }

               {schoolProfile && <div className='row'> {/** container opens */}

                            <div className="col-lg-4 col-md-12 mt-2">
							<div className="card custom-card">
								<div className="card-body text-center">
									<div className="main-profile-overview widget-user-image text-center">
										<div className="main-img-user">
                                            {!schoolProfile.badge && <InitialAvatar value="SP"/>}
											
											{schoolProfile.badge && <img 
													alt="badge"
													src={schoolProfile.badge}
													className="pointer"												
                                                />}
                                            
										</div>
									</div>
									<div className="item-user pro-user">
										<h4 className="pro-user-username text-dark mt-2 mb-0">{schoolProfile.initials}</h4>
										<p className="pro-user-desc text-muted mb-1">{schoolProfile.telephone}</p>
										
										 {/* <Link to="#" style={{color:"#FFFFFF"}}
											onClick="" 
										 	className="btn ripple btn-primary btn-sm">
												 <i className="far fa-edit mr-1"></i>Edit</Link> */}

											
									

									</div>

							  </div>
								
							</div>


							<div className="card custom-card">
								<div className="card-header custom-card-header">
									<div>
										<h6 className="card-title mb-0">Population Summary</h6>
									</div>
								</div>
								<div className="card-footer p-2">
									<div className="row text-center">
										<div className="col-sm-6 border-right">
											<div className="description-block">
		  										<h5 className="description-header mb-1">00</h5>
												<span className="text-muted">Students</span>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="description-block">
		 									 <h5 className="description-header mb-1">00</h5>
												<span className="text-muted">Staff</span>
											</div>
										</div>
									</div>

								</div>
							</div>

                            <div className="card custom-card">
                            <div className="card-body text-center">

                                <div className="main-profile-overview widget-user-image text-center">
										<div className="main-img-user">
                                            {!schoolProfile.sign && <InitialAvatar value="S"/>}
											
											{schoolProfile.sign && <img 
													alt="signature"
													src={schoolProfile.sign}
													className="pointer"												
                                                />}
                                            
										</div>
									</div>


                                    <div className="item-user pro-user text-center">
										<p className="pro-user-desc text-muted mb-1">{schoolProfile.signature_title}</p>
										
										 <Link to="#" style={{color:"#FFFFFF"}}
											onClick={this.editSignature} 
										 	className="btn ripple btn-primary btn-sm">
												 <i className="far fa-edit mr-1"></i>Edit</Link>


									</div>

                            </div>

                            </div>


						
                        
                        </div>{/** Left */}

                        <div className="col-lg-8 col-md-12 mt-2">




                                
                        <div className="card custom-card">
                            <div className="">
                            <div className="main-content-body main-content-body-contacts">
                               
                                <div className="main-contact-info-body">
                                
                                <div className="media-list pb-lg-0">
                                    <div className="media">
                                            <div className="media-body">
                                                <div>
                                                <label>School Name</label> 
                                                <span className="tx-medium">{schoolProfile.school_name}</span>
                                                </div>
                                              
                                            </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                            <label>Address</label> 
                                            <span className="tx-medium">{schoolProfile.address}</span>
                                            </div>
                                            <div>
                                            <label>Telephone</label> 
                                            <span className="tx-medium">{schoolProfile.telephone}</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="media">
                                            <div className="media-body">
                                                <div>
                                                <label>School Motto</label> 
                                                <span className="tx-medium">{schoolProfile.motto}</span>
                                                </div>
                                              
                                            </div>
                                    </div>


                                    <div className="media">
                                            <div className="media-body">
                                                <div>
                                                <label>Website</label> 
                                                <span className="tx-medium">{schoolProfile.website}</span>
                                                </div>
                                              
                                            </div>
                                    </div>


                                    <div className="media">
                                            <div className="media-body">
                                                <div>
                                                <label>Email Address</label> 
                                                <span className="tx-medium">{schoolProfile.email_address}</span>
                                                </div>
                                              
                                            </div>
                                    </div>

                                    <div className="media">
                                            <div className="media-body">
                                                <div>
                                                <label>Short Name</label> 
                                                <span className="tx-medium">{schoolProfile.short_name}</span>
                                                </div>
                                              
                                            </div>
                                    </div>


                                    </div>

                                    </div>
                                    </div>
                                    </div>

                                    
                        </div>







                        </div>{/** Right */}
                  

                </div>} {/** container closes */}








                                    </div>
                                </div>
                        

                            </div>

                            <ConfigMenu/>
                       
                         
                        </div>

                     
        </PageContainer>
        );
    }
}

export default SchoolProfilePage;