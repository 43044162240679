import React, { Component } from 'react';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class NewSubject extends Component {

    state={
        subjectName:'',
        levelId:'',
        categoryId:'',
        typeId:'',
        info:false
    }


    onChangeSubject=(event)=>{
        this.setState({
            subjectName:event.target.value
        })
    }

    onChangeLevel=(option)=>{
        this.setState({
            levelId:option.value
        })
    }

    onChangeCategory=(option)=>{
        this.setState({
            categoryId:option.value
        })
    }

    onChangeType=(option)=>{
        this.setState({
            typeId:option.value
        })
    }
   

    RenderLevel=()=>{

        return(<ConfigConsumer>

            {props=>{

                const optionsListing=[];

                if(Array.isArray(props.classLevels))
                {
                    props.classLevels.map((item, key)=>
                    optionsListing.push({label:item.level, value:item.level_id})
                    )
                 }

                return(
                    <TextInput
                        label="Subject level"
                        leftIcon="fa fa-exchange"
                        type="select" 
                        placeholder="Select subject level...." 
                        options={optionsListing}
                        onChange={this.onChangeLevel}
                        leftComponent={<i className="fa fa-book"/>}
                        wrap
                    />
                )

            }}

        </ConfigConsumer>)
       
        }


        RenderCategory=()=>{

            return(<ConfigConsumer>
    
                {props=>{
    
                    const optionsListing=[];
    
                    if(Array.isArray(props.subjectCategories))
                    {
                        props.subjectCategories.map((item, key)=>
                        optionsListing.push({label:item.category, value:item.category_id})
                        )
                     }
    
                    return(
                        <TextInput
                            label="Subject Category"
                            leftIcon="fa fa-branch"
                            type="select" 
                            placeholder="Select subject category...." 
                            options={optionsListing}
                            onChange={this.onChangeCategory}
                            leftComponent={<i className="fa fa-tree"/>}
                            wrap
                        />
                    )
    
                }}
    
            </ConfigConsumer>)
           
            }

        
        RenderType=()=>{

                return(<ConfigConsumer>
        
                    {props=>{
        
                        const optionsListing=[];
        
                        if(Array.isArray(props.subjectTypes))
                        {
                            props.subjectTypes.map((item, key)=>
                            optionsListing.push({label:item.type_name, value:item.type_id})
                            )
                         }
        
                        return(
                            <TextInput
                                leftIcon="fa fa-bookmark"
                                label="Subject Type"
                                type="select" 
                                placeholder="Select subject type...." 
                                options={optionsListing}
                                onChange={this.onChangeType}
                                leftComponent={<i className="fa fa-bookmark"/>}
                                wrap
                            />
                        )
        
                    }}
        
                </ConfigConsumer>)
               
                }
    
   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {subjectName, levelId, categoryId, typeId}=this.state;
        if(subjectName.length>0 && levelId>0 && categoryId>0 && typeId>0)
        {
            const server_response = await ajaxConfig.createSubject(subjectName, levelId, categoryId, typeId);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>,
                    subjectName:''
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {
        return (
            
    <SystemModal id="mdl-new-subject" title="New Subject">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="Subject Name"
                            placeholder="Subject Name"
                            leftIcon="fa fa-bookmark"
                            value={this.state.subjectName}
                            onChange={this.onChangeSubject} 
                            required
                            wrap
                            />
               <this.RenderLevel/>
               <this.RenderCategory/>
               <this.RenderType/>

            <div className="row">&nbsp;</div>


       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Subject</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default NewSubject;