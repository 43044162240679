import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import ReportContext from "../../Context/ReportContext";
import ajaxReport from "../../util/remote/ajaxReport";
import ActivityLoader from "../Common/ActivityLoader"
import ItemNotFound from "../Common/ItemNotFound";

const StudentReports = props => {

    const [termlyReports, setTermlyReports] = useState(false);
    const { setCurrentReportId, setStudentId} = useContext(ReportContext)


    useEffect(()=>{
        setStudentId(props.studentId)
        getStudentReports()
    }, [props.studentId])

    const onChangeReport=(reportId)=>{

        setCurrentReportId(reportId)
    }

    const  getStudentReports=async()=>{

        const server_response = await ajaxReport.fetchStudentReports({
            "student_id":props.studentId,
            "page":"1"
        })

        if(server_response.status==="OK"){
            setTermlyReports(server_response.details.list)
        }else{
            setTermlyReports("404")
        }
    }

    return(
        <div className="card custom-card">
        <div className="card-body">
            <div>
                <h6 className="card-title mb-1">Academic Reports</h6>
            </div>
        </div>
        <div className="card-body scroll-widget overflow-auto border-top" style={{maxHeight:"250px"}}>
            <div className="activity-block">
                {!termlyReports && <ActivityLoader/>}
                <ul className="task-list">
                    
                    {Array.isArray(termlyReports) && termlyReports.map((item, key)=><li key={key}>
                        <i className="task-icon bg-success"></i>
                        <h6 onClick={()=>onChangeReport(item.id)}><Link to="#">{item.title}</Link>
                        <small className="float-right text-muted tx-11">{item.report_date.short_date}</small></h6>
                        <span className="text-muted tx-12">{item.class.class_name + " Term " + item.term.term + " " + item.year}</span>
                    </li>)}
                
                </ul>

                {termlyReports==="404" && <ItemNotFound description="No student report found"/>}

            </div>
        </div>
    </div>
    )

}


export default StudentReports