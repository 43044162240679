import React, { Component } from 'react';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class EditSubjectUnit extends Component {

    state={
        unitName:this.props.record.title,
        unitDescription:this.props.record.description,
        unitId:this.props.record.unit_id,
        info:false
    }


    onChangeTitle=(event)=>{
        this.setState({
            unitName : event.target.value
        })
    }

  
    onChangeDescription=(event)=>{
        this.setState({
            unitDescription : event.target.value
        })
    }
   
   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {unitName, unitDescription, unitId}=this.state;
        if(unitName.length>0 && unitId>0 && unitDescription.length>0)
        {
            const server_response = await ajaxConfig.updateSubjectUnit(unitId, unitName, unitDescription);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {
        return (
            
    <SystemModal id="mdl-new-sunit" title="Update Subject Unit">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="Unit Name"
                            placeholder="Unit Name"
                            leftIcon="fa fa-bookmark"
                            value={this.state.unitName}
                            onChange={this.onChangeTitle} 
                            required
                            wrap
                            />


             <TextInput
                            type="textarea"
                            label="Unit Description"
                            placeholder="Unit Description"
                            leftIcon="fa fa-bookmark"
                            value={this.state.unitDescription}
                            onChange={this.onChangeDescription} 
                            required
                            wrap
                            />
    
    

            <div className="row">&nbsp;</div>


       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Update Unit</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default EditSubjectUnit;