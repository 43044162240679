/* eslint-disable import/no-anonymous-default-export */
import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('eschool@user');
const apiHost=dictionary.apiHost

export default {

    async getOrganiseMarksList(class_id, paper_id) {
        let data={
            class_id:class_id,
            paper_id:paper_id
        }
      try {
        let response = await fetch(apiHost + 'marks/organise/list',
          {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },

    async organiseMarks(student_list, class_id, paper_id) {
        let data={
            student_list:student_list,
            class_id:class_id,
            paper_id:paper_id
        }
      try {
        let response = await fetch(apiHost + 'marks/organise',
          {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },

  
    async organiseAllSubjectStudents(class_id, paper_id) {

      let data={
          class_id:class_id,
          paper_id:paper_id
      }

      const server_response = await apiCall("marks/organise/subject", data);
      return server_response;

  },

  async clearAllSubjectStudents(class_id, paper_id) {
    let data={
        class_id:class_id,
        paper_id:paper_id
    }
  try {
    let response = await fetch(apiHost + 'marks/organise/subject/clear',
      {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
    let result = await response.json();
    return result;
  } catch (error) {
    let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
    return connError;
  }
},


    async removeStudent(mark_id) {
        let data={
            mark_id:mark_id,
        }
      try {
        let response = await fetch(apiHost + 'marks/remove',
          {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },

    async getMarksEntrySheet(data) {
     
      try {
        let response = await fetch(apiHost + 'marks/entry/sheet',
          {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },

    async enterMark(data) {
     
      let response = await apiCall("mark/add", data);
      return response;
    },

    async organiseFromHistory(class_id) {
      
      let data={
          class_id:class_id,
      }

      const server_response = await apiCall("marks/organise/history", data);
      return server_response;

  },

}