import React, { Component, useContext, useEffect, useRef, useState } from 'react';
import ConfigContext from '../../Context/ConfigContext';
import dictionary from '../../util/dictionary';
import ajaxStudent from '../../util/remote/ajaxStudent';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';


const PopEditContact=(props)=>{

    const [info, setInfo] = useState(false);
    const [contactGroup, setContactGroup] = useState({"label":props.record.contact_type.type_name, value:props.record.contact_type.id})
    const phoneRef = useRef();
    const nameRef = useRef();
    const groupRef = useRef();
    const priorityRef = useRef();
    const { contactTypes } = useContext(ConfigContext);

    useEffect(()=>{
        if(info){
            setTimeout(() => {
                setInfo(false)
            }, 6000);
        }
    }, [info])


    useEffect(()=>{
        initializeValues()
    }, [props.record])
   
    const initializeValues=()=>{
        phoneRef.current.value = props.record.contact;
        nameRef.current.value = props.record.contact_name;
    }
   
    const confirmSave=async(event)=>{
        event.preventDefault();
  
        const phoneNumber = phoneRef.current.value;
        const contactName = nameRef.current.value;
        const contactType = groupRef.current.props.value || contactGroup;
        const priority = priorityRef.current.props.value.value || props.record.priority;
        const data={
                
            "contact_id":props.record.id,
            "phone_number":phoneNumber,
            "contact_name":contactName,
            "contact_type":contactType.value,
            "priority": priority
          
        }

       
        if(phoneNumber.length>9)
        {
            const server_response = await ajaxStudent.updateContact(data);
            if(server_response.status==="OK")
            {
                setInfo(<AlertSuccess message={server_response.message}/>)
                props.refresh();
            }else{
                setInfo(<AlertError message={server_response.message}/>)

            }

        }else{

            setInfo(<AlertError message={"Please enter correct contact number and try again"}/>)
          
        }
    }



    const RenderType=()=>{

        const optionContactTypes=[];
        if(Array.isArray(contactTypes)){
            contactTypes.forEach(element=>{
                optionContactTypes.push({
                    "label":element.type_name,
                    "value":element.id
                })
            })
        }
        
        return <TextInput
                    label="Contact Group"
                    type="select" 
                    leftIcon="fa fa-users"
                    placeholder={contactGroup.label} 
                    options={optionContactTypes}
                    reference={groupRef}
                    wrap
                    />

    }


        const RenderPriority=()=>{

            const optionPriority=[{"label":"Secondary contact", "value":"0"}, {"label":"Default contact", "value":"1"}];

        console.log(props.record.priority)
        return <TextInput
                    label="Contact Priority"
                    type="select" 
                    leftIcon="fa fa-check"
                    placeholder={props.record.priority*1>0?"Default Contact":"Secondary Contact"} 
                    options={optionPriority}
                    reference={priorityRef}
                    wrap
                    />
        
        }



   



    return (
            
    <SystemModal id="mdl-new-contact" title="Edit Student Contact">
    <form method="post" className="form-horizontal" onSubmit={confirmSave}>
               {info}
               <TextInput
                            type="text"
                            label="Contact Number"
                            placeholder="E.g. 07xxxxx or 2567xxxxx"
                            leftIcon="fa fa-phone"
                            reference={phoneRef}
                            required
                            wrap
                            />

            
                   <TextInput
                            type="text"
                            label="Contact Name"
                            placeholder="Contact Name"
                            leftIcon="fa fa-user"
                            reference={nameRef}
                            required
                            wrap
                            />
               

                   {RenderType()}
                   {RenderPriority()}
    

            <div className="row">&nbsp;</div>


       
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    
}

export default PopEditContact;