import React, { Component } from 'react';
import ConfigContext from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class NewUser extends Component {

    static contextType = ConfigContext;

    state={
        roleId:'',
        firstName:'',
        lastName:'',
        username:'',
        phoneNumber:'',
        info:false
    }


    setRoleId=(item)=>{
        this.setState({roleId:item.value})
    }

    handleFormChange=(event)=>{
        this.setState({
           [event.target.name]:event.target.value
        })
    }

   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {roleId, firstName, lastName, username, phoneNumber}=this.state;
        if(roleId>0 && firstName.length>0 && lastName.length>0 && username.length>0 && phoneNumber.length>0)
        {
            const server_response = await ajaxConfig.createUser({
                "username":username,
                "first_name":firstName,
                "last_name":lastName,
                "role_id":roleId,
                "phone_number":phoneNumber
            });
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>,
                    firstName:'',
                    lastName:'',
                    username:'',
                    phoneNumber:''
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    RenderRoles=()=>{
        const {userRoles} = this.context;
        const optionRoles=[];
        Array.isArray(userRoles) && userRoles.map((item, key)=>
            optionRoles.push({label:item.role_name, value:item.role_id})
        )
        return  <TextInput
        type="select"
        label="User Role"
        placeholder="Select User Role...."
        leftIcon="fa fa-user"
        onChange={this.setRoleId} 
        options={optionRoles}
        required
        wrap
        />
    }


    render() {
        return (
            
    <SystemModal id="mdl-new-user" title="New User">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="First Name"
                            placeholder="First Name"
                            leftIcon="fa fa-user"
                            value={this.state.firstName}
                            name="firstName"
                            onChange={this.handleFormChange} 
                            required
                            wrap
                            />

                <TextInput
                    type="text"
                    label="Last Name"
                    placeholder="Last Name"
                    leftIcon="fa fa-user"
                    value={this.state.lastName}
                    name="lastName"
                    onChange={this.handleFormChange} 
                    required
                    wrap
                    />


               <TextInput
                    type="text"
                    label="Username"
                    placeholder="Username"
                    leftIcon="fa fa-user"
                    value={this.state.username}
                    name="username"
                    onChange={this.handleFormChange} 
                    required
                    wrap
                    />


              <this.RenderRoles/>


                <TextInput
                    type="text"
                    label="Phone Number"
                    placeholder="Phone Number"
                    leftIcon="fa fa-phone"
                    value={this.state.phoneNumber}
                    name="phoneNumber"
                    onChange={this.handleFormChange} 
                    required
                    wrap
                    />
               
    

            <div className="row">&nbsp;</div>


       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-success">Generate User Creditentials</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default NewUser;