import React, { Component } from 'react';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class EditClasStream extends Component {

    state={
        streamName:this.props.record.stream_name,
        streamId:this.props.record.stream_id,
        info:false
    }


    onChangeStream=(event)=>{
        this.setState({
            streamName:event.target.value
        })
    }

  

   
   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {streamName, streamId}=this.state;
        if(streamName.length>0 && streamId>0)
        {
            const server_response = await ajaxConfig.updateStream(streamId, streamName);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {
        return (
            
    <SystemModal id="mdl-new-stream" title="Edit Class Stream">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="Stream Name"
                            placeholder="Stream Name"
                            leftIcon="fa fa-bookmark"
                            value={this.state.streamName}
                            onChange={this.onChangeStream} 
                            required
                            wrap
                            />
    

            <div className="row">&nbsp;</div>


       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default EditClasStream;