import React, { useEffect, useState } from "react"
import functions from "../../../util/functions"
import LetterHead from "../../Securities/LetterHead"
import StudentHeading from "../../Securities/StudentHeading"
import AOIKey from "../Elements/AOIKey"
import ClassTeacher from "../Elements/ClassTeacher"
import Disclaimer from "../Elements/Disclaimer"
import ReportRemarks from "../Elements/ReportRemarks"
import PopReportInitials from "../PopReportInitials"
//** BEST FOR NEW O'LEVEL CURRICULUM 2021: Includes format & Summative assessment */
const ReportFormatFour = (props) =>{

    const [mdl, setMdl] = useState(false);

    useEffect(()=>{
        if(mdl)
        {
          functions.openPopup("mdl-report-initials")
          
        }
      }, [mdl])


      const onClickInitials=(paper_id)=>{

            setMdl(false);

            setTimeout(() => {
                setMdl(
                <PopReportInitials
                    report_id={props.reportInfo.id}
                    paper_id={paper_id}
                />
                )

            }, 10);

      }


    return(
        <>
        {mdl}
        <div id="report-card" key={props.key}>
        
        <LetterHead 
            title={props.reportInfo.title}
            student={props.student.student_info}
            serial={props.student.serial_no}
            />

        <StudentHeading 
            studentInfo={props.student.student_info} 
            report={props.reportInfo} 
            total={props.totalStudents}
            />

      

        <table className="Reports" border="1" cellSpacing="0" width="100%" style={{border:"none", fontFamily:"Arial, Helvetica, sans-serif", fontSize:"13px"}}>
        
            <thead>
                <tr>
                    <th style={{borderTopStyle: "hidden", borderLeftStyle: "hidden"}}></th>
                    <th colSpan={props.reportInfo.report_test_types.formative.length + 3} style={{textAlign:"center"}}>FORMATIVE ASSESSMENT</th>
                    
                    <th colSpan={props.reportInfo.report_test_types.summative.length + 3} style={{textAlign:"center"}}>SUMMATIVE ASSESSMENT</th>
                </tr>
                <tr>
                <th>SUBJECT</th>
                {/* <th>PAPER</th> */}
                {Array.isArray(props.reportInfo.report_test_types.formative) && props.reportInfo.report_test_types.formative.map((item, key)=>
                    <th key={key} style={{textAlign:"center"}}>{item.test.exam_name}</th>
                )}
                <th style={{textAlign:"center"}}>AV SC</th>
                <th style={{textAlign:"center"}}>M/20</th>
                <th style={{textAlign:"center"}}>Identifier</th>

                {Array.isArray(props.reportInfo.report_test_types.summative) && props.reportInfo.report_test_types.summative.map((item, key)=>
                    <th key={key} style={{textAlign:"center"}}>{item.test.exam_name}/{item.out_of.total_c}</th>
                )}

                <th style={{textAlign:"center"}}>TTL/100</th>
                <th style={{textAlign:"center"}}>GD</th>
                <th style={{textAlign:"center"}}>TR</th>
                </tr>
            </thead>
            <tbody>
                {Array.isArray(props.student.student_marks.marks) && props.student.student_marks.marks.map((grade_record, grade_key)=> <>
                    
                    {Array.isArray(grade_record.grades.paper_listing) && grade_record.grades.paper_listing.map((item_paper, paper_key)=>
                    <tr key={grade_key}>

                        {paper_key===0 && <td rowSpan={grade_record.grades.total_papers}>{grade_record.subject.subject.toUpperCase()}</td>}

                        {/* <td key={paper_key}>{item_paper.paper_code}</td> */}
                        

                        

                        {/* Print FORMATIVE exam marks */}
                        {Array.isArray(props.reportInfo.report_test_types.formative) && props.reportInfo.report_test_types.formative.map((period_mark, period_mark_key)=>
                            <td key={period_mark_key} style={{textAlign:"center"}}>
                                { grade_record.grades.marks[period_mark.test_id] && grade_record.grades.marks[period_mark.test_id][item_paper.paper_id] &&
                                
                                <span>{grade_record.grades.marks[period_mark.test_id][item_paper.paper_id].mark}</span>}
                            </td>
                        )}

                        <td style={{textAlign:"center"}}><b>{grade_record.grades.subject_average}</b></td>

                        <td style={{textAlign:"center"}}><b>{grade_record.grades.school_contribution_mark[grade_record.subject.subject_id]}</b></td>
                       
                        {paper_key===0 && <td align="center" rowSpan={grade_record.grades.total_papers}><b> {grade_record.grades.advanced_grade[grade_record.subject.subject_id ]} </b></td>}
                       
                       
                        {/* Print SUMMATIVE exam marks */}
                        {Array.isArray(props.reportInfo.report_test_types.summative) && props.reportInfo.report_test_types.summative.map((period_mark, period_mark_key)=>
                            <td key={period_mark_key} style={{textAlign:"center"}}>
                                { grade_record.grades.marks[period_mark.test_id] && grade_record.grades.marks[period_mark.test_id][item_paper.paper_id] &&
                                
                                <span>{grade_record.grades.marks[period_mark.test_id][item_paper.paper_id].mark}</span>}
                            </td>
                            
                        )}
                       
                       <td style={{textAlign:"center"}}><b>{grade_record.grades.summative_plus_formative[grade_record.subject.subject_id]}</b></td>

                       <td style={{textAlign:"center"}}><b>{grade_record.grades.normal_grade[grade_record.subject.subject_id].grade}</b></td>
                        <td style={{textAlign:"center", cursor:"pointer"}} onClick={()=>onClickInitials(item_paper.paper_id)} >
                            {props.initials && props.initials[item_paper.paper_id] && props.initials[item_paper.paper_id].initials}
                        </td>
                    </tr>
                    )}

                    </>
                )}

                <tr>
                    <td>&nbsp;</td>
                    <td colSpan={props.reportInfo.report_test_types.formative.length} style={{textAlign:"center"}}><b>AVERAGE SCORE /PTS (OUT OF 20) / IDENTIFIER</b></td>
                    <td style={{textAlign:"center"}}><b>{props.student.student_marks.average}</b></td>
                    <td style={{textAlign:"center"}}><b>{props.student.student_marks.school_contribution}</b></td>
                    <td style={{textAlign:"center", background:"#000", color:"#FFF"}}><b>{props.student.student_marks.identifier.identifier}</b></td>
                    <td style={{textAlign:"center"}} colSpan={props.reportInfo.report_test_types.summative.length || 1}><b>TOTAL</b></td>
                    <td style={{textAlign:"center"}}><span style={{color:"#F00"}}><b>{props.student.student_marks.summative_total}</b></span></td>
                    <td style={{borderBottomStyle: "hidden", borderRightStyle: "hidden"}}>&nbsp;</td>
                    <td style={{borderBottomStyle: "hidden", borderRightStyle: "hidden"}}>&nbsp;</td>
                </tr>
               
            </tbody>

        </table>


        <div style={{float:"right", width:"82%"}}>
        
            <table className="Reports" border="1" cellSpacing="0" width="100%" style={{border:"solid", fontFamily:"Arial, Helvetica, sans-serif", fontSize:"13px", marginTop:"20px",  marginBottom:"10px"}}>
                <tbody>
                    <tr>
                        <td><i>Identifier</i></td>
                        <td style={{textAlign:"center"}}><b>{props.student.student_marks.identifier.identifier}</b></td>
                        <td><i>Overall Learner Achievement</i></td>
                        <td><b>{props.student.student_marks.identifier.comment}</b></td>
                    </tr>
                </tbody>
            </table>

           <AOIKey/>
        </div>

        <ReportRemarks remarks={props.student.report_comments} noBorders={true}/>
        {/* <GradingScale/> */}
        {/* <AdvancedScale/> */}
        {/* <ClassTeacher/> */}

        <Disclaimer/>
        <p style={{pageBreakAfter:"always"}}></p>
        <div className='row' style={{marginTop:"100px"}}></div>


</div>
</>
    )

}

export default ReportFormatFour