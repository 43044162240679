import React, { useEffect, useState } from 'react';
import ajaxStaff from '../util/remote/ajaxStaff';

const StaffContext = React.createContext();

export const StaffConsumer=StaffContext.Consumer;

export const StaffProvider=(props)=> {

    const [staffList, setstaffList] = useState(false);
    const [staffFilter, setstaffFilter] = useState({status_id:'1'});
    const [totalStaff, setTotalStaff] = useState(false) 
    
     useEffect(()=>{
       getStaff();
       getstaffPopulation();
     }, [])
       
    
 
     const getStaff=async()=>{
         getstaffPopulation();
         const server_response = await ajaxStaff.getStaff(staffFilter);
         if(server_response.status==="OK")
         {
            setstaffList(server_response.details)
         }else{
             setstaffList("404");
         }
     }

     const setFilter=(data)=>{
         setstaffFilter(data)
     }


     const getstaffPopulation=async()=>{
      const server_response = await ajaxStaff.getStaffPopulation()
      if(server_response.status==="OK")
      {
         setTotalStaff(server_response.details.total_p)
      }
  }

     
        return (
           <StaffContext.Provider value={
                {
                  staffList,
                  totalStaff,
                  setFilter,
                  getStaff
                }
               }>
               {props.children}
           </StaffContext.Provider>
        );
    
}

export default StaffContext;