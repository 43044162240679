import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import PageContainer from '../../Components/Headings/PageContainer';
import StudentFilter from '../../Components/Student/StudentFilter';
import StaffContext from '../../Context/StaffContext';

const ListStaffPage =(props)=> {

    const { staffList, setFilter, getStaff} = useContext(StaffContext);

   
    const RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                {/* <ol className='button-listing'>
                   
                    <li>
                        <Link to="#" className="btn ripple btn-success mb-1">
                        <i className="fa fa-edit mr-1"></i>&nbsp;Create Custom List</Link>
                    </li>
                    <li>
                        <Link to="/configuration/classes" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-bookmark mr-1"></i>&nbsp;Alumni</Link>
                    </li>
                </ol>
                */}
    
                
            </div>
          
        )
      }
    

    

   const RenderTransactions=()=>{
        return(
           
                        <div className="table-responsive table-ellipsis">
                       {Array.isArray(staffList) && <table className="table expandable-table table-striped text-nowrap mb-0">
                            <thead>
                                <tr>
                                <th>No.</th>                                   
                                <th>Reg No.</th>
                                <th>Staff Name</th>
                                <th>Sex</th>
                                <th>Phone Number</th>
                                <th>Initials</th>

                            </tr>
                        </thead>
                        <tbody>
                          {staffList.map((item, key)=>
                            <tr key={key}>
                                <td>{key +1}</td>
                                <td>{item.reg_no}</td>
                                <td>{item.last_name + " " + item.first_name}</td>
                                <td>{item.gender}</td>
                                <td>{item.contact}</td>
                                <td>{item.initials}</td>
                                <td></td>
                            </tr>
                          )}
            
                        </tbody>
                        </table>}
                       {!staffList && <ActivityLoader/>}
                    </div>
                    )
            
    }


        return (
            <PageContainer 
                title="Staff Listing"
                // history={{title:"Configurations", link:"/configuration"}}
                headerNav={<RenderControls/>}
                >
                        <div className="row">
                            <div className='col-md-9 col-sm-12'>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Create, view and manage staff lists</h3>
                                    </div>
                                    <div className="card-body">

                                    {RenderTransactions()}

                                    </div>
                                </div>
                        

                            </div>

                            {/* <StudentFilter/> */}
                       
                         
                        </div>

                     
        </PageContainer>
        );
}

export default ListStaffPage;