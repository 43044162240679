import React from 'react'
import { Link } from 'react-router-dom'
import functions from '../../util/functions';
import logo from '../../images/logo.png';
class LeftSideBar extends React.Component{

    state={
        userId:functions.sessionGuard(),
        sideBarInitiated:true,
        activeLocationPath:window.location.pathname,
        activeRoot:''
    }
   
    componentDidMount () {

        const pathSections=this.state.activeLocationPath.split("/");
        this.setState({
            activeRoot: "/" + pathSections[1]
        })
      }


      handleMenuClick=(e)=>{

        //   const trees = document.getElementsByClassName("has-treeview");
        //   for(var i=0; i<trees.length; i++){
        //       trees[i].classList.remove("menu-open");
        //       trees[i].classList.remove("menu-is-opening")
        //   }

        //   const activeMenu = document.getElementsByClassName("active");
        //   for(var k=0; k<activeMenu.length; k++){
        //     activeMenu[k].classList.remove("active");
        //   }

        // e.target.parentNode.classList.toggle("menu-open")
      }


    render()
    {
        // const accessInfo=functions.readPermissions();
        const activeRoot=this.state.activeRoot;
        const path=this.state.activeLocationPath;

        // const userInfo=functions.readUser();

        return(

            <aside className="main-sidebar sidebar-light-primary elevation-4">
            {/* Brand Logo */}
            <Link to="/" className="brand-link">
              <img src={logo} alt="Eschool" className="brand-image img-circle elevation-3" style={{opacity: ".8"}}/>
              <span className="brand-text font-weight-light">Eschool</span>
            </Link>
        
            {/* Sidebar */}
            <div className="sidebar">
              {/* Sidebar user panel (optional) */}
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  {/* <img src={userInfo.photo} className="img-circle elevation-2" alt=" "/> */}
                </div>
                <div className="info">
                  <Link to="#" className="d-block">System User</Link>
                </div>
              </div>
        
              {/* Sidebar Menu */}
              <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" onClick={this.handleMenuClick}>
                  {/* Add icons to the links using the .nav-icon class
                       with font-awesome or any other icon font library */}

                  <li className="nav-item">
                    <Link to="/" className={`nav-link ${(activeRoot==="/" || activeRoot==="/dashboard")?"active":""}`}>
                      <i className="nav-icon fas fa-tachometer-alt"></i>
                      <p>
                        Dashboard
                      </p>
                    </Link>
                  </li>

                  <li className={`nav-item has-treeview ${activeRoot==="/student"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/student")?"active":""}`}>
                      <i className="nav-icon fas fa-users"></i>
                      <p>
                        Students 
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/student/new" className="nav-link">
                          <i className="far fa-circl nav-icon"></i>
                          <p>New Student</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/student/listing" className="nav-link">
                          <i className="far fa-circl nav-icon"></i>
                          <p>Students Listing</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/student/contacts" className="nav-link">
                          <i className="far fa-circl nav-icon"></i>
                          <p>Contact Center</p>
                        </Link>
                      </li>
    
                    </ul>
                  </li>


                  <li className={`nav-item has-treeview ${activeRoot==="/staff"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/staff")?"active":""}`}>
                      <i className="nav-icon fas fa-briefcase"></i>
                      <p>
                        Staff
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/staff/new" className="nav-link">
                          <i className="far fa-circl nav-icon"></i>
                          <p>New Staff</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/staff/listing" className="nav-link">
                          <i className="far fa-circl nav-icon"></i>
                          <p>List Staff</p>
                        </Link>
                      </li>
    
                    </ul>
                  </li>
                
                  <li className={`nav-item has-treeview ${activeRoot==="/marks"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/marks")?"active":""}`}>
                      <i className="nav-icon fas fa-book"></i>
                      <p>
                        Academics
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/marks/new" className="nav-link">
                          <i className="far fa-circl nav-icon"></i>
                          <p>Enter Marks</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/marks/organise" className="nav-link">
                          <i className="far fa-circl nav-icon"></i>
                          <p>Organise Marks</p>
                        </Link>
                      </li>
                      
                      <li className="nav-item">
                        <Link to="/report/card" className="nav-link">
                          <i className="far fa-circl nav-icon"></i>
                          <p>Report Card</p>
                        </Link>
                      </li>
                     
    
                    </ul>
                  </li>


                  <li className={`nav-item has-treeview ${activeRoot==="/liability"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/liability")?"active":""}`}>
                      <i className="nav-icon fas fa-table"></i>
                      <p>
                        Timetable
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="#" className="nav-link">
                          <i className="far fa-circl nav-icon"></i>
                          <p>General Timetable</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="#" className="nav-link">
                          <i className="far fa-circl nav-icon"></i>
                          <p>Class Timetable</p>
                        </Link>
                      </li>
                     
    
                    </ul>
                  </li>



                  <li className="nav-item">
                    <Link to="/configuration" className={`nav-link ${(activeRoot==="/configuration")?"active":""}`}>
                      <i className="nav-icon fas fa-cog"></i>
                      <p>
                      Configurations
                      </p>
                    </Link>
                  </li>


                 
                
                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
          </aside>





          
          /*
            <div className="main-sidebar main-sidebar-sticky side-menu" 
                onMouseEnter={this.openSideBar}
                onMouseLeave={this.closeSideBar}
            >
            <div className="sidemenu-logo">
                <div className="touch-close-menu">
                    <i className="fas fa-align-left custom-menu-icon" onClick={this.toggleSideBar}></i>
                </div>
                <this.logos/>
            </div>

            <div className="main-sidebar-body">
                <ul className="nav">
                    <li className="nav-label">Dashboard</li>
                    {functions.findInObject(accessInfo, "LOAN0000-2") &&
                    <li 
                        className={`nav-item show ${(activeRoot==='/dashboard') && 'active'}`}
                        >
                        <Link className="nav-link" to="/dashboard"><i className="fas fa-hands-helping"></i><span className="sidemenu-label">Loans</span></Link>
                    </li>}
                    {functions.findInObject(accessInfo, "CUST000-2") &&  
                    <li 
                        className={`nav-item show ${(activeRoot==='/customers') && 'active'}`}
                        >
                        <Link className="nav-link" to="/customers"><i className="fas fa-users"></i><span className="sidemenu-label">Customers</span></Link>
                    </li>}
                    {functions.findInObject(accessInfo, "AGENT000-2") &&
                    <li 
                        className={`nav-item show ${(activeRoot==='/agents') && 'active'}`}
                        >
                        <Link className="nav-link" to="/agents"><i className="fas fa-globe"></i><span className="sidemenu-label">Agents</span></Link>
                    </li>}
                    {functions.findInObject(accessInfo, "WALLET0001-2") &&
                    <li 
                        className={`nav-item show ${(activeRoot==='/finance') && 'active'}`}
                        >
                        <Link className="nav-link" to="/finance"><i className="fas fa-chart-bar"></i><span className="sidemenu-label">Finance</span></Link>
                    </li>}



                    {functions.findInObject(accessInfo, "DASHBOARD-PRODUCTS") &&
                    <li 
                        className={`nav-item show ${(activeRoot==='/products') && 'active'}`}
                        >
                        <Link className="nav-link" to="/products"><i className="fas fa-gem"></i><span className="sidemenu-label">Products</span></Link>
                    </li>}


                    {functions.findInObject(accessInfo, "DASHBOARD-CASHROUND") &&
                    <li 
                        className={`nav-item show ${(activeRoot==='/cashround') && 'active'}`}
                        >
                        <Link className="nav-link" to="/cashround"><i className="fe fe-aperture"></i><span className="sidemenu-label">Cash Rounds</span></Link>
                    </li>}



                    <li className="nav-label">Applications</li>
                    
                
                  
                            {functions.findInObject(accessInfo, "LOAN-PG-0003-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/all-loans">Loan History</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "LOAN-PG-0004-2") && 
                            <li 
                                className={`nav-sub-item ${path==='/dashboard/todolist' && 'active'}`}>
                                <Link replace className={`nav-sub-link `} to="/dashboard/todolist">Todo List Loans</Link>
                            </li>}
                        </ul>
                    
                    </li>}



                    {functions.findInObject(accessInfo, "AGENT000-2") &&  
                    <li className="nav-item" onClick={this.toggleMenu}>
                        <a className="nav-link with-sub" to="#">
                            <i className="fas fa-globe"></i>
                            <span className="sidemenu-label">Agents</span><i className="angle fe fe-chevron-right"></i>
                        </a>
                        <ul className="nav-sub">
                        {functions.findInObject(accessInfo, "AGENT-PG-0001-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/agent-records">Agent Records</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "AGENT-PG-0002-2") &&<li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/agent-tokens">Activation Tokens</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "AGENT-PG-0003-2") &&<li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/agent-performance">Agent Performance</Link>
                            </li>}

                            
                        </ul>
                    </li>}

                   

                    {functions.findInObject(accessInfo, "TRANS0000-2") &&  
                        <li className="nav-item"  onClick={this.toggleMenu}>
                        <a className="nav-link with-sub" to="#">
                            <i className="fas fa-history"></i>
                            <span className="sidemenu-label">Transactions</span><i className="angle fe fe-chevron-right"></i>
                        </a>
                        <ul className="nav-sub">
                        {functions.findInObject(accessInfo, "TRANS0001-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/float-transactions">Capital Float</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "TRANS0002-2") &&  <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/business-float-transactions">Business Float</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "TRANS0003-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/wallet-transactions">Wallet</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "TRANS0004-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/reserve-transactions">Company Reserve</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "TRANS0005-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/commission-transactions">Commission</Link>
                            </li>}
                           
                        </ul>
                    </li>}

                  
                    {functions.findInObject(accessInfo, "STT0000-2") &&  
                        <li className="nav-item"  onClick={this.toggleMenu}>
                        <a className="nav-link with-sub" to="#">
                            <i className="fas fa-exchange-alt"></i>
                            <span className="sidemenu-label">Statements</span><i className="angle fe fe-chevron-right"></i>
                        </a>
                        <ul className="nav-sub">
                            {functions.findInObject(accessInfo, "STT0001-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/float-statement">Capital Float</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "STT0002-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/business-float-statement">Business Float</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "STT0003-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/wallet-statement">Wallet</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "STT0004-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/reserve-statement">Company Reserve</Link>
                            </li>}
                            {functions.findInObject(accessInfo, "STT0005-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/commission-statement">Commission</Link>
                            </li>}
                        </ul>
                    </li>}
               
               
               
                </ul>
            </div>
        </div>
          */
       
       
       )
    }

}

export default LeftSideBar