import React, { Component } from 'react';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class EditTerm extends Component {

    state={
        termId:this.props.record.term_id,
        termName:this.props.record.term,
        info:false
    }


    onChangeTerm=(event)=>{
        this.setState({
            termName:event.target.value
        })
    }

   

   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {termName, termId}=this.state;
        if(termName.length>0 && termId>0)
        {
            const server_response = await ajaxConfig.updateTerm(termId, termName);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {
        return (
            
    <SystemModal id="mdl-edit-term" title="Edit Academic Term">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="Term Name"
                            placeholder="Term Name"
                            leftIcon="fa fa-bookmark"
                            value={this.state.termName}
                            onChange={this.onChangeTerm} 
                            required
                            wrap
                            />

       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default EditTerm;