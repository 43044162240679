import React from 'react';
import Loader from 'react-loader-spinner';

class ActivityLoader extends React.Component {
  

  render(){

    const size = this.props.size || 40;
    
      return (
        <>
        {!this.props.inline && <div className="col-md-12 text-center" style={{marginTop:"10px"}}>
             <Loader
                type="Watch"
                color={this.props.color || "#00BFFF"}
                height={size}
                width={size}
            />
        </div>}
        {this.props.inline && <span style={{float:'right'}}>
          <Loader
                type="Watch"
                color={this.props.color || "#00BFFF"}
                height={size}
                width={size}
            />
          </span>}
        </>

      );
    
 
  }
}

export default ActivityLoader