import React, { useEffect, useState } from 'react';
import ajaxConfig from '../util/remote/ajaxConfig';
import ajaxStudent from '../util/remote/ajaxStudent';
import ajaxSystem from '../util/remote/ajaxSystem';

const ConfigContext = React.createContext();

export const ConfigConsumer=ConfigContext.Consumer;

export const ConfigProvider=(props)=> {

    const [classLevels, setClassLevels] = useState(false);
    const [allClasses, setAllClasses] = useState(false);
    const [allTerms, setAllTerms] = useState(false);
    const [allExams, setAllExams] = useState(false);
    const [allPapers, setAllPapers] = useState(false);
    const [currentPeriod, setCurrentPeriod] = useState(false);
    const [currentTerm, setCurrentTerm] = useState("...");
    const [currentExams, setCurrentExams] = useState(false);
    const [allSubjects, setAllSubjects] = useState(false);
    const [subjectCategories, setSubjectCategories]  = useState(false);
    const [subjectTypes, setSubjectTypes] = useState(false);
    const [totalSubjects, setTotalSubjects] = useState("...");
    const [stdCurrentPeriod, setStdCurrentPeriod] = useState("...");
    const [totalClasses, setTotalClasses] = useState("...");
    const [userRoles, setUserRoles] = useState(false);
    const [totalUsers, setTotalUsers] = useState("...");
    const [userListing, setuserListing] = useState(false);
    const [totalClassLevels, setTotalClassLevels] = useState("...");
    const [totalTerms, setTotalTerms] = useState("...");
    const [totalTests, setTotalTests] = useState("...");
    const [schoolProfile, setSchoolProfile] = useState(false);
    const [reportFormats, setReportFormats] = useState(false);
    const [primaryScales, setPrimaryScales] = useState(false);
    const [advancedScales, setAdvancedScales] = useState(false);
    const [assessmentTypeListing, setAssessmentTypeListing] = useState(false);
    const [residenceListing, setResidenceListing] = useState(false)
    const [contactTypes, setContactTypes] = useState(false);

     useEffect(()=>{
        getSchoolProfile();
        getClassLevels();
        getAllClasses();
        getAllTerms();
        getAllExams();
        getCurrentPeriod();
        getCurrentExams();
        getAllSubjects();
        getSubjectCategories();
        getSubjectTypes();
        getSubjectPapers();
        countSubjects();
        countClasses();
        getUserRoles();
        countUsers();
        getUsers();
        countClassLevels();
        countTerms();
        countTests();
        getReportFormats();
        getPrimaryScales();
        getAdvancedScales();
        getAssessmentTypes();
        getResidenceListing();
        getContactTypo();
     }, [])
       

     const getSchoolProfile=async()=>{
         const server_response = await ajaxConfig.getSchoolInfo();
         if(server_response.status==="OK")
         {
            setSchoolProfile(server_response.details)
         }
     }
    
 
     const getClassLevels=async()=>{
         const server_response = await ajaxConfig.getClassLevels();
         if(server_response.status==="OK")
         {
             setClassLevels(server_response.details)
         }
     }

     const getAllClasses=async()=>{
         const server_response = await ajaxConfig.getClassRooms();
         if(server_response.status==="OK")
         {
            setAllClasses(server_response.details)
         }
      }


      const getAllTerms=async()=>{
         const server_response = await ajaxConfig.getTerms();
         if(server_response.status==="OK")
         {
            setAllTerms(server_response.details)
         }
      }


      const getAllExams=async()=>{
         const server_response = await ajaxConfig.getExams();
         if(server_response.status==="OK")
         {
            setAllExams(server_response.details)
         }
      }
    

      const getCurrentPeriod=async()=>{
         const server_response = await ajaxConfig.getCurrentPeriod();
         if(server_response.status==="OK")
         {
            setCurrentPeriod(server_response.details);
            setStdCurrentPeriod("Term " + server_response.details.term.term + " " + server_response.details.acad_year);
            setCurrentTerm(server_response.details.term.term);
         }
      }

      const getCurrentExams=async()=>{
         const server_response = await ajaxConfig.getCurrentExams();
         if(server_response.status==="OK")
         {
            setCurrentExams(server_response.details)
         }
      }

      const getAllSubjects=async()=>{
         const server_response = await ajaxConfig.getSubjects();
         if(server_response.status==="OK")
         {
            setAllSubjects(server_response.details)
         }
      }

      const getSubjectCategories=async()=>{
         const server_response = await ajaxConfig.getSubjectCategories();
         if(server_response.status==="OK")
         {
            setSubjectCategories(server_response.details)
         }
      }


      const getSubjectTypes=async()=>{
         const server_response = await ajaxConfig.getSubjectTypes();
         if(server_response.status==="OK")
         {
            setSubjectTypes(server_response.details)
         }
      }

      const getSubjectPapers=async()=>{
         const server_response = await ajaxConfig.getAllSubjectPapers();
         if(server_response.status==="OK")
         {
            setAllPapers(server_response.details)
         }
      }

      const countSubjects=async()=>{
         const server_response = await ajaxConfig.countSubjects();
         if(server_response.status==="OK")
         {
            setTotalSubjects(server_response.details.total_p)
         }
      }


      const countClasses=async()=>{
         const server_response = await ajaxConfig.countClasses();
         if(server_response.status==="OK")
         {
            setTotalClasses(server_response.details.total_p)
         }
      }


      const getUserRoles=async()=>{

         const server_response = await ajaxConfig.getUserRoles();
         if(server_response.status==="OK")
         {
            setUserRoles(server_response.details)
         }else{
            setUserRoles("404")
         }         

      }

      const getUsers=async()=>{

         countUsers();
         const server_response = await ajaxConfig.getUsers();
         if(server_response.status==="OK")
         {
            setuserListing(server_response.details)
         }else{
            setuserListing("404")
         }         

      }

      const countUsers=async()=>{

         const server_response = await ajaxConfig.countUsers();
         if(server_response.status==="OK")
         {
            setTotalUsers(server_response.details.total_p)
         }
      }

      const countClassLevels=async()=>{

         const server_response = await ajaxConfig.countClassLevels();
         if(server_response.status==="OK")
         {
            setTotalClassLevels(server_response.details.total_p)
         }
      }

      const countTerms=async()=>{

         const server_response = await ajaxConfig.countTerms();
         if(server_response.status==="OK")
         {
            setTotalTerms(server_response.details.total_p)
         }
      }


      const countTests=async()=>{

         const server_response = await ajaxConfig.countTests();
         if(server_response.status==="OK")
         {
            setTotalTests(server_response.details.total_p)
         }
      }

      const getReportFormats=async()=>{

         const server_response = await ajaxSystem.getReportFormats();
         if(server_response.status==="OK")
         {
            setReportFormats(server_response.details)
         }
      }


      const getPrimaryScales=async()=>{

         const server_response = await ajaxSystem.getPrimarySCales();
         if(server_response.status==="OK")
         {
            setPrimaryScales(server_response.details)
         }
      }


      const getAdvancedScales=async()=>{

         const server_response = await ajaxSystem.getAdvancedSCales();
         if(server_response.status==="OK")
         {
            setAdvancedScales(server_response.details)
         }
      }

      const getAssessmentTypes=async()=>{

         const server_response = await ajaxConfig.fetchAssessmentTypes();
         if(server_response.status==="OK")
         {
            setAssessmentTypeListing(server_response.details)
         }
      }


      const getResidenceListing=async()=>{

         const server_response = await ajaxConfig.fetchResidenceListing();
         if(server_response.status==="OK")
         {
            setResidenceListing(server_response.details)
         }

      }


      const getContactTypo=async()=>{

         const server_response = await ajaxStudent.fetchContactTypo();
         if(server_response.status==="OK")
         {
            setContactTypes(server_response.details)
         }

      }
    
    
        return (
           <ConfigContext.Provider value={
                {
                   classLevels,
                   allClasses,
                   allTerms,
                   allExams,
                   currentPeriod,
                   currentExams,
                   allSubjects,
                   subjectCategories,
                   subjectTypes,
                   allPapers,
                   totalSubjects,
                   stdCurrentPeriod,
                   totalClasses,
                   userRoles,
                   totalUsers,
                   userListing,
                   totalClassLevels,
                   totalTerms,
                   totalTests,
                   schoolProfile,
                   reportFormats,
                   advancedScales,
                   primaryScales,
                   currentTerm,
                   assessmentTypeListing,
                   residenceListing,
                   contactTypes,
                   getClassLevels,
                   getAllClasses,
                   getAllTerms,
                   getAllExams,
                   getCurrentExams,
                   getCurrentPeriod,
                   getAllSubjects,
                   getSubjectCategories,
                   getSubjectTypes,
                   getUserRoles,
                   getUsers,
                   getSchoolProfile
                }
               }>
               {props.children}
           </ConfigContext.Provider>
        );
    
}

export default ConfigContext;