import React, { useContext } from 'react';
import ConfigContext from '../../../Context/ConfigContext';
const Disclaimer = (props)=> {

        const { schoolProfile } = useContext(ConfigContext);

        return <>
                {schoolProfile.sign &&<table border="0" style={{width:"100%"}}><tbody>
                <tr>
                        <td> &nbsp;</td>
                        <td style={{textAlign:'right'}}>

                        <img 
                           alt="signature"
                           src={schoolProfile.sign}
                           style={{width:'100px'}}
                        /><br/>
                        <span style={{fontWeight:"bold", fontSize:"12px"}}>{schoolProfile.signature_title}</span>

                        </td>
                </tr>
                <tr>
                <td colSpan={2} style={{color:"#F00", fontWeight:"bold", fontSize:"12px", textAlign:"center"}}>
                                {props.text || "THIS REPORT CARD IS INVALID WITHOUT OFFICIAL STAMP AND SIGNATURE"}
                </td>
                </tr>
              
                </tbody>
                </table>}
        </>
        
    }


export default Disclaimer;