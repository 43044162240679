import React, { useContext } from "react";
import ReportContext from "../../Context/ReportContext";
import functions from "../../util/functions";
import ActivityLoader from "../Common/ActivityLoader";
import LoadMoreButton from "../Other/LoadMoreButton";
import ReportFormatFour from "./Template/ReportFormatFour";
import ReportFormaThree from "./Template/ReportFormaThree";
import ReportFormatOne from "./Template/ReportFormatOne";
import ReportFormaTwo from "./Template/ReportFormaTwo";

const ReportFormatSelector=(props)=>{

    const {info, currentReport, studentListing, loading, fetchMore, studentMeta} = useContext(ReportContext);

    const ReportFormat=(prop)=>{
        switch(currentReport.report_info.report_format.format_id*1)
        {
            case 5:
                return <ReportFormatFour {...prop}/>
            case 4:
                return <ReportFormaTwo {...prop}/>
            case 2:
                return <ReportFormaThree {...prop}/>
                    
            default:
                return <ReportFormatOne {...prop}/>            
        }

    }

    const onPrint=()=>{
        functions.printElement("acad-report-cards")
    }

    return(
        <div>
            {currentReport && currentReport.students.student_listing &&<button 
                    className='btn btn-primary'
                    onClick={onPrint} 
                    style={{float:"right"}}><i className='fa fa-print'/> Print</button>}
        <div id="acad-report-cards">
        {info}
        

        {currentReport && studentListing && Object.keys(studentListing).map((studentId, key)=>
                                    <ReportFormat 
                                            key={key} 
                                            student={studentListing[studentId]}
                                            studentId={studentId} 
                                            reportInfo={currentReport.report_info}
                                            initials={currentReport.initials}
                                            totalStudents={currentReport.students.total_students}
                                            />
                                )}

        {!currentReport && <ActivityLoader/>}


        {currentReport && studentListing && studentMeta && <LoadMoreButton 
                title={`Load more reports (${studentMeta.current_page + "/" + studentMeta.total_pages})`}
                action={fetchMore}
                loading={loading}
                />}

        </div>
    </div>
    )

}


export default ReportFormatSelector