import React, { Component } from 'react';
import ConfigContext from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class UpdateExam extends Component {
    static contextType = ConfigContext
    state={
        examId:this.props.record.id,
        examName:this.props.record.exam_name,
        description:this.props.record.description,
        assessmentId:this.props.record.assessment_type.id,
        selectedAssessment:"",
        info:false
    }


    onChangeExam=(event)=>{
        this.setState({
            examName:event.target.value
        })
    }

    onChangeDescription=(event)=>{
        this.setState({
            description:event.target.value
        })
    }

    onChangeAssessment=(item)=>{
        this.setState({
            assessmentId:item.value
        })
    }



    RenderAssessment=()=>{
        const optionsListing=[];
        const listing = this.context.assessmentTypeListing;
        var selected="";
        if(Array.isArray(listing))
        {
            Object.keys(listing).forEach(key=>{
                if(listing[key].id*1===this.state.assessmentId*1){
                  selected = listing[key].type_name
                }
                optionsListing.push({label:listing[key].type_name, value:listing[key].id})
            })
         }

        return(
            <TextInput
                label="Assessment Type"
                leftIcon="fa fa-bookmark"
                type="select" 
                placeholder={selected} 
                options={optionsListing}
                onChange={this.onChangeAssessment}
                leftComponent={<i className="fa fa-bookmark"/>}
                wrap
            />
        )
    }

   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {examName, description, examId, assessmentId}=this.state;
        if(examName.length>0 && description.length>0 && examId>0 && assessmentId.length>0)
        {
            const server_response = await ajaxConfig.updateAssessment(examId, examName, description, assessmentId);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {
        return (
            
    <SystemModal id="mdl-edit-exam" title="Edit Assessment">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
                    <TextInput
                            type="text"
                            label="Assessment Name"
                            placeholder="Assessment Name"
                            leftIcon="fa fa-edit"
                            value={this.state.examName}
                            onChange={this.onChangeExam} 
                            required
                            wrap
                            />

                    <TextInput
                            type="text"
                            label="Description"
                            placeholder="Description"
                            leftIcon="fa fa-list"
                            value={this.state.description}
                            onChange={this.onChangeDescription} 
                            required
                            wrap
                            />
                    <this.RenderAssessment/>
       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default UpdateExam;