import React, { Component } from 'react';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class EditClassLevel extends Component {

    state={
        levelId:this.props.record.level_id,
        levelName:this.props.record.level,
        info:false
    }


    onChangeLevel=(event)=>{
        this.setState({
            levelName:event.target.value
        })
    }

   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {levelName}=this.state;
        if(levelName.length>0)
        {
            const server_response = await ajaxConfig.updateClassLevel(this.state.levelId, levelName);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {
        return (
            
    <SystemModal id="mdl-edit-level" title="Edit Class Level">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="Level Name"
                            placeholder="Level Name"
                            leftIcon="fa fa-bookmark"
                            value={this.state.levelName}
                            onChange={this.onChangeLevel} 
                            required
                            wrap
                            />
               
    

            <div className="row">&nbsp;</div>


       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default EditClassLevel;