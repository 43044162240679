import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import EditClassRoom from '../../Components/Config/ClassRoom/EditClassRoom';
import NewTerm from '../../Components/Config/Term/NewTerm';
import ConfigMenu from '../../Components/Config/ConfigMenu';
import PageContainer from '../../Components/Headings/PageContainer';
import { ConfigConsumer } from '../../Context/ConfigContext';
import functions from '../../util/functions';
import EditTerm from '../../Components/Config/Term/EditTerm';

class TermPage extends Component {

    state={
        currentRecord:false,
        transactionId:'',
        mdlCrud:false
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }

    newRecord=()=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <NewTerm refresh={props.getAllTerms}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-new-term"))
        })
    }

    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                    <li>
                        <Link to="#" className="btn ripple btn-success mb-1" onClick={this.newRecord}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;Define New</Link>
                    </li>
                    <li>
                        <Link to="/configurations" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    

      editRecord=(record)=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <EditTerm record={record} refresh={props.getAllTerms}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-edit-term"))
        })
    }


    RenderTransactions=()=>{
        return(
            <ConfigConsumer>
                {props=>{
                    const list = props.allTerms;
                    return(
                        <div className="table-responsive table-ellipsis">
                        <table className="table expandable-table table-striped text-nowrap mb-0">
                            <thead>
                                <tr>
                                <th>No.</th>                                   
                                <th>Term</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(list) && list.map((item, key)=>
                                <tr 
                                    className={`${item.term_id===this.state.transactionId?'flag-row':'hot-tr'}`}
                                    style={{cursor:'default'}}
                                    onClick={()=>this.onSelectRecord(item.term_id, item)}
                                    key={key}>
                                    <td>{key+1}</td>
                                    <td>{item.term}</td>
                                    <td>
                                        <span className='action'>
                                            <button type="button" className="btn btn-primary btn-flat btn-pri btn-sm" onClick={()=>this.editRecord(item)}>
                                                <i className='fa fa-pen'/>&nbsp;Edit</button>
                                            <button type="button" className="btn btn-danger btn-flat btn-pri btn-sm">
                                                <i className='fa fa-trash-alt'/>&nbsp;Delete
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            )}
            
                        </tbody>
                        </table>
                        {!list && <ActivityLoader/>}
                    </div>
                    )
                }}
            </ConfigConsumer>
           
        )
    }


    render() {
        return (
            <PageContainer 
                title="Academic Terms"
                history={{title:"Configurations", link:"/configuration"}}
                headerNav={<this.RenderControls/>}
                >
                        {this.state.mdlCrud}
                        <div className="row">
                            <div className='col-md-9 col-sm-12'>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Academic Terms</h3>
                                    </div>
                                    <div className="card-body">

                                    <this.RenderTransactions/>

                                    </div>
                                </div>
                        

                            </div>

                            <ConfigMenu/>
                       
                         
                        </div>

                     
        </PageContainer>
        );
    }
}

export default TermPage;