import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import EditClassLevel from '../../../Components/Config/ClassLevel/EditClassLevel';
import NewClassLevel from '../../../Components/Config/ClassLevel/NewClassLevel';
import ConfigMenu from '../../../Components/Config/ConfigMenu';
import NewUserRole from '../../../Components/Config/User/NewUserRole';
import PageContainer from '../../../Components/Headings/PageContainer';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import functions from '../../../util/functions';

class UserRolePage extends Component {

    state={
        currentRecord:false,
        transactionId:'',
        mdlCrud:false
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }

    newLevel=()=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <NewUserRole refresh={props.getUserRoles}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-new-role"))
        })
    }

    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                    {this.state.currentRecord && <li>
                        <Link to="#" className="btn ripple btn-warning mb-1" onClick={this.editLevel}>
                            <i className="fa fa-pen mr-1"></i>&nbsp;Edit Role</Link>
                    </li>}
                    <li>
                        <Link to="#" className="btn ripple btn-success mb-1" onClick={this.newLevel}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;New Role</Link>
                    </li>
                    <li>
                        <Link to="/configurations" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    

      editLevel=()=>{
        alert("Coming soon");
        return false;
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <EditClassLevel record={this.state.currentRecord} refresh={props.getClassLevels}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-edit-level"))
        })
    }


    RenderTransactions=()=>{
        return(
            <ConfigConsumer>
                {props=>{
                    const list = props.userRoles;
                    return(
                        <div className="table-responsive table-ellipsis">
                        <table className="table expandable-table table-striped text-nowrap mb-0">
                            <thead>
                                <tr>
                                <th>No.</th>                                   
                                <th>Role Name</th> 
                                <th>Created on</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(list) && list.map((item, key)=>
                                <tr 
                                    className={`${item.role_id===this.state.transactionId?'flag-row':'hot-tr'}`}
                                    style={{cursor:'default'}}
                                    onClick={()=>this.onSelectRecord(item.role_id, item)}
                                    key={key}>
                                    <td>{key+1}</td>
                                    <td>{item.role_name}</td>
                                    <td>{item.created_at.short_date + " " + item.created_at.time}</td>
                                </tr>
                            )}
            
                        </tbody>
                        </table>
                        {!list && <ActivityLoader/>}
                    </div>
                    )
                }}
            </ConfigConsumer>
           
        )
    }


    render() {
        return (
            <PageContainer 
                title="User Roles"
                history={{title:"Configurations", link:"/configuration"}}
                headerNav={<this.RenderControls/>}
                >
                        {this.state.mdlCrud}
                        <div className="row">
                            <div className='col-md-9 col-sm-12'>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">List of system user roles</h3>
                                    </div>
                                    <div className="card-body">

                                    <this.RenderTransactions/>

                                    </div>
                                </div>
                        

                            </div>

                            <ConfigMenu/>
                       
                         
                        </div>

                     
        </PageContainer>
        );
    }
}

export default UserRolePage;