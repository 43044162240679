import React, { Component } from 'react';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class NewCurrentPeriod extends Component {

    state={
        termId:'',
        startDate:'',
        endDate:'',
        info:false
    }


    onChangeStartDate=(event)=>{
        this.setState({
            startDate:event.target.value
        })
    }

    onChangeEndDate=(event)=>{
        this.setState({
            endDate:event.target.value
        })
    }


    onChangeTerm=(option)=>{
        this.setState({
            termId:option.value
        })
    }



    RenderTerm=()=>{

        return(<ConfigConsumer>

            {props=>{

                const optionsListing=[];

                if(Array.isArray(props.allTerms))
                {
                    props.allTerms.map((item, key)=>
                    optionsListing.push({label:item.term, value:item.term_id})
                    )
                 }

                return(
                    <TextInput
                        label="Term"
                        leftIcon="fa fa-bookmark"
                        type="select" 
                        placeholder="Select term...." 
                        options={optionsListing}
                        onChange={this.onChangeTerm}
                        leftComponent={<i className="fa fa-bookmark"/>}
                        wrap
                    />
                )

            }}

        </ConfigConsumer>)
       
        }

   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {termId, startDate, endDate}=this.state;
        if(startDate.length>0 && endDate.length>0 && termId>0)
        {
            const server_response = await ajaxConfig.setCurrentPeriod(termId, startDate, endDate);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>,
                    className:''
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {
        return (
            
    <SystemModal id="mdl-new-period" title="Set Current Period">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <this.RenderTerm/>
               <TextInput
                    type="date"
                    label="Term Begins"
                    placeholder="Term Begins"
                    leftIcon="fa fa-calendar"
                    value={this.state.startDate}
                    onChange={this.onChangeStartDate} 
                    required
                    wrap
                    />
                <TextInput
                    type="date"
                    label="Term Ends"
                    placeholder="Term Ends"
                    leftIcon="fa fa-calendar"
                    value={this.state.endDate}
                    onChange={this.onChangeEndDate} 
                    required
                    wrap
                    />

            <div className="row">&nbsp;</div>


       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Confirm Period</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default NewCurrentPeriod;