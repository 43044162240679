import React, { Component, useState } from 'react';
import { ConfigConsumer } from '../../Context/ConfigContext';
import dictionary from '../../util/dictionary';
import functions from '../../util/functions';
import ajaxConfig from '../../util/remote/ajaxConfig';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

const PopNewReportExam =(props)=> {

    const [mark, setMark] = useState('');
    const [exam, setExam] = useState(props.exam);
    const [info, setInfo] = useState(false)
    const [self, setSelf] = useState("mdl-new-report-exam")

  
   const  onChangeMark=(event)=>{
       setMark(event.target.value)
    }



   const confirmSave=(event)=>{
        event.preventDefault();
  
        if(mark>0)
        {
            props.onConfirm({
                "out_of":mark,
                "test_id":exam.value,
                "exam_name":exam.label
            })
            functions.closePopup(self)
        }else{
            this.setState({
                info:<AlertError message={"Enter test mark and try again"}/>
            })
        }
    }


        return (
            
            <SystemModal id={self} title={"Include " + exam.label}>
            <form method="post" className="form-horizontal" onSubmit={confirmSave}>
                    {info}
                    <TextInput
                            type="text"
                            label="Exam Contribution Mark"
                            placeholder="Enter Exam Mark e.g. 30, 40"
                            leftIcon="fa fa-bookmark"
                            value={mark}
                            onChange={onChangeMark} 
                            required
                            wrap
                            />
            
                    
                    <div className="modal-footer no-border">
                        <button type="submit" className="btn btn-success">Include Exam</button>
                    </div>

            </form> 

        

            </SystemModal>
        );
    
}

export default PopNewReportExam;