import React, { Component } from 'react';
import './style/App.css';
import './style/tree.css';
import './style/report.css';
import './style/Custom.css';

import { Route, BrowserRouter as Router, Routes as Switch, Navigate} from 'react-router-dom'
import Login from './Pages/auth/Login';
import Footer from './Components/Common/Footer';
import NotFoundPage from './Pages/error/NotFoundPage';
import DashboardPage from './Pages/DashboardPage';
import ConfigPage from './Pages/config/ConfigPage';
import ClassLevelPage from './Pages/config/ClassLevelPage';
import SuperProvider from './Context/SuperProvider';
import ClassRoomPage from './Pages/config/ClassRoomPage';
import ClasStreamPage from './Pages/config/ClasStreamPage';
import TermPage from './Pages/config/TermPage';
import AssessmentPage from './Pages/config/AssessmentPage';
import CurrentPeriodPage from './Pages/config/CurrentPeriodPage';
import SubjectPage from './Pages/config/SubjectPage';
import ManageSubjectPage from './Pages/config/ManageSubjectPage';
import StudentPage from './Pages/student/StudentPage';
import NewStudentPage from './Pages/student/NewStudentPage';
import EnterMarksPage from './Pages/marks/EnterMarksPage';
import OrganiseMarksPage from './Pages/marks/OrganiseMarksPage';
import ReportCardPage from './Pages/marks/ReportCardPage';
import NewReportPage from './Pages/reports/NewReportPage';
import ImportStudentPage from './Pages/student/ImportStudentPage';
import MarkSheetPage from './Pages/marks/MarkSheetPage';
import NewStaffPage from './Pages/staff/NewStaffPage';
import ListStaffPage from './Pages/staff/ListStaffPage';
import UserRolePage from './Pages/config/User/UserRolePage';
import UsersPage from './Pages/config/User/UsersPage';
import SchoolProfilePage from './Pages/config/Profile/SchoolProfilePage';
import SystemFinderPage from './Pages/find/SystemFinderPage';
import UpdateStudentPage from './Pages/student/UpdateStudentPage';
import EditReportPage from './Pages/reports/EditReportPage';
import ResetPassword from './Pages/auth/ResetPassword';
import functions from './util/functions';
import ReportCommentsPage from './Pages/marks/ReportCommentsPage';
import ContactCenterPage from './Pages/student/ContactCenterPage';
import StudentProfile from './Pages/student/StudentProfile';
import ConfigReportCommentPage from './Pages/config/ConfigReportCommentPage';

class App extends Component {

  state={
    loggedIn:false,
    token:false
  }

  componentDidMount() {
     this.checkLogin();
  }

  checkLogin()
  {
    
    if(!window.localStorage.getItem("eschool@user") || !window.localStorage.getItem("eschool@remote"))
    {
      this.setState({
        loggedIn:false
      })
    }else{
      this.setState({
        loggedIn:true,
        token:functions.readUser()
      })
    }
  }

  

  render() {
    return (
      <div className="wrapper" id="main-wrapper">
        <SuperProvider>
            <Router forceRefresh={false}>
            <Switch>
                {!this.state.loggedIn && <>
                  <Route path="*" element={<Login/>}/> 
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/" element={!this.state.loggedIn?<Navigate replace to="/login" />:<DashboardPage/>}/>
                </>}

                {this.state.token.is_secure*1<=0 && <>
                      <Route path="/account/security" element={<ResetPassword/>}/>
                      <Route path="/" element={<Navigate replace to="/account/security" />}/>

                   </>}



                {this.state.loggedIn && <>
                
                  <Route path="/" element={!this.state.loggedIn?<Navigate replace to="/login" />:<DashboardPage/>}/>
                  <Route path="/login" element={this.state.loggedIn?<Navigate replace to="/" />:<Login/>}/>
                  <Route path="/dashboard" element={<DashboardPage/>}/>
                  <Route path="/configuration" element={<ConfigPage/>}/>
                  <Route path="/configurations" element={<ConfigPage/>}/>
                  <Route path="/configuration/class_levels" element={<ClassLevelPage/>}/>
                  <Route path="/configuration/classes" element={<ClassRoomPage/>}/>
                  <Route path="/configuration/classes/streams/:classId" element={<ClasStreamPage/>}/>
                  <Route path="/configuration/term" element={<TermPage/>}/>
                  <Route path="/configuration/exam" element={<AssessmentPage/>}/>
                  <Route path="/configuration/current_period" element={<CurrentPeriodPage/>}/>
                  <Route path="/configuration/subjects" element={<SubjectPage/>}/>
                  <Route path="/configuration/subjects/:subjectId" element={<ManageSubjectPage/>}/>
                  <Route path="/configuration/roles" element={<UserRolePage/>}/>
                  <Route path="/configuration/users" element={<UsersPage/>}/>
                  <Route path="/configuration/profile" element={<SchoolProfilePage/>}/>
                  <Route path="/configuration/report/comments" element={<ConfigReportCommentPage/>}/>

                  

                  <Route path="/student/listing" element={<StudentPage/>}/>
                  <Route path="/student/new" element={<NewStudentPage/>}/>
                  <Route path="/student/import" element={<ImportStudentPage/>}/>
                  <Route path="/student/update/:initials" element={<UpdateStudentPage/>}/>
                  <Route path="/student/contacts" element={<ContactCenterPage/>}/>
                  <Route path="/students/profile/:studentId" element={<StudentProfile/>}/>


                  <Route path="/staff/new" element={<NewStaffPage/>}/>
                  <Route path="/staff/listing" element={<ListStaffPage/>}/>


                  <Route path="/marks/new" element={<EnterMarksPage/>}/>
                  <Route path="/marks/organise" element={<OrganiseMarksPage/>}/>
                  <Route path="/report/card" element={<ReportCardPage/>}/>
                  <Route path="/report/new" element={<NewReportPage/>}/>
                  <Route path="/report/marksheet" element={<MarkSheetPage/>}/>
                  <Route path="/report/update" element={<EditReportPage/>}/>
                  <Route path="/report/comments" element={<ReportCommentsPage/>}/>


                  <Route exact path="/finder" element={<SystemFinderPage/>}/>

                  <Route path="/report/comments" element={<ReportCommentsPage/>}/>


                  <Route path="*" element={<NotFoundPage/>}/>
                </>}
        
              </Switch>

            </Router>
            {this.state.loggedIn &&  this.state.token.is_secure*1>0 && <Footer/>}
          </SuperProvider>
      </div>
    );
  }
}

export default App;