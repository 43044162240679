import React, { useContext } from 'react';
import ReportContext from '../../Context/ReportContext';
const GradingScale = (props)=> {
        const { reportCards } = useContext(ReportContext)
        const gradeTitles=[];
        const gradeValues=[];
        
        if(Array.isArray(reportCards.primary_scale_definition)){
            reportCards.primary_scale_definition.forEach(element => {
                gradeValues.push(element.range_from + "-" + element.range_to);
                gradeTitles.push(element.grade)
            });
        }

        return (
        <>
            <center><b><span style={{fontSize:"13px"}}>GRADING SCALE</span></b></center>
            <table className="Reports" border="1" width="100%" style={{border:"solid", borderCollapse:"collapse", fontFamily:"Arial", fontSize:"13px"}}>
            <tbody>
            <tr>
            
                {gradeTitles.map((item, key)=>
                    <th key={key} style={{textAlign:"center"}}>{item}</th>
                )}
                
            </tr>
            <tr>
            {gradeValues.map((item, key)=>
                <td key={key}><center>{ item }</center></td>
            )}
          </tr>
          </tbody>
        </table>



        




       </>
        );
    }


export default GradingScale;