import React from "react";
import MarkSheetFormatFive from "./MarkSheetFormatFive";
import MarkSheetFormatFour from "./MarkSheetFormatFour";
import MarkSheetFormatOne from "./MarkSheetFormatOne";
import MarkSheetFormatThree from "./MarkSheetFormatThree";
import MarkSheetFormatTwo from "./MarkSheetFormatTwo";

const MarkSheetSelector=(props)=>{

    const reportType = props.marksheet.report_info.report_format.format_id*1
    switch(reportType){

        case 5://Formativ-Summative
            return <MarkSheetFormatFour {...props}/>
        case 4://A-level
            return <MarkSheetFormatTwo {...props}/>
        case 2://Identifier
            return <MarkSheetFormatThree {...props}/>
        case 3://Best 8
            return <MarkSheetFormatFive {...props}/>
                
        default://Positioning
            return <MarkSheetFormatOne {...props}/>       
    }

}


export default MarkSheetSelector;


