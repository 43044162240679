import React, { Component, useContext, useEffect, useState } from 'react';
import { ConfigConsumer } from '../../Context/ConfigContext';
import ReportContext from '../../Context/ReportContext';
import StaffContext from '../../Context/StaffContext';
import dictionary from '../../util/dictionary';
import functions from '../../util/functions';
import ajaxConfig from '../../util/remote/ajaxConfig';
import ajaxReport from '../../util/remote/ajaxReport';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

const PopReportInitials =(props)=> {

    const [info, setInfo] = useState(false)
    const [self, setSelf] = useState("mdl-report-initials");
    const [teacherId, setTeacherId] = useState("");
    const [loading, setLoading] = useState(false);
    


    const onChangeTeacher=(option)=>setTeacherId(option.value)

    const {staffList} = useContext(StaffContext);

   

    const RenderTeacher=()=>{

        const optionsListing=[];

        if(Array.isArray(staffList))
        {
            staffList.map((item, key)=>
            optionsListing.push({label:item.last_name + " " + item.first_name, value:item.staff_id})
            )
         }



      
            return(
                <TextInput
                    label="Subject Initials"
                    leftIcon="fa fa-user"
                    type="select" 
                    placeholder="Select teacher" 
                    options={optionsListing}
                    onChange={onChangeTeacher}
                    leftComponent={<i className="fa fa-user"/>}
                    wrap
                />
            )

          
        }


   const confirmSave=async(event)=>{
        event.preventDefault();
  
        if(teacherId>0)
        {   
            setInfo(<ActivityLoader/>)
           const server_response = await ajaxReport.addReportInitials(props.report_id, teacherId, props.paper_id);
           if(server_response.status==="OK")
           {
            setInfo(<AlertSuccess message={server_response.message}/>);
           }else{
            setInfo(<AlertError message={server_response.message}/>)
           }

        }else{
            
            setInfo(<AlertError message={"Select staff and try again"}/>)
        
        }
    }


  


   




        return (
            
            <SystemModal id={self} title={"Set Report Subject Initials"}>
            <form method="post" className="form-horizontal" onSubmit={confirmSave}>
                    {info}

                    {loading && <ActivityLoader/>}
                   


                    {RenderTeacher()}
                    
                    
                    <div className="modal-footer no-border">
                        {!loading && <button type="submit" className="btn btn-primary">Set Initials</button>}
                    </div>

            </form> 

        

            </SystemModal>
        );
    
}

export default PopReportInitials;