/* eslint-disable import/no-anonymous-default-export */
import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('eschool@user');
const apiHost=dictionary.apiHost

export default {

    async getSearch(data) {
      try {
        let response = await fetch(apiHost + 'system/search',
          {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },

    async getReportFormats() {
      try {
        let response = await fetch(apiHost + 'system/report.formats',
          {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },

    async getPrimarySCales() {
      try {
        let response = await fetch(apiHost + 'grading/primary/list',
          {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },

    async getAdvancedSCales() {
      try {
        let response = await fetch(apiHost + 'grading/advanced/list',
          {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    }

  
}