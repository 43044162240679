import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ReportContext from "../../../Context/ReportContext";

const ReportOption = (props)=>{
   
    const {currentReportCount, totalReportCount, setViewReport} = useContext(ReportContext)
    return(
        <div className="card">
        <div className="card-header">
        <h3 className="card-title">Report Options</h3>
        
        <div className="card-tools">
             <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
            </button>
        </div>
        </div>
        <div className="card-body p-0">

        <ul className="nav nav-pills flex-column">
            <li className="nav-item active">
                <Link to="/report/card" className="nav-link" onClick={()=>setViewReport(false)}>
                    <i className="fas fa-home text-primary"></i> Report Home
                </Link>
            </li>
            <li className="nav-item active">
            <Link to="/report/new" className="nav-link">
                <i className="fas fa-edit text-success"></i> New Report
            </Link>
            </li>
            <li className="nav-item">
            <Link to="#" className="nav-link">
                <i className="fa fa-list text-primary"></i> All Reports
                <span className="badge bg-primary float-right">{totalReportCount}</span>
            </Link>
            </li>
            <li className="nav-item">
            <Link to="#" className="nav-link">
                <i className="fa fa-calendar text-danger"></i> Current Term Reports
                <span className="badge bg-primary float-right">{currentReportCount}</span>
            </Link>
            </li>
        </ul>

        </div>
    </div>
    )
}

export default ReportOption