import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import ItemNotFound from '../../Components/Common/ItemNotFound';
import PageContainer from '../../Components/Headings/PageContainer';
import LoadMoreButton from '../../Components/Other/LoadMoreButton';
import FilterStudent from '../../Components/Student/FilterStudent';
import ConfigContext from '../../Context/ConfigContext';
import StudentContext from '../../Context/StudentContext';
import dictionary from '../../util/dictionary';

const StudentPage =(props)=> {

    const { studentList, getStudents, goToNextPage, loading, metaData} = useContext(StudentContext);
   
    const RenderFilter = () => {
        
       
    
        return <FilterStudent/>
    
        
    }
    
    
    const Buttons = () => {
      return <div className='float-sm-right'>
                <ol className='button-listing '>
                   <li><button className="btn ripple btn-primary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <i className="fa fa-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                      </button>
                    </li>
                   
                    <li>
                        <button className="btn ripple btn-secondary mb-1" onClick={getStudents}>
                            <i className="fa fa-redo mr-1"></i>&nbsp;Refresh</button>
                    </li>
                </ol>
              
             
              
    
          </div>}
    

    

   const RenderTransactions=()=>{
        return(
           
                        <div className="table-responsive table-ellipsis">
                       {Array.isArray(studentList) && <table id="table-class-list" className="table expandable-table table-striped text-nowrap mb-0 datatable" export-format={dictionary._exportBtnFormats}>
                            <thead>
                                <tr>
                                <th>No.</th>                                   
                                <th>Reg No.</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Sex</th>
                                <th>Class</th>
                                <th>Reg Date</th>
                                <th>Residence</th>
                            </tr>
                        </thead>
                        <tbody>
                          {studentList.map((item, key)=>
                            <tr key={key}>
                                <td>{key +1}</td>
                                <td>{item.reg_no}</td>
                                <td>{item.last_name}</td>
                                <td>{item.first_name}</td>
                                <td>{item.sex}</td>
                                <td>{item.class.class_name}</td>
                                <td>{item.created_at.short_date}</td>
                                <td>{item.residence.residence_name}</td>
                            </tr>
                          )}
            
                        </tbody>
                        </table>}
                       {!studentList && <ActivityLoader/>}


                       {studentList==="404" && <ItemNotFound description="No student results founds"/>}


                    </div>
                    )
            
    }


        return (
            <PageContainer 
                title="Students Listing"
                // history={{title:"Configurations", link:"/configuration"}}
                headerNav={Buttons()}
                >
                        <div className="row">
                            <div className='col-md-12 col-sm-12'>
                            {RenderFilter()}

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Create, view and extract student lists</h3>

                                        {Array.isArray(studentList) &&
                                                            <LoadMoreButton
                                                                action={goToNextPage}
                                                                loading={loading}
                                                                title={metaData.current_page + " of " + metaData.total_pages}
                                                            />
                                                        }
                                    </div>
                                    <div className="card-body">

                                    {RenderTransactions()}

                                    </div>
                                </div>
                        

                            </div>

                            {/* <StudentFilter/> */}
                       
                         
                        </div>

                     
        </PageContainer>
        );
}

export default StudentPage;