import React, { Component, useContext, useEffect, useState } from 'react';
import { ConfigConsumer } from '../../Context/ConfigContext';
import ReportContext from '../../Context/ReportContext';
import StaffContext from '../../Context/StaffContext';
import dictionary from '../../util/dictionary';
import functions from '../../util/functions';
import ajaxConfig from '../../util/remote/ajaxConfig';
import ajaxReport from '../../util/remote/ajaxReport';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import ListItem from '../Common/List/ListItem';
import SelectSearch from '../Common/SelectSearch';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';
import GeneralCommentWriter from './Elements/GeneralCommentWriter';

const PopCommentSign =(props)=> {

    const [info, setInfo] = useState(false)
    const [self, setSelf] = useState("mdl-report-comment-sign");
    const [teacherId, setTeacherId] = useState("");



    const {staffList} = useContext(StaffContext);



        const onChangeTeacher=(option)=>setTeacherId(option.value)

        const RenderTeacher=()=>{

            const optionsListing=[];
    
            if(Array.isArray(staffList))
            {
                staffList.map((item, key)=>
                optionsListing.push({label:item.last_name + " " + item.first_name, value:item.staff_id})
                )
             }
    
                return(
                    <TextInput
                        label={props.commentField.comment.title}
                        leftIcon="fa fa-user"
                        type="select" 
                        placeholder="Select staff...." 
                        options={optionsListing}
                        onChange={onChangeTeacher}
                        leftComponent={<i className="fa fa-user txt-primary"/>}
                        wrap
                />
                   
                )
    
              
            }
    

   
    

   const confirmSave=async(event)=>{
        event.preventDefault();
  
        if(teacherId>0)
        {   
            setInfo(<ActivityLoader/>)
           const server_response = await ajaxReport.addCommentStaff(props.commentField.id, teacherId);
           if(server_response.status==="OK")
           {
            setInfo(<AlertSuccess message={server_response.message}/>);
            props.refresh()
           }else{
            setInfo(<AlertError message={server_response.message}/>)
           }

        }else{
            
            setInfo(<AlertError message={"Select staff and try again"}/>)
        
        }
    }



   


        return (
            
            <SystemModal id={self} title={`Change comment Initials`}>
            <form method="post" className="form-horizontal" onSubmit={confirmSave}>
                <div className='row'>
                    <div className='col-12'>
                    {info}

                   
                    </div>
                </div>


                    <div className='row'>
                        <div className='col-12'>
                            {RenderTeacher()}
                        </div>
                       
                    </div>
                   
                    
                    
                    <div className="modal-footer no-border">

                        {/* <div className='col-4'> */}
                            <button type="submit" className="btn btn-success">Change Initials</button>
                        {/* </div> */}
                       
                    </div>

            </form> 

        

            </SystemModal>
        );
    
}

export default PopCommentSign;