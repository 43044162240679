import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import EditClasStream from '../../Components/Config/ClassRoom/EditClasStream';
import NewClasStream from '../../Components/Config/ClassRoom/NewClasStream';
import ConfigMenu from '../../Components/Config/ConfigMenu';
import PageContainer from '../../Components/Headings/PageContainer';
import functions from '../../util/functions';
import ajaxConfig from '../../util/remote/ajaxConfig';

const ClasStreamPage =(props)=> {

    const params = useLocation();
    const navigate = useNavigate();
    const [root, setRoot] = useState(false);
    const [transactions, setTransactions] = useState(false);
    const [mdlCrud, buildMdlCrud] = useState(false);
    const [transactionId, setTransactionId] = useState("");
    const [currentRecord, setCurrentRecord] = useState(false);

    useEffect(()=>{
        if(!params.state)
        {
            navigate("/configuration/classes")
        }else{
            setRoot(params.state.record)
        }
    }, [])
    
    useEffect(()=>{
        if(root.class_id)
        {
            geTransactions();
        }

    }, [root])

    useEffect(()=>{
        if(mdlCrud)
        {
            functions.openPopup("mdl-new-stream")
        }
    }, [mdlCrud])



    const geTransactions=async()=>{
      const server_response = await ajaxConfig.getClassStreams(root.class_id);
      if(server_response.status==="OK")
      {
        setTransactions(server_response.details)

      }else{

          setTransactions("404")
      }
    }

    const onSelectRecord=(transactionId, currentRecord)=>{
        setTransactionId(transactionId);
        setCurrentRecord(currentRecord);
    }

    const newRecord=()=>{
       
        if(mdlCrud){
            buildMdlCrud(false);
            setTimeout(() => {
                buildMdlCrud(<NewClasStream classId={root.class_id} refresh={geTransactions}/>)
            }, 10);
        }else{
            buildMdlCrud(<NewClasStream classId={root.class_id} refresh={geTransactions}/>)

        }
    
    }

    const RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                    <li>
                        <Link to="#" className="btn ripple btn-success mb-1" onClick={newRecord}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;Create New</Link>
                    </li>
                    <li>
                        <Link to="/configuration/classes" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-bookmark mr-1"></i>&nbsp;View Classes</Link>
                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    

      const editRecord=(record)=>{

            buildMdlCrud(false);
            setTimeout(() => {
                buildMdlCrud(<EditClasStream record={record} refresh={geTransactions}/>)
            }, 10);
        
        
    }


   const RenderTransactions=()=>{
        return(
           
                        <div className="table-responsive table-ellipsis">
                        <table className="table expandable-table table-striped text-nowrap mb-0">
                            <thead>
                                <tr>
                                <th>No.</th>                                   
                                <th>Stream Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(transactions) && transactions.map((item, key)=>
                                <tr 
                                    className={`${item.stream_id===transactionId?'flag-row':'hot-tr'}`}
                                    style={{cursor:'default'}}
                                    onClick={()=>onSelectRecord(item.stream_id, item)}
                                    key={key}>
                                    <td>{key+1}</td>
                                    <td>{item.stream_name}</td>
                                    <td>
                                        <span className='action'>
                                            <button type="button" className="btn btn-primary btn-flat btn-pri btn-sm" onClick={()=>editRecord(item)}>
                                                <i className='fa fa-pen'/>&nbsp;Edit</button>
                                            <button type="button" className="btn btn-danger btn-flat btn-pri btn-sm">
                                                <i className='fa fa-trash-alt'/>&nbsp;Delete
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            )}
            
                        </tbody>
                        </table>
                        {transactions==="404" && <AlertError type="info" message="No stream found for class"/>}
                        {!transactions && <ActivityLoader/>}
                    </div>
                    )
            
    }


        return (
            <PageContainer 
                title="Class Streams"
                history={{title:"Configurations", link:"/configuration"}}
                headerNav={<RenderControls/>}
                >
                        { mdlCrud }
                        <div className="row">
                            <div className='col-md-9 col-sm-12'>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Streams for {root.class_name}</h3>
                                    </div>
                                    <div className="card-body">

                                    {RenderTransactions()}

                                    </div>
                                </div>
                        

                            </div>

                            <ConfigMenu/>
                       
                         
                        </div>

                     
        </PageContainer>
        );
}

export default ClasStreamPage;