import React, { Component } from 'react';
import ConfigContext from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class EditSignature extends Component {

    static contextType = ConfigContext;

    state={
        title:'',
        info:false,
        sign:"",
        actualFile:"",
        fileSource:""

    }

    componentDidMount() {
        const { schoolProfile } = this.context;
        if(schoolProfile)
        {
            this.setState({
                title:schoolProfile.signature_title
            })
        }
       
    }

  

    handleFormChange=(event)=>{
        this.setState({
           [event.target.name]:event.target.value
        })
    }


   handleFileChange=(event)=>{
        this.setState({
            fileSource:event.target.value
        })
        let files=event.target.files;
        let reader=new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload=(e)=>{
            this.setState({
                actualFile:{file:  e.target.result}
            })
            
        }
}

   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const { title }=this.state;
        
        const data={
    
            "signature_title": title,
            "signature_file":this.state.actualFile
        }
       
        if(title.length>0)
        {
          
            const server_response = await ajaxConfig.updateSignature(data);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


  

    render() {
        return (
            
    <SystemModal id="mdl-edit-signature" title="Edit Signature" type="modal-lg">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
             
                <TextInput
                    type="text"
                    label="Signature Title"
                    placeholder="Signature Title"
                    leftIcon="fa fa-user"
                    value={this.state.title}
                    name="title"
                    onChange={this.handleFormChange} 
                    required
                    />


                <TextInput
                    type="file"
                    label="Signature File"
                    placeholder="Website"
                    leftIcon="fa fa-paperclip"
                    value={this.state.fileSource}
                    name="badge"
                    onChange={this.handleFileChange} 
                    />


       
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default EditSignature;