/* eslint-disable import/no-anonymous-default-export */
import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('eschool@user');
const apiHost=dictionary.apiHost

export default {

    async getStudents(data) {

      const response = await apiCall("student/filter", data);
      return response;

    },

    async registerStudent(data) {
        try {
          let response = await fetch(apiHost + 'student/register',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },
      async importStudents(data) {
       
        try {
          let response = await fetch(apiHost + 'student/import',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async getStudentPopulation() {
        try {
          let response = await fetch(apiHost + 'student/population',
            {
              method: 'GET',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              }
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },


      async updateStudent(data) {
        let response = await apiCall("student/update", data);
        return response;

      },

      async fetchContacts(data) {

        let response = await apiCall("student/contact", data);
        return response;

      },

      async addContact(data) {

        let response = await apiCall("student/contact/add", data);
        return response;

      },


      async fetchContactTypo() {

        let response = await apiCall("student/contact/types", []);
        return response;

      },

      async updateContact(data) {

        let response = await apiCall("student/contact/update", data);
        return response;

      },


      async getStudentInfo(studentId) {

        let response = await apiCall("student/info", {student_id:studentId});
        return response;

      },

      async getStudentInfo(studentId) {

        let response = await apiCall("student/info", {student_id:studentId});
        return response;

      },



}