import React from 'react'

class InfoBox extends React.Component {

    render() {
        return (
          <div className="col-md-4 col-sm-6 col-12">
            <div className={`info-box mb-3 ${this.props.bg}`}>
              <span className="info-box-icon">
                  <i className={this.props.iconName}></i>
                </span>

              <div className="info-box-content">
                <span className="info-box-text">{this.props.title}</span>
                <span className="info-box-number">{this.props.value}</span>

              {this.props.progress && <>
                <div class="progress">
                  <div class="progress-bar" style={{"width":this.props.progress.percentage + "%"}}></div>
                </div>
                <span class="progress-description">
                  { this.props.progress.description }
                </span>
              </>}
              

              </div>
            </div>
          </div>
        )
    }

}

export default InfoBox