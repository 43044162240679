import React, { useEffect, useState } from 'react';
import AlertError from '../Components/Common/AlertError';
import ajaxMark from '../util/remote/ajaxMark';

const MarksContext = React.createContext();

export const MarksConsumer=MarksContext.Consumer;

export const MarksProvider=(props)=> {

    const [classId, setClassId] = useState(false);
    const [paperId, setPaperId] = useState(false);
    const [streamId, setStreamId] =useState(false);
    const [organisedList, setOrganisedList] = useState(500);
    const [info, setInfo] = useState(false);

    useEffect(()=>{
        if(classId>0 && paperId>0)
        {
            getOrganisedList(true);
        }
    }, [classId, paperId])
    
    const getOrganisedList=async(init)=>{
        if(init)
        {
            setOrganisedList(false);
        }
        setInfo(false);
        const server_response = await ajaxMark.getOrganiseMarksList(classId, paperId);
        if(server_response.status==="OK")
        {
            setOrganisedList(server_response.details);
        }else{
            setOrganisedList("404");
            setInfo(<AlertError message={server_response.message}/>);
        }
    }

     
        return (
           <MarksContext.Provider value={
                {
                  paperId,
                  classId,
                  streamId,
                  info,
                  organisedList,
                  setClassId,
                  setPaperId,
                  setStreamId,
                  getOrganisedList
                }
               }>
               {props.children}
           </MarksContext.Provider>
        );
    
}

export default MarksContext;