import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import ReportContext from '../../../Context/ReportContext';
import functions from '../../../util/functions';
import PopReportComments from '../PopReportComments';
import PopReportExams from '../PopReportExams';
import ReportFormatSelector from '../ReportFormatSelector';
import ReportFormaTwo from '../Template/ReportFormaTwo';
import ActivityLoader from '../../Common/ActivityLoader';
import ajaxReport from '../../../util/remote/ajaxReport';
const ACTIONS={
  REPORT_COMMENTS : "change-report-comments",
  REPORT_EXAMS : "change-report-exams",
  RESET:"reset"
}
const ReportInfo = (props)=> {

        const {viewReport, setViewReport} = useContext(ReportContext)
        const [mdlReportComment, setMdlReportComment] = useState(false);
        const [mdlActive, setMdlActive] = useState(0)
        const [syncFees, setSyncFees] = useState(false)
        
        
        const mdlListing={
          mdl:false,
          id:"mdl-undef"
        }
        useEffect(()=>{
          if(mdl.id)
          {
            functions.openPopup(mdl.id)
            

          }
        }, [mdlActive])

        const reducer=(state, action)=>{
          // setMdlActive(false);
          switch(action){
              case ACTIONS.REPORT_COMMENTS:
                  setMdlActive(prev=>prev+1)
                  return {mdl:<PopReportComments report={props.report}/>, id:"mdl-report-comment"}
              case ACTIONS.REPORT_EXAMS:
                  return {mdl:<PopReportExams report={props.report}/>, id:"mdl-report-exams"}
              case ACTIONS.RESET:
                  return {mdl:false}
              default: 
              return state;
          }

        }

       

        const [mdl, handleChange] = useReducer(reducer, mdlListing)

       
        const handleSyncFees=async()=>{
          setSyncFees(true);
          const server_response = await ajaxReport.syncSchoolFees(props.report.report_info.class.class_id);
          setSyncFees(false);
          if(server_response.status==="OK"){

          }
        }

       


        const ReportSummary=()=>{
            return(
                <div className="card card-light">
                <div className="card-header">
                  <h3 className="card-title">{props.report.report_info.title}</h3>
                </div>
                <div className="card-body">
                  <strong><i className="fas fa-book mr-1"></i> About</strong>
                  <span  style={{float:'right'}}>
                  <Link 
                      to={`/report/update`} 
                      state={{record:props.report.report_info}} 
                      className="btn btn-sm btn-primary">Edit Report</Link>&nbsp;&nbsp;
                    <button className="btn btn-sm btn-success" onClick={()=>setViewReport(true)}>View Reports</button>&nbsp;&nbsp;
                    <Link to="/report/marksheet" className="btn btn-sm btn-secondary">General Marksheet</Link>

                  </span>
                 
                    <ol className='list-description text-muted'>
                        <li><span className="badge badge-secondary">Term: {props.report.report_info.term.term}</span></li>
                        <li><span className="badge badge-secondary">Class: {props.report.report_info.class.class_name}</span></li> 
                        <li><span className="badge badge-secondary">Year: {props.report.report_info.year}</span></li>
                    </ol>
                   
    
                  <hr/>
    
                  <strong><i className="fas fa-pencil-alt mr-1"></i> Report Exams</strong>
                  {/* <button
                    onClick={()=>handleChange(ACTIONS.REPORT_EXAMS)} 
                    className="btn btn-sm btn-primary" 
                    style={{float:'right'}}>Change</button> */}
    
                    <ol className='list-description text-muted'>
                        {props.report.report_info.report_tests && props.report.report_info.report_tests.map((item, key)=>
                         <li key={key}><span className="badge badge-secondary">{item.test.exam_name}/{item.out_of_exact}</span></li>
                        )}
                    </ol>
    
                  <hr/>
    
                  <strong><i className="fas fa-file mr-1"></i> Report Format</strong>
                  {/* <button className="btn btn-sm btn-primary" style={{float:'right'}}>Change</button> */}
    
                  <p className="text-muted">{props.report.report_info.report_format && props.report.report_info.report_format.format_name + "(" + props.report.report_info.report_format.description + ")"}</p>
    
                  <hr/>
    
                  <strong><i className="far fa-file-alt mr-1"></i> Report Comments</strong>
                  <Link to="/report/comments" 
                    // onClick={()=>handleChange(ACTIONS.REPORT_COMMENTS)}
                    className="btn btn-sm btn-primary" 
                    style={{float:'right'}}>Change</Link>
    
                  <p className="text-muted">Report comments represent general and specific remarks summarise the student performance and any other communication</p>
               
               
                <hr/>
    
                  <strong><i className="fa fa-signal mr-1"></i> Primary Grading</strong>
    
                  <p className="text-muted">{props.report.report_info.primary_scale && props.report.report_info.primary_scale.title}</p>
               
               
                <hr/>
    
                  <strong><i className="fa fa-signal mr-1"></i> Secondary Grading</strong>
    
                  <p className="text-muted">{props.report.report_info.advanced_scale && props.report.report_info.advanced_scale.scale_name + "(" + props.report.report_info.advanced_scale.description + ")"}</p>
    
                <hr/>
                   <strong><i className="fa fa-book mr-1"></i> Areas Of Integration</strong>
                   {/* <button className="btn btn-sm btn-primary" style={{float:'right'}}>Change</button> */}
    
                  <p className="text-muted">N/A</p>

                <hr/>
                   <strong><i className="fa fa-comment mr-1"></i> School Fees Comments</strong>
                   {!syncFees && <button className="btn btn-sm btn-primary" style={{float:'right'}} onClick={handleSyncFees}>Sync Now</button>}
                    
                    {syncFees && <button className="btn btn-sm btn-primary" style={{float:'right'}}>
                    <ActivityLoader inline={true} size={20} color="#FFFFFF"/>
                   </button>}

                  <p className="text-muted">Automatically populate school fees balance and next term fees comments. This only works if your academics system is linked to YEXP Accounting system.</p>
               
                </div>
              </div>
            )
        }


        const ReportView=()=>{
            return(
               <ReportFormatSelector/>
            )
        }


        if(viewReport)
        {
            return <ReportView/>
        }else{
            return <>
                  {mdl.mdl}
                  <ReportSummary/>
            </>
                
        }
       
    }


export default ReportInfo;