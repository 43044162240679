import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import dictionary from '../../util/dictionary';
import ajaxAuth from '../../util/remote/ajaxAuth';
import logo from '../../images/aralogo.png';
class Login extends Component {

    state={
        username:'',
        password:'',
        error:false,
        info:"",
        loading:false
    
    }

    onPressLogin=async (event)=>{
        event.preventDefault();
        const{username, password}=this.state;
        if(username.length>0 && password.length>0)
        {

            this.setState({
              info:<ActivityLoader/>
            })
          var server_response;
          if(process.env.REACT_APP_ENVIRONMENT==="DEVELOPMENT"){
            server_response=await ajaxAuth.login(username, password);
          }else{
            server_response=await ajaxAuth.captiveLogin(username, password);
          }
          if(server_response.status==="OK")
          {
            if(process.env.REACT_APP_ENVIRONMENT==="DEVELOPMENT"){
              localStorage.setItem('eschool@user', server_response.details);
              localStorage.setItem('eschool@remote', process.env.REACT_APP_DEVELOPMENTURL.slice(0, -1));

            }else{
              localStorage.setItem('eschool@user', server_response.details.remote_response.details);
              localStorage.setItem('eschool@remote', server_response.details.client_link);
            }

            window.location.reload();
           
          }else{
                this.setState({
                  info:<AlertError message={server_response.message}/>,
                  loading:false
                })
          }

        }else{
          this.setState({
            info:<AlertError message={dictionary._completeFields}/>,
            loading:''
          })
        }
       
       
      }

      onChangeUsername = (event) => {
        this.setState({username: event.target.value});
      }

      onChangePassword = (event) => {
        this.setState({password: event.target.value});
      }




      LoginCard=()=>{

        return(

          <div className="login-box">
         
          <div className="card" style={{height:"75vh", width:"30vw", padding:"30px"}}>
          <div className="card-body login-card-body">
          <div className="login-logo-custom">
            <h1>Login</h1>
          </div>
            <p className="login-box-msg">Sign in to start your session</p>
            {this.state.info}
            <form method="post" onSubmit={this.onPressLogin}>
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Username" onChange={this.onChangeUsername}/>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input type="password" className="form-control" placeholder="Password" onChange={this.onChangePassword}/>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember"/>
                    <label htmlFor="remember">
                      Remember Me
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <button type="submit" className="btn btn-success btn-block">Sign In</button>
                </div>
              </div>
            </form>
      
            
      
            <p className="mt-4">
              <a href="forgot-password.html">I forgot my password</a>
            </p>
            <p className="mb-4">
              <Link to="#" className="text-center">Request for a new account</Link>
            </p>
          </div>
        </div>
        </div>
        )

      }




  

    render() {
        return (
        <div className="col-12 login-page-custom">
          <div className='row mt-3'>
                <div className='col-12'>
                <img src={logo} alt="ARAKNERD" width="300px"/>

                    
                </div>
          </div>

          <div className='row'>

              <div className='col-lg-8 col-md-8 col-sm-12'>
                  

                    <div className='login-ad'>
                    
                        <h1>Welcome to Eschool <br/>cloud solution</h1>
                        <ul className='functions-list'>
                          <li>Includes new UNEB curriculum reports as of 2022</li>
                          <li>Highly configurable solution for academic reporting</li>
                          <li>Compatible with the YEXP accounting & School fees system</li>
                          <li>Scalable database solution for your students and staff</li>

                        </ul>
                    </div>


              </div>

              
              <div className='col-lg-4 col-md-4 col-sm-12'>
                    <this.LoginCard/>
              </div>


          </div>


         



          
      </div>
        );
    }
}

export default Login;