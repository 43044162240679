import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ConfigContext from '../../Context/ConfigContext';
import MarkEntryContext from '../../Context/MarkEntryContext';
import ajaxConfig from '../../util/remote/ajaxConfig';
import ActivityLoader from '../Common/ActivityLoader';
import CheckList from '../Common/List/CheckList';
import RadioList from '../Common/List/RadioList';

const MarkEntryFilter =(props)=>{
   
    const { currentExams, allSubjects, allPapers } = useContext(ConfigContext);
    const {classId, paperId, setClassId, setStreamList, setPaperId, streamId, setStreamId, streamList, getMarksEntryForm } = useContext(MarkEntryContext);
    const [ tempClassId, setTempClassId ] = useState(classId);
    
   
    const classList=[];
    Array.isArray(currentExams) && currentExams.map((item, key)=>{
        let examTitle = item.exam?item.exam.exam.exam_name : "Not set";
        classList.push({
            label:item.class.class_name + " (" + examTitle  + ")", 
            id:item.class.class_id
        })
    })

    const subjectList = [];
    Array.isArray(allSubjects) && allSubjects.map((item, key)=>{
        subjectList.push({label:item.subject, id:item.subject_id})
    })

    const paperList = [];
    Array.isArray(allPapers) && allPapers.map((item, key)=>{
        paperList.push({
            label:item.subject.subject + " (" + item.paper_code + ")", 
            id:item.paper_id
        })
    })

  
    const getStreams= async(id)=>{
        
        setTempClassId(id);
        setStreamList(false);
        const server_response = await ajaxConfig.getClassStreams(id);
        if(server_response.status==="OK")
        {
            setStreamList(server_response.details)
        }else{

            console.log("Setting default class as " + id)
            setStreamList("404");
            setClassId(id);
            // setTimeout(()=>{getMarksEntryForm()}, 20);
        }
    }


    const streamSelection=[];
    Array.isArray(streamList) && streamList.map((item, key)=>{
        streamSelection.push({
            label:item.stream_name, 
            id:item.stream_id
        })
    })

    const selectStream=(id)=>{

            setStreamId(id);
            setTimeout(()=>setClassId(tempClassId), 10);
            // setTimeout(()=>getMarksEntryForm(), 10);
    }

        return (
            <div 
                className="col-xl-3 col-lg-12 d-none d-xl-block custom-leftnav"
                style={{height:"550px", overflow:"auto"}}
                >
                <div className="main-content-left-components">
                  {!currentExams && <ActivityLoader/>}  
                 
                     <RadioList 
                        title="Subjects" 
                        id="subject-papers"
                        list={paperList}
                        onSelect={setPaperId}
                        activeId={paperId}
                        />

                        <RadioList 
                            title="Classes" 
                            element={!streamList?<ActivityLoader inline size={20}/>:false}
                            id="class"
                            list={ classList }
                            onSelect={getStreams}
                            activeId={classId}
                            />
                    


                           { Array.isArray(streamList) && <RadioList 
                                title="Streams" 
                                id="class_streams"
                                list={streamSelection}
                                onSelect={selectStream}
                                activeId={streamId}
                                />}

                    
                            


                </div>
        </div>
        );
    
}

export default MarkEntryFilter;