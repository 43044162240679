import React, { useContext, useEffect, useState } from 'react';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import ListItem from '../../Components/Common/List/ListItem';
import SelectSearch from '../../Components/Common/SelectSearch';
import TextInput from '../../Components/Common/TextInput';
import PageContainer from '../../Components/Headings/PageContainer';
import GeneralCommentWriter from '../../Components/Report/Elements/GeneralCommentWriter';
import ReportInfo from '../../Components/Report/Elements/ReportInfo';
import ListReports from '../../Components/Report/Nav/ListReports';
import ReportOption from '../../Components/Report/Nav/ReportOptions';
import PopCommentSign from '../../Components/Report/PopCommentSign';
import ReportContext from '../../Context/ReportContext';
import StaffContext from '../../Context/StaffContext';
import functions from '../../util/functions';
import ajaxReport from '../../util/remote/ajaxReport';



const ReportCommentsPage =(props)=> {


    const [info, setInfo] = useState(false)
    const [commentId, setCommentId] = useState("");
    const [reportComments, setReportComments] = useState(false);
    const [activeComment, setActiveComment] = useState(false)
    const [teacherId, setTeacherId] = useState("");

    const {staffList} = useContext(StaffContext);
    const {reportCommentListing, generateReports, currentReportId, currentReport} = useContext(ReportContext);
    const [mdlInitials, setMdlInitials] = useState(false)
    
    useEffect(()=>{
        getReportComments();
    }, [])

    const RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                
                    {/* <li>
                        <Link to="#" className="btn ripple btn-success mb-1">
                        <i className="fa fa-edit mr-1"></i>&nbsp;Create Custom List</Link>
                    </li>
                    <li>
                        <Link to="/configuration/classes" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-bookmark mr-1"></i>&nbsp;Alumni</Link>
                    </li> */}
                </ol>
            
    
                
            </div>
        
        )
    }

    const onChangeComment=(option)=>setCommentId(option.value)

   

    const getReportComments=async()=>{
        const server_response = await ajaxReport.getReportComments(currentReportId);
        if(server_response.status==="OK")
        {
            setReportComments(server_response.details)
        }else{
            setReportComments("404")
            setInfo(<AlertError type="danger" message={server_response.message}/>)
        }
    }


    const RenderComment=()=>{

        const optionsListing=[];

        if(Array.isArray(reportCommentListing))
        {
            reportCommentListing.map((item, key)=>
            optionsListing.push({label:item.title, value:item.id})
            )
         }



      
            return(
                <TextInput
                    label="Set Report Comment"
                    leftIcon="fa fa-comment"
                    type="select" 
                    placeholder="Select comment...." 
                    options={optionsListing}
                    onChange={onChangeComment}
                    leftComponent={<i className="fa fa-comment txt-primary"/>}
                    wrap
                />
            )

          
        }



       

   
    

   const confirmSave=async(event)=>{
        event.preventDefault();
        if(commentId>0 && currentReportId>0)
        {   
            setInfo(<ActivityLoader/>)
           const server_response = await ajaxReport.addReportComment(currentReportId, commentId);
           if(server_response.status==="OK")
           {
            setInfo(<AlertSuccess message={server_response.message}/>);
            getReportComments();
           }else{
            setInfo(<AlertError message={server_response.message}/>)
           }

        }else{
            
            setInfo(<AlertError message={"Select comment and try again"}/>)
        
        }
    }


    const refresh=(updated)=>{
        setActiveComment(false);
        if(updated)
        {
            getReportComments();
        }
        
    }


    const deleteCommentField=async(fieldId)=>{

        const server_response = await ajaxReport.deleteReportCommentField(fieldId);
        if(server_response.status==="OK")
        {
            getReportComments();
            generateReports();
            setInfo(<AlertSuccess message={server_response.message}/>)
        }else{
            setInfo(<AlertError type="info" message={server_response.message}/>)

        }

    }


    const RenderItem=(propItem)=>{
        const item = propItem.item;
        if(item.comment.scope.id*1===2)
        {
            if(activeComment && item.id===activeComment.id)
            {   
                return <GeneralCommentWriter field={item} onConfirm={refresh}/>

            }else{

                return <ListItem 
                title={item.comment.title}
                subtitle={item.value}
                delete={()=>{deleteCommentField(item.id)}}
                edit={()=>{setActiveComment(item)}}
                listIcon="fa fa-comment"
                />
            }
           
        }else{
            return <ListItem 
            title={item.comment.title}
            delete={()=>{deleteCommentField(item.id)}}
            listIcon="fa fa-comment txt-primary"
            edit={()=>handleChangeInitials(item)}
            subtitle={item.staff?item.staff.full_name : "No initials set"}
            // component={<RenderTeacher/>}
        />
        }
    }



    useEffect(()=>{
        if(mdlInitials)
        {
            functions.openPopup("mdl-report-comment-sign")
        }
    }, [mdlInitials])


   const  handleChangeInitials=(comment)=>{

        if(mdlInitials){
            setMdlInitials(false);
            setTimeout(() => {
                setMdlInitials(<PopCommentSign commentField={comment} refresh={getReportComments}/>)
            }, 10);
        }else{
            setMdlInitials(<PopCommentSign commentField={comment}/>)

        }

    }


      

        return (
            <PageContainer 
                title="Change Report Comments"
                // history={{title:"Configurations", link:"/configuration"}}
                headerNav={<RenderControls/>}
                >
                    {mdlInitials}
                        <div className="row">
                            <div className='col-md-9 col-sm-12'  style={{height:"550px", overflow:"auto"}}>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Add, remove and update report comments 
                                         {currentReport && <> - {currentReport.report_info.title}</>}
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                       



                                        <form method="post" className="form-horizontal" onSubmit={confirmSave}>
                                            <div className='row'>
                                                <div className='col-12'>
                                                {info}

                                                {!currentReportId && <AlertError type="warning" message="No active report found"/>}


                                                <ul className="todo-list" data-widget="todo-list">
                                                {Array.isArray(reportComments) && reportComments.map((item, key)=>
                                                    <RenderItem item={item} key={key}/>
                                                )}
                                                </ul>
                                                </div>
                                            </div>


                                            <div className='row'>
                                                <div className='col-8'>
                                                    {RenderComment()}
                                                </div>
                                                <div className='col-4'>
                                                    <button type="submit" className="btn btn-success" style={{marginTop:"35px"}}>Add Comment</button>
                                                </div>
                                            </div>
                    
                        
                        
                    
                                        </form> 






                                    </div>
                                </div>
                        

                            </div>

                             {/** REPORT OPTIONS */}
                            <div 
                                className="col-xl-3 col-lg-12 d-none d-xl-block custom-leftnav"

                                // style={{height:"1000px", overflow:"auto"}}
                                >
                                <div className="main-content-left-components">



                                   <ReportOption/>

                                   
                                </div>

                            </div>
                             {/** END REPORT OPTIONS */}
                       
                         
                        </div>

                     
        </PageContainer>
        );
}

export default ReportCommentsPage;