import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import WarnDelete from '../../Components/Common/WarnDelete';
import PageContainer from '../../Components/Headings/PageContainer';
import MarksFilter from '../../Components/Marks/MarksFilter';
import ConfigContext from '../../Context/ConfigContext';
import MarksContext from '../../Context/MarksContext';
import functions from '../../util/functions';
import ajaxMark from '../../util/remote/ajaxMark';
import ButtonGroup from '../../Components/Common/Controls/ButtonGroup';

const OrganiseMarksPage =(props)=> {

    const { currentPeriod } = useContext(ConfigContext);
    const { info, organisedList, paperId, classId, getOrganisedList } = useContext(MarksContext);

    const period = currentPeriod?"Term " + currentPeriod.term.term + " " + currentPeriod.acad_year : "...";
   
    const [studentProgress, setStudentProgess] = useState(false);
    const [linfo, setLinfo] = useState(false);
    const [mdl, buildMdl] = useState(false);

    useEffect(()=>{
        if(mdl)
        {
            functions.openPopup("mdl-org-marks");
        }
    }, [mdl])
   
    const organiseMark = async(studentlist)=>{
        setLinfo(false)
        const server_response = await ajaxMark.organiseMarks(studentlist, classId, paperId);
        if(server_response.status==="OK")
        {
            getOrganisedList(false);
            setStudentProgess(false)
        }else{
            setLinfo(<AlertError type="info" message={server_response.message}/>)
        }
    }

    const addMark=(studentId)=>{
        setStudentProgess(studentId);
        organiseMark([studentId])
    }
    
    const RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                 
                    <li>
                        <ButtonGroup title="Options" type="primary">
                            <Link to="#" className="dropdown-item" onClick={onClickAddAll}>
                                <i className="fa fa-plus mr-1"></i>&nbsp;Add All</Link>
                            
                   
                            <Link to="#" className="dropdown-item" onClick={onClickRemoveAll}>
                            <i className="fa fa-trash-alt mr-1"></i>&nbsp;Remove All</Link>
     
                            <Link to="#" className="dropdown-item" onClick={onClickRecallAdd}>
                            <i className="fa fa-sync mr-1"></i>&nbsp;Recall & Add</Link>

                          
                        </ButtonGroup>

                    </li>

                </ol>
               
    
                
            </div>
          
        )
      }


      const deleteUnit=async(record)=>{
       
        setStudentProgess(record.student.id);
        const server_response = await ajaxMark.removeStudent(record.mark.id);
        setStudentProgess(false);
        if(server_response.status==="OK")
        {
            getOrganisedList(false);
            functions.closePopup("mdl-org-marks")
        }else{
  
           setLinfo(<AlertError message={server_response.details.message}/>)
        }
    }



    const addAllStudents=async()=>{

        setLinfo(false)
        const server_response = await ajaxMark.organiseAllSubjectStudents(classId, paperId);
        if(server_response.status==="OK")
        {
            getOrganisedList(false);
            setStudentProgess(false)
            functions.closePopup("mdl-org-marks")
            setLinfo(<AlertSuccess message={server_response.message}/>)


        }else{
            setLinfo(<AlertError type="info" message={server_response.message}/>)
            functions.closePopup("mdl-org-marks")
        }

    }


    const removeAllStudents=async()=>{

        setLinfo(false)
        const server_response = await ajaxMark.clearAllSubjectStudents(classId, paperId);
        if(server_response.status==="OK")
        {
            getOrganisedList(false);
            setStudentProgess(false)
            functions.closePopup("mdl-org-marks")
            setLinfo(<AlertSuccess message={server_response.message}/>)


        }else{
            setLinfo(<AlertError type="info" message={server_response.message}/>)
            functions.closePopup("mdl-org-marks")
        }

    }




    const recallAddStudents=async()=>{

        setLinfo(false)
        const server_response = await ajaxMark.organiseFromHistory(classId);
        if(server_response.status==="OK")
        {
            getOrganisedList(false);
            setStudentProgess(false)
            functions.closePopup("mdl-org-marks")
            setLinfo(<AlertSuccess message={server_response.message}/>)


        }else{
            setLinfo(<AlertError type="info" message={server_response.message}/>)
            functions.closePopup("mdl-org-marks")
        }

    }

    

      const onClickRemove =(record)=>{

        buildMdl(false);
            setTimeout(() => {
                buildMdl(<WarnDelete 
                                id="mdl-org-marks"
                                title="Remove Student"
                                description={"Are you sure you want to remove " + record.student.last_name + " " + record.student.first_name + " (" + record.student.reg_no + ") from the marksheet ?" } 
                                onConfirm={()=>deleteUnit(record)}
                                />)
            }, 10);
        }



        const onClickAddAll =()=>{

            buildMdl(false);
                setTimeout(() => {
                    buildMdl(<WarnDelete 
                                    id="mdl-org-marks"
                                    title="Add Entire Class ?"
                                    description={"Are you sure you want to add the entire class to the selected subject marksheet" } 
                                    onConfirm={addAllStudents}
                                    buttonLabel="I Know what am doing"
                                    />)
                }, 10);
            }


            const onClickRemoveAll =()=>{

                buildMdl(false);
                    setTimeout(() => {
                        buildMdl(<WarnDelete 
                                        id="mdl-org-marks"
                                        title="Remove Entire Class ?"
                                        description={"Are you sure you want to remove this subject from the selected subject marksheet" } 
                                        onConfirm={removeAllStudents}
                                        buttonLabel="Confirm Marks Delete"
                                        />)
                    }, 10);
                }



                const onClickRecallAdd =()=>{

                    buildMdl(false);
                        setTimeout(() => {
                            buildMdl(<WarnDelete 
                                            id="mdl-org-marks"
                                            title="Recall & Add ?"
                                            description={"Students previously added in the last test/exam will be automatically added to the current test/exam. Are you sure you want to continue ?" } 
                                            onConfirm={recallAddStudents}
                                            buttonLabel="Confirm Action"
                                            />)
                        }, 10);
                    }
        
    

   const RenderTransactions=()=>{
        return(
           
                        <div className="table-responsive table-ellipsis">
                       {Array.isArray(organisedList) && <table className="table expandable-table table-striped text-nowrap mb-0">
                            <thead>
                                <tr>
                                <th>No.</th>                                   
                                <th>Reg No.</th>
                                <th>Student Name</th>
                                <th>Class</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                          {organisedList.map((item, key)=>
                            <tr key={key}>
                                <td>{key +1}</td>
                                <td>{item.student.reg_no}</td>
                                <td>{item.student.last_name + " " + item.student.first_name}</td>
                                <td>{item.student.class.class_name}</td>
                                <td>
                                    {(item.student.id===studentProgress) && <ActivityLoader size={20} inline/>}
                                    {!(item.student.id===studentProgress) && <>
                                        {!item.mark && <button className='btn btn-primary btn-sm' onClick={()=>addMark(item.student.id)}>Add</button>}
                                        {item.mark && <button className='btn btn-danger btn-sm' onClick={()=>onClickRemove(item)}>Remove</button>}
                                    </>}
                                 
                                </td>
                            </tr>
                          )}
            
                        </tbody>
                        </table>}
                       {!organisedList && <ActivityLoader/>}
                       {organisedList===500 && <AlertError type="info" message="Select class and subject to continue"/>}
                       
                    </div>
                    )
            
    }


        return (
            <PageContainer 
                title={"Organise Marks (" + period + ")"}
                // history={{title:"Configurations", link:"/configuration"}}
                headerNav={<RenderControls/>}
                >
                    {mdl}
                        <div className="row">
                            <div className='col-md-9 col-sm-12'>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Organise mark sheets for current term: Select class/stream and subject</h3>
                                    </div>
                                    <div className="card-body">
                                        {info}{linfo}

                                    {RenderTransactions()}

                                    </div>
                                </div>
                        

                            </div>

                            <MarksFilter/>
                       
                         
                        </div>

                     
        </PageContainer>
        );
}

export default OrganiseMarksPage;