import React, { Component } from 'react';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class NewClassRoom extends Component {

    state={
        className:'',
        levelId:'',
        info:false
    }


    onChangeClass=(event)=>{
        this.setState({
            className:event.target.value
        })
    }

    onChangeLevel=(option)=>{
        this.setState({
            levelId:option.value
        })
    }



    RenderLevel=()=>{

        return(<ConfigConsumer>

            {props=>{

                const optionsListing=[];

                if(Array.isArray(props.classLevels))
                {
                    props.classLevels.map((item, key)=>
                    optionsListing.push({label:item.level, value:item.level_id})
                    )
                 }

                return(
                    <TextInput
                        label="Class level"
                        leftIcon="fa fa-bookmark"
                        type="select" 
                        placeholder="Select class level...." 
                        options={optionsListing}
                        onChange={this.onChangeLevel}
                        leftComponent={<i className="fa fa-bookmark"/>}
                        wrap
                    />
                )

            }}

        </ConfigConsumer>)
       
        }

   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {className, levelId}=this.state;
        if(className.length>0 && levelId>0)
        {
            const server_response = await ajaxConfig.createClass(levelId, className);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>,
                    className:''
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {
        return (
            
    <SystemModal id="mdl-new-class" title="New Class">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="Class Name"
                            placeholder="Class Name"
                            leftIcon="fa fa-bookmark"
                            value={this.state.className}
                            onChange={this.onChangeClass} 
                            required
                            wrap
                            />
               <this.RenderLevel/>
    

            <div className="row">&nbsp;</div>


       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Class</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default NewClassRoom;