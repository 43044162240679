import React from "react";

const AOIKey=(props)=>{

    return<>
     <span style={{fontSize:"13px"}}>Key to Terms Used: </span>
            <table className="Reports" border="1" cellSpacing="0" width="100%" style={{border:"solid", fontFamily:"Arial, Helvetica, sans-serif", fontSize:"13px", marginTop:"0px",  marginBottom:"0px"}}>
                <tbody>
                    <tr>
                        <td colSpan={2}><i>Identifier</i>: <b>Descriptor (Meaning)</b></td>
                    </tr>
                    <tr>
                        <td style={{textAlign:"center"}}> - </td>
                        <td>No Learning outcomes achieved (<b>Learner was absent</b>)</td>
                    </tr>
                    <tr>
                        <td style={{textAlign:"center", fontWeight:"bold"}}> 1 </td>
                        <td>Some <b>LO</b>s achieved but not sufficient for overall achievement <b>(Basic)</b></td>
                    </tr>

                    <tr>
                        <td style={{textAlign:"center", fontWeight:"bold"}}> 2 </td>
                        <td>Most <b>LO</b>s achieved, enough for overall learning achievement <b>(Moderate)</b></td>
                    </tr>

                    <tr>
                        <td style={{textAlign:"center", fontWeight:"bold"}}> 3 </td>
                        <td>All <b>LO</b>s achieved achievement with ease <b>(Accomplished)</b></td>
                    </tr>

                </tbody>
            </table>
            <div style={{flexDirection:"row", justifyContent:"stretch", display:"flex", marginBottom:"15px"}}>
                <span style={{fontSize:"13px"}}>
                    <i><b>LO</b> = Learning Outcomes</i>
                </span>

                <span style={{fontSize:"13px", marginLeft:"15px"}}>
                    <i><b>C1</b> = Chapter 1 Assessment, etc.</i>
                </span>
            </div>

    </>
}

export default AOIKey