import React, { Component } from 'react';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class EditPaper extends Component {

    state={
        paperName:this.props.record.paper_code,
        paperId:this.props.record.paper_id,
        info:false
    }


    onChangePaper=(event)=>{
        this.setState({
            paperName:event.target.value
        })
    }

  

   
   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {paperName, paperId}=this.state;
        if(paperName.length>0 && paperId>0)
        {
            const server_response = await ajaxConfig.updateSubjectPaper(paperId, paperName);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {
        return (
            
    <SystemModal id="mdl-new-paper" title="New Subject Paper">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="Paper Name"
                            placeholder="Paper Name"
                            leftIcon="fa fa-bookmark"
                            value={this.state.paperName}
                            onChange={this.onChangePaper} 
                            required
                            wrap
                            />
    

            <div className="row">&nbsp;</div>


       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Update Paper</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default EditPaper;