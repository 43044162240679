import React, { useContext, useState } from "react"
import ConfigContext from "../../Context/ConfigContext";
import StudentContext from "../../Context/StudentContext";
import FilterSelectInput from "../Common/Filter/FilterSelectInput";
import FilterTextInput from "../Common/Filter/FilterTextInput";
import ScrollFilterBar from "../Common/Filter/ScrollFilterBar";

const FilterStudent=(props)=>{

    const { resetData, filterData} = useContext(StudentContext);
    const {allClasses, residenceListing } = useContext(ConfigContext);
    const [streamList, setStreamList] = useState([]);


    const optionsclassList=[{title:"All Classes", value:"0"}];
    if(Array.isArray(allClasses))
    {
        allClasses.forEach(item=>
            optionsclassList.push({title:item.class_name, value:item.class_id})
        )
    }

    const optionsGender=[
        {title:"Select", value:""},
        {title:"Female", value:"Female"},
        {title:"Male", value:"Male"}
    ]


    const optionsResidence=[{title:"All Residences", value:"0"}];
    if(Array.isArray(residenceListing))
    {
       residenceListing.forEach(item=>
            optionsResidence.push({title:item.residence_name, value:item.id})
        )
    }

    const onSelectClass=(classId)=>{
        const listing=[];
       
       if(Array.isArray(allClasses)){
             allClasses.forEach(element => {
            if(element.class_id*1===classId*1){
                if(Array.isArray(element.streams)){
                    element.streams.forEach(stream=>{
                        listing.push({title:stream.stream_name, value:stream.stream_id})
                    })
                }//ends streams
            }//ends class
        });
      
        setStreamList(listing)
        }
    }

    return  <ScrollFilterBar
    onReset={resetData}
    onConfirm={filterData}
    firstScreen={
      {
        inputs:[
         
            (onSet, key, currentValues)=><FilterSelectInput
              key={key}
              label="Student Class"
              options={optionsclassList}
              onChange={onSet}
              name="studentClass"
              currentValues={currentValues}
              onSelect={onSelectClass}
            />,
            (onSet, key, currentValues)=> <FilterSelectInput
            key={key}
            label="Class Stream"
            options={streamList}
            onChange={onSet}
            name="studentStream"
            currentValues={currentValues}
          />,
          (onSet, key, currentValues)=> <FilterSelectInput
          key={key}
          label="Student Residence"
          options={optionsResidence}
          onChange={onSet}
          name="studentResidence"
          currentValues={currentValues}
        />,
        (onSet, key, currentValues)=> <FilterSelectInput
        key={key}
        label="Gender"
        options={optionsGender}
        onChange={onSet}
        name="gender"
        currentValues={currentValues}
      />
        ],
        names:["studentClass", "studentStream", "studentResidence", "gender"]
      }
    }
/>

}


export default FilterStudent