import React, { useEffect, useState } from "react"
import functions from "../../util/functions";
import ajaxStudent from "../../util/remote/ajaxStudent"
import ActivityLoader from "../Common/ActivityLoader";
import ItemNotFound from "../Common/ItemNotFound";
import PopEditContact from "./PopEditContact";
import PopNewContact from "./PopNewContact";

const ContactPerson=(props)=>{

    const [contactList, setContactList] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [studentId, setStudentId] = useState(props.studentId);
    const [modal, setModal] = useState(false)


    useEffect(()=>{
        getContacts();
    }, [])

    useEffect(()=>{
        if(modal){
            functions.openPopup("mdl-new-contact")
        }
    }, [modal])

    const getContacts=async()=>{
        const server_response = await ajaxStudent.fetchContacts({"student_id":studentId})
        if(server_response.status==="OK"){
            setContactList(server_response.details);
        }else{
            setContactList("404")
            setResponseMessage(server_response.message)
        }
    }


    const addContact=()=>{
        setModal(<PopNewContact studentId={studentId} refresh={getContacts}/>)
    }

    const editContact=(record)=>{
        setModal(false);
        setTimeout(() => {
            setModal(<PopEditContact record={record} refresh={getContacts}/>)

        }, 100);
    }

    return <>
            {modal}
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Contact Information
                    </h3>
                    <span className="link" style={{float:'right'}} onClick={addContact}><i className="fa fa-plus" title="Add Contact"></i></span>

                </div>

                <div className="card-body">



                           {Array.isArray(contactList) && <ul className="task-list">
                                 {contactList.map((item, key)=><li key={key}>
                                    <i 
                                        className={`task-icon cursor-pointer ${item.default_contact?'bg-primary':'bg-secondary'}`} 
                                        title="Click to Edit" 
                                        onClick={()=>{editContact(item)}}
                                        ></i>
                                    <h6>{item.contact}<span className="float-right text-muted fs-11">{item.default_contact?"Default":""}</span></h6>
                                    <p className="text-muted fs-12">{item.contact_name} <span className="text-primary"> {item.contact_type.type_name}</span></p>
                            </li>)}
                              
							</ul>}

                            {contactList==="404" && <ItemNotFound description={responseMessage}/>}

                            {!contactList && <ActivityLoader/>}


                <div className="row">&nbsp;</div>
        
    

                </div>
            </div>
    
            </>
}



export default ContactPerson