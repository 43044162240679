import React from 'react';
const AdvancedScale = (props)=> {
        return (
        <>
          <table className="Reports" style={{fontSize:"13px", borderLeft:"solid", borderRight:"solid", borderBottom:"solid"}} width="100%" border="1" cellSpacing="0" cellpadding="0">
            <tr>
            <th>Grade</th>
            <th>Points</th>
            <th>Papers</th>
            <th style={{borderRight:"solid"}}>Condition</th>
            
            <th>Grade</th>
            <th>Points</th>
            <th>Papers</th>
            <th>Condition</th>
            
            </tr>
                <tr>
                <td rowSpan="2" align="center">A</td>
                <td rowSpan="2" align="center">6</td>
                <td align="center">2</td>
                <td style={{borderRight:"solid"}}>All must be distinctions.</td>
                <td rowSpan="2" align="center">E</td>
                <td rowSpan="2" align="center">2</td>
                <td align="center">2</td>
                <td>At worst C6 or P7 and other paper must be at worst C5.</td>
                </tr>
                <tr>
                <td>3 or 4</td>
                <td style={{borderRight:"solid"}}>At worst C3 in only one paper and the rest distinctions.</td>
                <td>3 or 4</td>
                <td>At worst P7 or P8 in one of the three papers and rest credits or Ds</td>
                </tr>
                <tr>
                <td rowSpan="2" align="center">B</td>
                <td rowSpan="2" align="center">5</td>
                <td align="center">2</td>
                <td style={{borderRight:"solid"}}>At worst C3 in one paper and a higher grade in the second paper.</td>
                <td rowSpan="2" align="center">O</td>
                <td rowSpan="2" align="center">1</td>
                <td align="center">2</td>
                <td>One pass and better.</td>
                </tr>
                <tr>
                <td align="center">3 or 4</td>
                <td style={{borderRight:"solid"}}>At worst C4 in only one paper and higher grades in the other papers.</td>
                <td align="center">3 or 4</td>
                <td>At worst 2 passes in 2 papers and a dinstinction or credit in other</td>
                </tr>
                <tr>
                <td rowSpan="2" align="center">C</td>
                <td rowSpan="2" align="center">4</td>
                <td align="center">2</td>
                <td style={{borderRight:"solid"}}>At worst C4 in one of the papers and the rest must be a higher grade.</td>
                <td rowSpan="2" align="center">F</td>
                <td rowSpan="2" align="center">0</td>
                <td align="center">2</td>
                <td>All F9s, one F9 and a pass.</td>
                </tr>
                <tr>
                <td align="center">3 or 4</td>
                <td style={{borderRight:"solid"}}>At worst C5 in only one of the papers and higher grades in the other.</td>
                <td align="center">3 or 4</td>
                <td>At worst 2 F9s, F9 and P8, All F9s.</td>
                </tr>
                <tr>
                <td rowSpan="2" align="center">D</td>
                <td rowSpan="2"  align="center">3</td>
                <td align="center">2</td>
                <td style={{borderRight:"solid"}}>At worst C5 in one paper and a higher grade in the other paper.</td>
                <td rowSpan="2" align="center">X</td>
                <td rowSpan="2" align="center">0</td>
                <td rowSpan="2">2,3,4</td>
                <td rowSpan="2">Missing a paper.</td>
                </tr>
                <tr>
                <td align="center">3 or 4</td>
                <td style={{borderRight:"solid"}}>At worst C6 in only one paper and a higher grade in the other paper.</td>
                </tr>
            </table>
        
       </>
        );
    }


export default AdvancedScale;