import React, { Component } from 'react';
import LeftSideBar from '../Common/LeftSideBar';
import TopBar from '../Common/TopBar';
import ContentHeader from './ContentHeader';

class PageContainer extends Component {
    render() {
        return (
            <>
            <TopBar/>
            <LeftSideBar/>
            <div className="content-wrapper">
                <ContentHeader title={this.props.title} history={this.props.history} nav={this.props.headerNav}/>
                <section className="content">
                    <div className="container-fluid">
                        {this.props.children}

                    <div className="clearfix"> </div>
                </div>            
            </section>
          </div>
        </>
        );
    }
}

export default PageContainer;