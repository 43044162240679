import React from 'react';
import 'chart.js/auto';

import { Bar } from 'react-chartjs-2';

class BarChart extends React.Component {

  state={
    chartData:{
        labels:['Sticker', 'Other'],
        datasets: [
          {
            //label: 'Rainfall',
            backgroundColor: [
              // '#e95053',
              '#ed6f33',
              '#43d07f'
              // '#8760fb',
              
              // '#e1e6f1'
            ],
            // hoverBackgroundColor: [
            //  this.props.color,
            // '#e1e6f1'
            // ],
            data: [65, 59]
          }
        ]
      }
    
}
  

  render() {
    return (
      <div className="mt-3">

        <Bar
            width={10}
            height={120}

            options={{ 
              maintainAspectRatio: false,
              plugins:{
                legend:{
                  display:this.props.displayKey || false,
                  position:'right'
                } 
              }
                
            }}
            data={{
                labels: this.props.data.label,
                datasets: [
                  {
                    data: this.props.data.value,
        
                    backgroundColor: [
                      '#e95053',
                      '#ed6f33',
                      '#43d07f',
                      '#8760fb',
                      '#e1e6f1',
                      '#007bff',
                      '#cca300',
                      '#ff99cc'
                    ],
                  }

                  
                 
                ]
              }}
        /> 
      </div>
    );
  }
}

export default BarChart;
