import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import ConfigMenu from '../../Components/Config/ConfigMenu';
import PageContainer from '../../Components/Headings/PageContainer';
import { ConfigConsumer } from '../../Context/ConfigContext';
import functions from '../../util/functions';
import NewCurrentPeriod from '../../Components/Config/CurrentPeriod/NewCurrentPeriod';
import SetExam from '../../Components/Config/CurrentPeriod/SetExam';

class CurrentPeriodPage extends Component {


    state={
        currentRecord:false,
        transactionId:'',
        mdlCrud:false
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }

   

    newRecord=()=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <NewCurrentPeriod refresh={props.getCurrentPeriod}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-new-period"))
        })
    }

    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                    <li>
                        <Link to="#" className="btn ripple btn-success mb-1" onClick={this.newRecord}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;Change Period</Link>
                    </li>
                    <li>
                        <Link to="/configurations" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    

      editRecord=(record)=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <SetExam record={record} refresh={props.getCurrentExams}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-set-exam"))
        })
    }


    RenderTransactions=()=>{
        return(
            <ConfigConsumer>
                {props=>{
                    const list = props.currentExams;
                    return(
                        <div className="table-responsive table-ellipsis">
                        <table className="table expandable-table table-striped text-nowrap mb-0">
                            <thead>
                                <tr>
                                <th>Class</th>                                   
                                <th>Exam</th>
                                <th>Description</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(list) && list.map((item, key)=>
                                <tr 
                                    className={`${item.class.class_id===this.state.transactionId?'flag-row':'hot-tr'}`}
                                    style={{cursor:'default'}}
                                    onClick={()=>this.onSelectRecord(item.id, item)}
                                    key={key}>
                                    <td>{item.class.class_name}</td>
                                    <td>{item.exam?item.exam.exam.exam_name : "Not set"}</td>
                                    <td>{item.exam?item.exam.exam.description : "N/A"}</td>
                                    <td>
                                        <span className='action'>
                                            <button type="button" className="btn btn-primary btn-flat btn-pri btn-sm" onClick={()=>this.editRecord(item)}>
                                                <i className='fa fa-pen'/>&nbsp;{item.exam?"Change Exam": "Set Exam" }</button>
                                           
                                        </span>
                                    </td>
                                </tr>
                            )}
            
                        </tbody>
                        </table>
                        {!list && <ActivityLoader/>}
                    </div>
                    )
                }}
            </ConfigConsumer>
           
        )
    }


    render() {
        return (
            <ConfigConsumer>
                {props=>{
                    const period = props.currentPeriod?props.currentPeriod.term.term + " " + props.currentPeriod.acad_year : "...";
                    return(
                        <PageContainer 
                        title={"Academic Period: " + period}
                        history={{title:"Configurations", link:"/configuration"}}
                        headerNav={<this.RenderControls/>}
                        >
                                {this.state.mdlCrud}
                                <div className="row">
                                    <div className='col-md-9 col-sm-12'>
        
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">Manage Exam Marks Entry Periods</h3>
                                            </div>
                                            <div className="card-body">
        
                                            <this.RenderTransactions/>
        
                                            </div>
                                        </div>
                                
        
                                    </div>
        
                                    <ConfigMenu/>
                               
                                 
                                </div>
        
                             
                </PageContainer>
                    )

                }}
            </ConfigConsumer>
         
        );
    }
}

export default CurrentPeriodPage;