import React, { Component } from 'react';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class SetExam extends Component {

    state={
        classId:this.props.record.class.class_id,
        examId:'',
        info:false
    }


   


    onChangExam=(option)=>{
        this.setState({
            examId:option.value
        })
    }



    RenderTerm=()=>{

        return(<ConfigConsumer>

            {props=>{

                const optionsListing=[];

                if(Array.isArray(props.allTerms))
                {
                    props.allExams.map((item, key)=>
                    optionsListing.push({label:item.exam_name, value:item.id})
                    )
                 }

                return(
                    <TextInput
                        label="Exam"
                        leftIcon="fa fa-bookmark"
                        type="select" 
                        placeholder="Select exam...." 
                        options={optionsListing}
                        onChange={this.onChangExam}
                        leftComponent={<i className="fa fa-bookmark"/>}
                    />
                )

            }}

        </ConfigConsumer>)
       
        }

   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {examId, classId}=this.state;
        if(examId>0 && classId>0)
        {
            const server_response = await ajaxConfig.setCurrentExam(classId, examId);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>,
                    className:''
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {
        return (
            
    <SystemModal id="mdl-set-exam" title={"Set " + this.props.record.class.class_name +" Current  Exam"}>
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <this.RenderTerm/>
              
            <div className="row">&nbsp;</div>

            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default SetExam;