import React from "react";
import { Link } from "react-router-dom";
import InitialAvatar from "../Common/InitialAvatar";

const ProfileCard=(props)=>{


    return(
        <>
         <div className="card card-primary card-outline">
                            <div className="card-body box-profile">
                                <div className="text-center"> 
                                {!props.profile.image && <div className="profile-user-img img-fluid">
                                <InitialAvatar value={props.profile.initials}/>
                              </div>}

                                  {props.profile.image && <img className="profile-user-img img-fluid img-circle"
                                        src={props.profile.image}
                                        alt="Student"
                                    />}
                                </div>

                                <h3 className="profile-username text-center">{props.profile.full_name}</h3>

                                <p className="text-muted text-center">{props.profile.sex}</p>

                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Class</b> <span className="float-right">{props.profile.class.class_name}
                                            <span>{props.profile.stream?props.profile.stream.stream_name : ""}</span>
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Reg No.</b> <span className="float-right">{props.profile.reg_no}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Payment Code</b> <span className="float-right">{props.profile.other_reg}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Residence</b> <span className="float-right">{props.profile.residence.residence_name}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Birthday</b> <span className="float-right">{props.profile.DOB!==null?props.profile.DOB.short_date : "N/A"}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Admission</b> <span className="float-right">{props.profile.admission_date!==null?props.profile.admission_date.short_date : "N/A"}</span>
                                    </li>
                                </ul>

                                <span className="btn btn-primary btn-block"><b>{props.profile.status_name}</b></span>
                            </div>

                        </div>



                        <div className="card card-primary">
                            {/* <div className="card-header">
                                <h3 className="card-title">Other Details</h3>
                            </div> */}

                            <div className="card-body">
                                <strong><i className="fas fa-calendar-o mr-1"></i> Created On</strong>

                                <p className="text-muted">
                                    {props.profile.created_at.long_date}
                                </p>

                                <hr />

                              
                            
                               {props.profile.status*1===1 &&  <strong><Link to="#" onClick={props.methods.terminateBilling} className="text-danger"><i className="fas fa-handshake mr-1"></i> Deactivate Student</Link></strong>}
                               {props.profile.status*1===0 &&  <strong><Link to="#" onClick={props.methods.activateBilling} className="text-success"><i className="fas fa-handshake mr-1"></i> Activate Student</Link></strong>}


                                {/*<p className="text-muted">
                                    <span className="tag tag-danger">UI Design</span>
                                    <span className="tag tag-success">Coding</span>
                                    <span className="tag tag-info">Javascript</span>
                                    <span className="tag tag-warning">PHP</span>
                                    <span className="tag tag-primary">Node.js</span>
                                </p>

                                <hr />

                                <strong><i className="far fa-file-alt mr-1"></i> Notes</strong>

                                <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam fermentum enim neque.</p>
                            */}
                           </div>

                        </div>
        </>
    )

}


export default ProfileCard;