import React, { useEffect, useState } from 'react';
import AlertError from '../Components/Common/AlertError';
import ajaxMark from '../util/remote/ajaxMark';

const MarkEntryContext = React.createContext();

export const MarkEntryConsumer=MarkEntryContext.Consumer;

export const MarkEntryProvider=(props)=> {

    const [classId, setClassId] = useState(false);
    const [paperId, setPaperId] = useState(false);
    const [streamId, setStreamId] =useState(false);
    const [marksList, setMarksList] = useState(500);
    const [info, setInfo] = useState(false);
    const [streamList, setStreamList] = useState(500);

   
    useEffect(()=>{
        setStreamId(false)
    }, [classId])

    useEffect(()=>{
        if(classId>0 && paperId>0)
        {
            if(streamList==="404" || streamId>0)
            {
                getMarksEntryForm(true);
            }
           
        }
    }, [classId, paperId, streamId])
    
    const getMarksEntryForm=async(init)=>{
        if(init)
        {
            setMarksList(false);
        }
        setInfo(false);
        const data={
            class_id:classId,
            paper_id:paperId,
            stream_id:streamId?streamId:null
        }
        
        const server_response = await ajaxMark.getMarksEntrySheet(data);
        if(server_response.status==="OK")
        {   
            setInfo(false)
            setMarksList(server_response.details);
        }else{
            setMarksList("404");
            setInfo(<AlertError message={server_response.message}/>);
        }
    }

     
        return (
           <MarkEntryContext.Provider value={
                {
                  paperId,
                  classId,
                  streamId,
                  info,
                  marksList,
                  streamList,
                  setClassId,
                  setPaperId,
                  setStreamId,
                  getMarksEntryForm,
                  setStreamList
                }
               }>
               {props.children}
           </MarkEntryContext.Provider>
        );
    
}

export default MarkEntryContext;