import React, { useContext } from 'react';
import ConfigContext from '../../Context/ConfigContext';
const LetterHead =(props)=>{

        const { schoolProfile } = useContext(ConfigContext);

        return (
            <div>

                   {schoolProfile && <table 
                        className="Reports" 
                        border="0" 
                        width="100%" 
                        cellPadding="0" 
                        cellSpacing="0" 
                        style={{color:"#000", fontFamily:"'Arial Black', Gadget, sans-serif", fontSize:"13px", border:"0px"}}>
                           <tbody>
                            <tr style={{border:"0px"}}>
                            <td style={{color:"#900", fontSize:"13px", border:"0px"}} colSpan={3}>S/N: {props.serial}</td>
                            </tr>
                            <tr style={{border:"0px"}}>
                            <td width="5mm" style={{border:"0px"}}><img src={schoolProfile.badge} alt="BB" style={{width:"30mm", float:"left", marginTop:"0px"}}/></td>

                            <td style={{width:"170mm", border:"0px", align:"center"}}>
                            <span style={{float:"left", width:"100%", textAlign:"center"}}>
                                <span style={{float:"left", width:"100%", fontSize:"18px", color:"#000000"}}>{schoolProfile.school_name}</span>
                            </span>
                                
                            <span style={{float:"left", textAlign:"center", width:"100%"}}><b><i>{schoolProfile.motto}</i></b></span>
                            <span style={{float:"left", textAlign:"center", width:"100%"}}>{schoolProfile.address}</span>
                                
                            <span style={{float:"left", textAlign:"center", width:"100%"}}>Tel: + {schoolProfile.telephone}</span>
                                
                            <span style={{float:"left", width:"100%", textAlign:"center"}}>
                                Email:{schoolProfile.email_address} &nbsp; Web: {schoolProfile.website}
                            </span>
                        
                            <span style={{float:"left", width:"100%", textAlign:"center", fontWeight:"bold", fontSize:"14px", marginTop:"5%"}}>
                            <u>{props.title}</u>
                            </span>
                                    
                                {/* <span style={{float:"right", width:"30%", marginRight:"2%", marginTop:"1%"}}>
                                    <table border="0" cellpadding="0" cellSpacing="0" width="100%" style={{color:"#000", fontFamily:"'Arial Black', Gadget, sans-serif", fontSize:"13px"}}>
                                    <tr>
                                    <td colspan="2"><b>CONTACTS</b></td>
                                    </tr>
                                    
                                    <tr>
                                        <td>Headteacher: </td>
                                        <td>+256704722190</td>
                                    </tr>
                                    <tr>
                                        <td>D.Headteacher: </td>
                                        <td>+256782861283</td>
                                    </tr>
                                    
                                    </table>
                                </span> */}
                                    
                            
                            </td>


                            <td width="5mm" style={{border:"0px"}}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                            <img src={props.student.image.toString()} alt="" style={{width:"30mm", marginTop:"0px", backgroundColor:"#6E9ECF", color:"#FFFFFF", fontSize:"42px", fontWeight:"bold"}} className="InvertImage"/>
                            
                            </td>

                            </tr>
                            <tr style={{border:"0px"}}>
                            <td colSpan={3} style={{border:"0px"}}>
                                <hr style={{borderColor:"#000", float:"left", width:"100%", height:"5px", borderBottom:"solid", backgroundColor:"#000"}}/>
                            </td> 
                            </tr>
                            </tbody>
                            </table>}



          
            </div>
        );
    }


export default LetterHead;