import React, { useContext, useEffect, useState } from 'react';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import PageContainer from '../../Components/Headings/PageContainer';
import MarkEntryFilter from '../../Components/Marks/MarkEntryFilter';
import ReportInfo from '../../Components/Report/Elements/ReportInfo';
import ListReports from '../../Components/Report/Nav/ListReports';
import ReportOption from '../../Components/Report/Nav/ReportOptions';
import ReportFormatOne from '../../Components/Report/Template/ReportFormatOne';
import ReportFormaTwo from '../../Components/Report/Template/ReportFormaTwo';
import ReportContext from '../../Context/ReportContext';




const ReportCardPage =(props)=> {

    const {reportCards, currentReportId, currentReport, viewReport, setStudentId } = useContext(ReportContext);
    
    useEffect(()=>{
        setStudentId(false)
    }, [])

        const RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                    {/* <li>
                        <Link to="#" className="btn ripple btn-success mb-1">
                        <i className="fa fa-edit mr-1"></i>&nbsp;Create Custom List</Link>
                    </li>
                    <li>
                        <Link to="/configuration/classes" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-bookmark mr-1"></i>&nbsp;Alumni</Link>
                    </li> */}
                </ol>
               
    
                
            </div>
          
        )
        }


      

        return (
            <PageContainer 
                title="Student Reports"
                // history={{title:"Configurations", link:"/configuration"}}
                headerNav={<RenderControls/>}
                >
                        <div className="row">
                            <div className='col-md-9 col-sm-12'  style={{height:"550px", overflow:"auto"}}>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">View and print student reports</h3>
                                    </div>
                                    <div className="card-body">
                                        {!currentReportId && <AlertError type="info" message="No report card selected. Click on a report title from the report listing to view report."/>}

                                        {/* {currentReportId && reportCards && !studentListing && <AlertError message="Students records could not be found"/>} */}

                                        

                                        {currentReport && reportCards && <ReportInfo report={currentReport} viewReport={viewReport}/>}


                                        
                                        {!reportCards && <ActivityLoader/>}

                                    </div>
                                </div>
                        

                            </div>

                             {/** REPORT OPTIONS */}
                            <div 
                                className="col-xl-3 col-lg-12 d-none d-xl-block custom-leftnav"

                                // style={{height:"1000px", overflow:"auto"}}
                                >
                                <div className="main-content-left-components">



                                   <ReportOption/>
                                   <ListReports/>

                                   
                                </div>

                            </div>
                             {/** END REPORT OPTIONS */}
                       
                         
                        </div>

                     
        </PageContainer>
        );
}

export default ReportCardPage;