import React from 'react';
import { ConfigProvider } from './ConfigContext';
import { MarkEntryProvider } from './MarkEntryContext';
import { MarksProvider } from './MarksContext';
import { ReportProvider } from './ReportContext';
import { SearchProvider } from './SearchContext';
import { StaffProvider } from './StaffContext';
import { StudentProvider } from './StudentContext';
import { ThemeProvider } from './ThemeContext';

const SuperProvider=(props)=>{
  
        return (
        <ThemeProvider>
            <ConfigProvider>
                <StudentProvider>
                    <MarksProvider>
                        <MarkEntryProvider>
                            <ReportProvider>
                                <StaffProvider>
                                    <SearchProvider>
                                       {props.children}
                                    </SearchProvider>
                                </StaffProvider>
                            </ReportProvider>
                        </MarkEntryProvider>
                    </MarksProvider>
                   
                </StudentProvider>
            </ConfigProvider>
          </ThemeProvider>                         
        )
}

export default SuperProvider;