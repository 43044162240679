import React from 'react';


const InitialAvatar = props => {
    
      return (
        <div className={`avatar avatar-${props.type || 'xxl'} d-none d-sm-flex bg-secondary`}>
            {props.value.substring(0, 2)}
		</div>
      );
    
 
  
}

export default InitialAvatar;