import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import functions from "../../../util/functions";
import ajaxConfig from "../../../util/remote/ajaxConfig";
import ActivityLoader from "../../Common/ActivityLoader";
import AlertError from "../../Common/AlertError";
import ListItem from "../../Common/List/ListItem";
import WarnDelete from "../../Common/WarnDelete";
import EditSubjectUnit from "./EditSubjectUnit";
import NewPaper from "./NewPaper";
import NewSubjectUnit from "./NewSubjectUnit";

const SubjectUnit=(props)=>{

    const [unitListing, setUnitListing] = useState(false);
    const [mdlCrud, buildMdlCrud] = useState(false);


    useEffect(()=>{
        if(props.subjectId)
        {
            getUnitListing();
        }

    }, [props.subjectId])


    useEffect(()=>{
        if(mdlCrud)
        {
            functions.openPopup("mdl-new-sunit")
        }
    }, [mdlCrud])




    const getUnitListing=async()=>{

        const server_response = await ajaxConfig.getSubjectUnits(props.subjectId);
        if(server_response.status==="OK")
        {
          setUnitListing(server_response.details)
  
        }else{
  
            setUnitListing("404")
        }
      }


      const newRecord=()=>{
       
        if(mdlCrud){
            buildMdlCrud(false);
            setTimeout(() => {
                buildMdlCrud(<NewSubjectUnit subjectId={props.subjectId} refresh={getUnitListing}/>)
            }, 10);
        }else{
            buildMdlCrud(<NewSubjectUnit subjectId={props.subjectId} refresh={getUnitListing}/>)

        }
    
    }


    const deleteUnit=async(record)=>{
        const server_response = await ajaxConfig.deleteSubjectUnit(record.unit_id);
        if(server_response.status==="OK")
        {
            getUnitListing();
            functions.closePopup("mdl-new-sunit")
        }else{
  
            alert(server_response.details.message)
        }
    }

    const editRecord=(record)=>{

        buildMdlCrud(false);
        setTimeout(() => {
            buildMdlCrud(<EditSubjectUnit record={record} refresh={getUnitListing}/>)
        }, 10);
   }

   const deleteRecord=(record)=>{

    buildMdlCrud(false);
    setTimeout(() => {
        buildMdlCrud(<WarnDelete 
                        id="mdl-new-sunit"
                        title="Delete Subject Unit"
                        description={"Are you sure you want to delete subject unit " + record.title + " ?" } 
                        onConfirm={()=>deleteUnit(record)}
                        />)
    }, 10);
}





    return(
        <div className="car card-default">
            { mdlCrud }
                <div className="card-header">
                    <h3 className="card-title">
                    <i className="fas fa-file txt-primary"></i> &nbsp;
                    <b>Subject Study Units</b>
                    </h3>
                    <Link to="#" className="btn btn-sm ripple btn-success mb-1" style={{float:'right'}} onClick={newRecord}>
                        <i className="fa fa-plus mr-1"></i>&nbsp;Add Subject Unit</Link>
                </div>
                <div className="card-body"></div>
                    <ul className="todo-list" data-widget="todo-list">
                
                                    {Array.isArray(unitListing) && unitListing.map((item, key)=>
                                       <div className="callout callout-success" key={key}>
                                            <h5>{ item.title }</h5>
                                            <p>{ item.description }</p>

                                            <div className="tools">
                                            <i className="fas fa-edit txt-primary link" onClick={()=>editRecord(item)}></i> &nbsp;
                                            <i className="fa fa-trash-alt txt-danger link" onClick={()=>deleteRecord(item)}></i>
                                            </div>
                                       </div>

                                    )}
                    
                            </ul>
                    {unitListing==="404" && <AlertError type="info" message="No subject unit found"/>}
                    {!unitListing && <ActivityLoader/>}

        </div>
    )
}

export default SubjectUnit