import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import dictionary from '../../util/dictionary';
import ajaxAuth from '../../util/remote/ajaxAuth';

class ResetPassword extends Component {

    state={
        password:'',
        rePassword:'',
        error:false,
        info:"",
        loading:false
    
    }

    onPressConfirm=async (event)=>{
        event.preventDefault();
        const{rePassword, password}=this.state;
        if(rePassword.length>0 && password.length>0)
        {

          if(rePassword!==password)
          {
            this.setState({
              info:<AlertError message={"Passwords do not match"}/>
            })

            return false;
          }

            this.setState({
              info:<ActivityLoader/>
            })

          const server_response=await ajaxAuth.resetPassword(password, rePassword);
          if(server_response.status==="OK")
          {
            window.localStorage.removeItem('eschool@user')
            window.location.replace('/');
          }else{
                this.setState({
                  info:<AlertError message={server_response.message}/>,
                  loading:false
                })
          }

        }else{
          this.setState({
            info:<AlertError message={dictionary._completeFields}/>,
            loading:''
          })
        }
       
       
      }

      onChangePassword = (event) => {
        this.setState({password: event.target.value});
      }

      onChangeConfirmPassword = (event) => {
        this.setState({rePassword: event.target.value});
      }
  

    render() {
        return (
        <div className="hold-transition login-page">

<div className="login-box">
  <div className="login-logo">
    <Link to="#">Eschool</Link>
  </div>
  <div className="card">
    <div className="card-body login-card-body">
      
      <p className="login-box-msg">Setup new account password</p>
      {this.state.info}
      <form method="post" onSubmit={this.onPressConfirm}>
        <div className="input-group mb-3">
          <input 
            type="password" 
            className="form-control" 
            placeholder="New Password"
            onChange={this.onChangePassword}
            value={this.state.password}/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input 
            type="password" 
            className="form-control" 
            placeholder="Confirm Password" 
            onChange={this.onChangeConfirmPassword}
            value={this.state.rePassword}
            />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
           
          </div>
          <div className="col-8">
            <button type="submit" className="btn btn-primary btn-block">Confirm Password</button>
          </div>
        </div>
      </form>

      

    </div>
  </div>
</div>




          
      </div>
        );
    }
}

export default ResetPassword;