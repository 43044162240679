import React, { useEffect, useState } from 'react';


const ScrollFilterBar=(props)=>{

	const [refresh, setRefresh] = useState(false);
	const [currentScreen, setCurrentScreen] = useState(1);
	// const [customParams, setCustomParams] = useState([]);
	const [currentParams, setCurrentParams] = useState([]);
	const [resetFlag, setResetFlag] = useState(0);
	

	useEffect(()=>{
		const initialValues = [];
		//Extract all the custom input names and initialize them
		if(props.secondScreen){
			if(Array.isArray(props.secondScreen.names)){

				for(var i=0; i<props.secondScreen.names.length; i++){
					initialValues.push({[props.secondScreen.names[i]]:""})
				}

				// this.setState({
				// 	customParams: initialValues
				// })
			}
		}

		if(props.firstScreen){
			if(Array.isArray(props.firstScreen.names)){

				for(var k=0; k<props.firstScreen.names.length; k++){
					initialValues.push({[props.firstScreen.names[k]]:""})
				}

				// this.setState({
				// 	customParams: initialValues
				// })
			}
		}
		setCurrentParams(initialValues)
	}, [resetFlag])



	useEffect(()=>{
		if(refresh){
			props.onReset()
			setRefresh(false)
			setResetFlag(resetFlag+1)
		}
	}, [refresh])



	const onApply = (func) => {

		const params={};
		for(var i=0; i<currentParams.length; i++){
			Object.assign(params, currentParams[i])
		}
	
		props.onConfirm(params, func)
	}



	const mapCustoms=(name, value)=>{
		for(var i = 0; i<currentParams.length; i++){
			
			if(currentParams[i][name]!==undefined){
				currentParams[i][name] = value

				return currentParams
			}
		}
		setCurrentParams(currentParams)
		return currentParams;
	}


	const setCustomParams=(name, value)=>{
		
		mapCustoms(name, value)

	}
	

	const onClickReset = () => {

		setRefresh(true);
		setCurrentScreen(1);
	}


	const RenderScreenOne=()=>{


		return(
			<div className="row align-items-center mt-3">
				{props.firstScreen && Array.isArray(props.firstScreen.inputs) && props.firstScreen.inputs.map((item, key)=>
					item(setCustomParams, key, currentParams)
				)}
			</div>
		)
	}



	const RenderScreenTwo=()=>{
		

		return(<div className="row align-items-center mt-3">
			

				{props.secondScreen && Array.isArray(props.secondScreen.inputs) && props.secondScreen.inputs.map((item, key)=>
					item(setCustomParams, key, currentParams)
				)}


			</div>)
	}



	const RenderNav=()=>{
		if(!props.secondScreen){
			return <></>
		}
		if(currentScreen===1){
			return(<span className='badge badge-pill badge-light link mr-3' onClick={()=>setCurrentScreen(2)}>
						<i className="fas fa-angle-double-right" style={{fontSize:'16px'}}></i>
					</span>)
		}else{

			return(<span className='badge badge-pill badge-light link mr-3' onClick={()=>setCurrentScreen(1)}>
						<i className="fas fa-angle-double-left" style={{fontSize:'16px'}}></i>
					</span>)

			}
	}



	

		if (refresh) {
			return <></>
		} else {
			return (
				<div className="responsive-background">
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<div className="advanced-search">
							<span className='float-right'>
								{ RenderNav() }
							</span>
						

								{currentScreen ===1 && RenderScreenOne()}
								{currentScreen ===2 && RenderScreenTwo()}

							
							<hr />
							<div className="text-right">
								 <button onClick={onApply} className="btn btn-primary" data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">Apply</button>
								
								<button onClick={onClickReset} className="btn btn-light" data-toggle="collapse" data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Reset</button>
							</div>
						</div>
					</div>
				</div>
			)
		}


	
}

export default ScrollFilterBar;
