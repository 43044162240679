import { Link } from "react-router-dom";

const ButtonGroup = (props)=>{

    return  <div className="btn-group">
    <button type="button" className={`btn btn-${props.type || 'default'}`}>{props.title || "Options"}</button>
    <button type="button" className="btn btn-default dropdown-toggle dropdown-icon" data-toggle="dropdown">
      <span className="sr-only">Options</span>
    </button>
    <div className="dropdown-menu" role="menu">
      {props.options && props.options.map((item, key)=>
        <Link className="dropdown-item" to={item.link} onClick={item.clickEvent} key={key}>{item.title}</Link>
      )}
       {/* <div className="dropdown-divider"></div> */}
        {/* <a className="dropdown-item" href="#">Separated link</a> */}
        {props.children}
    </div>
  </div>
}

export default ButtonGroup;