import React, { useState } from "react"

const CheckList = (props)=>{

    const list = [];

    const selectValue=(event)=>{
        if(list.includes(event.target.value))
        {
            
        }else{
            list.push(event.target.value)
        }
    }


    return(
        <div className="card">
            <div className="card-header">
            <h3 className="card-title">{props.title}</h3>

            <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
                </button>
            </div>
            </div>
            <div className="card-body p-0">
            <ul className="nav nav-pills flex-column">
                {Array.isArray(props.list) && props.list.map((item, key)=>
                 <li className="nav-item" key={key}>
                    <div className="icheck-primary ml-4">
                        <input type="checkbox" value={item.id} id={props.id + "-" + item.id} onChange={selectValue}/>
                        <label htmlFor={props.id + "-" + item.id} className="checklist-link">{item.label}</label>
                    </div>
                </li>
                )}
               
               
            </ul>
            </div>
                                            
        </div>)
}

export default CheckList