import React from "react"
const MarkSheetFormatTwo=(props)=>{

    const marksheet = props.marksheet


    const SubjectHead=(prop)=>{
        return <>{prop.item.done_papers && prop.item.done_papers.map((item, key)=>
                <td key={key}>&nbsp;P{item.paper_code.split("/")[1] }&nbsp;</td>
                )
                }
                <td>GD</td>
        </>
    }

    const MarkHead=(prop)=>{
       
        const total_mark=prop.studentMarks?prop.studentMarks.total_mark : 0;
        const grade = prop.studentMarks?prop.studentMarks.advanced_grade_label : "-";
            return <>
                {prop.donePapers.map((paper, paper_key)=>
                    // var mark =  
                    <td key={paper_key} style={{textAlign:"center"}}>{ total_mark? total_mark[paper.paper_id]: ""}</td>
                )}

                <td style={grade && {backgroundColor:"#000000", color:"#FFFFFF", fontWeight:"bold", textAlign:"center"}}>{grade?grade[prop.subjectId]:""}</td>
                
            </>
    


    }


    return <>
        { marksheet.marks_collection && Array.isArray(marksheet.marks_collection.student_info) && 
                                        
            <table width="100%" border="1" className="Reports" style={{fontSize:"12px"}} cellSpacing="0" cellPadding="0">
                <caption>{marksheet.report_info.title}</caption>
            <thead>
            <tr>
                <th>NO</th>
                <th>NAMES</th>
                {Array.isArray(marksheet.done_subjects) && marksheet.done_subjects.map((metaSubject, metaSubjectKey)=>
                    <th key={metaSubjectKey} colSpan={metaSubject.total_papers+1} style={{textAlign:"center"}}>
                        {metaSubject.subject_info.abbreviation}
                    </th>
                )}
                <th style={{textAlign:"center"}}>PTS</th>

            </tr>
            </thead>
                <tbody>
                    <tr style={{backgroundColor:"#000000", color:"#FFFFFF", fontWeight:"bold", textAlign:"center"}}>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        {Array.isArray(marksheet.done_subjects) && marksheet.done_subjects.map((metaSubject, metaSubjectKey)=>
                            <SubjectHead key={metaSubjectKey} item={metaSubject}/>
                        )}
                        
                        <td>&nbsp;</td>
                    </tr>
                    {marksheet.marks_collection.student_info.map((studentItem, studentKey)=> <tr key={studentKey}>
                        <td>{studentKey+1}</td>
                        <td>{studentItem.full_name}</td>

                        {Array.isArray(marksheet.done_subjects) && marksheet.done_subjects.map((metaPaper, metaPaperKey)=>
                            <MarkHead 
                                key={metaPaperKey} 
                                studentMarks={ marksheet.marks_collection[studentItem.id][metaPaper.subject_info.subject_id]}
                                donePapers={metaPaper.done_papers}
                                subjectId={metaPaper.subject_info.subject_id}
                                />
                           
                        )}
                        <td style={{textAlign:"center", fontWeight:"bold"}}>{marksheet.marks_collection[studentItem.id].advanced_points}</td>
                        
                    </tr>)}

                </tbody>
            </table>
            }</>
           

}

export default MarkSheetFormatTwo;