import React from "react";
import InitialAvatar from "../Common/InitialAvatar";
import ItemNotFound from "../Common/ItemNotFound";

const ProfileContacts = props =>{

    return <div class="row d-fle align-items-center">
       {props.contacts && props.contacts.map((item, key)=> <div class="col-12 d-fle align-items-center" key={key}>
          <div class="card bg-light">
            <div class="card-header text-muted border-bottom-0">
              {item.contact_type.type_name}
            </div>
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-12">
                  <h2 class="lead"><b>{item.contact} </b></h2>
                  {item.default_contact && <p class="text-muted text-sm"> Default Contact </p>}
                  <ul class="ml-4 mb-0 fa-ul text-muted">
                    <li class="small"><span class="fa-li"><i class="fas fa-lg fa-user"></i></span> Contact Name: {item.contact_name}</li>
                    <li class="small"><span class="fa-li"><i class="fas fa-lg fa-building"></i></span> Address: N/A</li>
                  </ul>
                </div>
                {/* <div class="col-5 text-center">
                    <InitialAvatar value="C" type="xl"/>
                </div> */}
              </div>
            </div>
            <div class="card-footer">
              {/* <div class="text-right">
                <a href="#" class="btn btn-sm bg-teal">
                  <i class="fas fa-comments"></i>
                </a>
                <a href="#" class="btn btn-sm btn-primary">
                  <i class="fas fa-user"></i> View Profile
                </a>
              </div> */}
            </div>
          </div>
        </div>)}


        {!props.contacts && <ItemNotFound description="No contact information found"/>}

        </div>
      

}

export default ProfileContacts;