import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import LeftSideBar from '../../Components/Common/LeftSideBar';
import TopBar from '../../Components/Common/TopBar';
import EditClassLevel from '../../Components/Config/ClassLevel/EditClassLevel';
import NewClassLevel from '../../Components/Config/ClassLevel/NewClassLevel';
import ConfigMenu from '../../Components/Config/ConfigMenu';
import ContentHeader from '../../Components/Headings/ContentHeader';
import PageContainer from '../../Components/Headings/PageContainer';
import { ConfigConsumer } from '../../Context/ConfigContext';
import functions from '../../util/functions';

class ClassLevelPage extends Component {

    state={
        currentRecord:false,
        transactionId:'',
        mdlCrud:false
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }

    newLevel=()=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <NewClassLevel refresh={props.getClassLevels}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-new-level"))
        })
    }

    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                    {this.state.currentRecord && <li>
                        <Link to="#" className="btn ripple btn-warning mb-1" onClick={this.editLevel}>
                            <i className="fa fa-pen mr-1"></i>&nbsp;Edit Level</Link>
                    </li>}
                    <li>
                        <Link to="#" className="btn ripple btn-success mb-1" onClick={this.newLevel}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;Create New</Link>
                    </li>
                    <li>
                        <Link to="/configurations" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    

      editLevel=()=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <EditClassLevel record={this.state.currentRecord} refresh={props.getClassLevels}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-edit-level"))
        })
    }


    RenderTransactions=()=>{
        return(
            <ConfigConsumer>
                {props=>{
                    const list = props.classLevels;
                    return(
                        <div className="table-responsive table-ellipsis">
                        <table className="table expandable-table table-striped text-nowrap mb-0">
                            <thead>
                                <tr>
                                <th>No.</th>                                   
                                <th>Level Name</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(list) && list.map((item, key)=>
                                <tr 
                                    className={`${item.level_id===this.state.transactionId?'flag-row':'hot-tr'}`}
                                    style={{cursor:'default'}}
                                    onClick={()=>this.onSelectRecord(item.level_id, item)}
                                    key={key}>
                                    <td>{key+1}</td>
                                    <td>{item.level}</td>
                                </tr>
                            )}
            
                        </tbody>
                        </table>
                        {!list && <ActivityLoader/>}
                    </div>
                    )
                }}
            </ConfigConsumer>
           
        )
    }


    render() {
        return (
            <PageContainer 
                title="Class Levels"
                history={{title:"Configurations", link:"/configuration"}}
                headerNav={<this.RenderControls/>}
                >
                        {this.state.mdlCrud}
                        <div className="row">
                            <div className='col-md-9 col-sm-12'>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">List of class levels</h3>
                                    </div>
                                    <div className="card-body">

                                    <this.RenderTransactions/>

                                    </div>
                                </div>
                        

                            </div>

                            <ConfigMenu/>
                       
                         
                        </div>

                     
        </PageContainer>
        );
    }
}

export default ClassLevelPage;