import React, { useState } from 'react';
import CommentWriter from './CommentWriter';
const ReportRemarks = (props)=> {
        const setFontSize="13px";

        const [activeField, setActiveField] = useState(false)

        const toggleField=(field)=>{
            if(activeField)
            {

                if(activeField.field.id===field.field.id)
                {
                    setActiveField(false);
                    return true;
                }
            }
            setActiveField(field)

        }

        const RenderField=(fieldProp)=>{
            if(fieldProp===undefined){
                return <></>
            }

            if(fieldProp.field.comment.scope.id*1===1)
            {
                return <span style={{cursor:"pointer"}} onClick={()=>toggleField(fieldProp)}>
                    <b>{fieldProp.field.comment.title}</b>
                </span>
            }

            return <b>{fieldProp.field.comment.title}</b>
            

        }


        const RenderValue=(item)=>{
            if(item===undefined){
                return <></>
            }
            if(activeField)
            {
                if(activeField.student_id===item.student_id && activeField.field.id===item.field.id){
                   return <CommentWriter field={item} onConfirm={handleSaved}/>
                }

            }

            return <span>
                    {item.field.comment.scope.id*1===1?(item.value?item.value.value:"") : item.field.value}
                    <span> <b>{item.field.staff?item.field.staff.initials:""}</b></span>
                </span>
        }


        const handleSaved=()=>{
            setActiveField(false);
            //fetch reports
        }


        return (
        <>
          <table className="Reports" border-collapse="collapse" border="1" cellSpacing="0" width="100%" style={{border:"solid", fontSize:{setFontSize}, borderWidth:"0.1em"}}>
               <tbody>
               <tr>
                    <td colSpan="2" align="center" style={{textAlign:"center"}}><b><span style={{fontSize:setFontSize}}>COMMENTS</span></b><br /></td>
                </tr>

               {Array.isArray(props.remarks.NORMAL) && props.remarks.NORMAL.map((item, key)=><tr key={key}>
                    <td style={{fontSize:setFontSize}}>
                        {RenderField(item)}
                    </td>
                    <td style={{cursor:"text", fontSize:{setFontSize}, minWidth:"60%"}}>
                        {RenderValue(item)}
                    </td>
                 </tr>)}

                 {props.remarks.OTHER &&<tr>
                    <td style={{fontSize:setFontSize, borderRight:'none', textAlign:'center'}}>
                        {RenderField(props.remarks.OTHER['FEES-BALANCE'])}
                        <span style={{marginLeft:'10px', color:'#900'}}><b>{RenderValue(props.remarks.OTHER['FEES-BALANCE'])}</b></span>
                       
                        
                    </td>
                    <td style={{fontSize:setFontSize, borderLeft:'none', textAlign:'center'}}>
                        {RenderField(props.remarks.OTHER['FEES-AMOUNT'])}
                        <span style={{marginLeft:'10px'}}>{RenderValue(props.remarks.OTHER['FEES-AMOUNT'])}</span>
                    </td>
                 </tr>}

                {/* {props.remarks.PROMOTION && <tr>
                    <td colSpan={2} style={{textAlign:'center', fontWeight: 'bold', fontSize:setFontSize, color:'#900'}}>{props.remarks.PROMOTION}</td>
                 </tr>} */}

               </tbody>
              
            </table>

       </>
        );
    }


export default ReportRemarks;