import React, { Component, useContext } from 'react';
import CustomCard from '../../Components/Common/Cards/CustomCard';
import InfoBox from '../../Components/Common/InfoBox';
import LeftSideBar from '../../Components/Common/LeftSideBar';
import TitleValueCard from '../../Components/Common/TitleValueCard';
import TopBar from '../../Components/Common/TopBar';
import ConfigMenu from '../../Components/Config/ConfigMenu';
import ContentHeader from '../../Components/Headings/ContentHeader';
import ConfigContext from '../../Context/ConfigContext';

const ConfigPage =(props)=> {

    const {
        totalUsers, 
        totalClasses, 
        totalClassLevels, 
        totalTerms, 
        totalTests,
        totalSubjects,
        currentPeriod,
        currentTerm
    } = useContext(ConfigContext)

        return (
            <>
            <TopBar/>
            <LeftSideBar/>
            <div className="content-wrapper">
                <ContentHeader title="Configurations"/>
                <section className="content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className='col-md-9 col-sm-12'>
                                    <div className='row'>

                                    <CustomCard
                                        href="/configuration/users" 
                                        title="System Users" 
                                        value={totalUsers} 
                                        icon="fas fa-user"
                                        tooltip="Setup system users" 
                                        color="icon-service rounded-circle bg-secondary-transparent text-danger" />

                                    <CustomCard
                                        href="/configuration/class_levels" 
                                        title="Class levels" 
                                        value={totalClassLevels} 
                                        icon="fas fa-bookmark"
                                        tooltip="Eg. Ordinary, Advanced" 
                                        color="icon-service rounded-circle bg-success-transparent text-success" />


                                    <CustomCard
                                        href="/configuration/classes" 
                                        title="Classes & Streams" 
                                        value={totalClasses} 
                                        icon="fas fa-bookmark"
                                        tooltip="Eg. S.1A, S.2 East" 
                                        color="icon-service rounded-circle bg-primary-transparent text-primary" />


                           
                              <CustomCard
                                        href="/configuration/term" 
                                        title="Academic Terms" 
                                        value={totalTerms} 
                                        icon="fas fa-calendar"
                                        tooltip="Eg. Term 1, Semester I" 
                                        color="icon-service rounded-circle bg-primary-transparent text-primary" />




                                <CustomCard
                                        href="/configuration/exam" 
                                        title="Assessments" 
                                        value={totalTests} 
                                        icon="fas fa-edit"
                                        tooltip="E.g MOT, EOT, BOT, SET 1...." 
                                        color="icon-service rounded-circle bg-danger-transparent text-danger" />


                               <CustomCard
                                        href="/configuration/current_period" 
                                        title="Current Period" 
                                        value={ "Term " + currentTerm} 
                                        icon="fas fa-clock"
                                        tooltip="Set active assessment" 
                                        color="icon-service rounded-circle bg-info-transparent text-info" />

                                <CustomCard
                                        href="/configuration/subjects" 
                                        title="Subjects" 
                                        value={totalSubjects} 
                                        icon="fas fa-book"
                                        tooltip="Eg. Mathematics, English, etc" 
                                        color="icon-service rounded-circle bg-success-transparent text-success" />

                              
                           
                         
                
                                    </div>

                            </div>

                            <ConfigMenu/>
                       
                         
                        </div>

                      

                           

                    </div>
            </section>
        </div>

         </>
        );
    
}

export default ConfigPage;