import React, { Component, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import CallOut from '../../Components/Common/CallOut';
import InitialAvatar from '../../Components/Common/InitialAvatar';
import WarnDelete from '../../Components/Common/WarnDelete';
import PageContainer from '../../Components/Headings/PageContainer';
import SearchContext from '../../Context/SearchContext';
import functions from '../../util/functions';
import ajaxSystem from '../../util/remote/ajaxSystem';

const SystemFinderPage =(props)=>{

  const {find_string} = useParams();
  const navigate = useNavigate();

    // const [transactions, setTransactions] = useState(false);
    const [info, setInfo] = useState("");
    const [activeTab, setActiveTab] = useState("main");
    const [currentRecord, setCurrentRecord] = useState(false);
    const [tabEdit, setTabEdit] = useState(false);

    const {searchResults, query} = useContext(SearchContext);

    const transactions = searchResults

    // const [query, setQuery] = useState("");

  //   useEffect(()=>{
  //     if(!find_string)
  //     {
  //         navigate("/")
  //     }else{
  //         setQuery(find_string)
  //     }
  // }, [])

  // useEffect(()=>{
  //   listTransactions();
  // }, [query])


  //   const listTransactions=async()=>{
  //       const server_response = await ajaxSystem.getSearch({hint:query});
  //       if(server_response.status==="OK")
  //       {
  //         setTransactions(server_response.details);
           
  //       }else{

  //         setInfo(<AlertError type="info" message={server_response.message}/>)
          
  //       }
  //   }




   const RenderStudentResults=()=>{

      return <div className="active tab-pane" id="students">
                <div className="row">

                      {transactions.students && transactions.students.map((item, key)=>

                        <div className="col-sm-6 col-md-6 col-xl-3" key={key}>
                          <div className="card custom-card">
                            <div className="card-body user-card text-center">
                            {item.image &&  <div className="main-img-user avatar-xxl online text-center">
                                  <img alt="avatar" className="rounded-circle" src={item.image}/>
                              </div>}

                              {!item.image && <div className="main-img-user avatar-xxl online text-center">
                                <InitialAvatar value={item.initials}/>
                              </div>}

                             
                              <div className="mt-2">
                                <h5 className="mb-1">{item.full_name}</h5>
                                <p className="mb-1 tx-inverse">{item.reg_no} - {item.class.class_name}</p>
                                <span className="text-muted"><i className={`far fa-check-circle ${item.status*1===1?"text-success":"text-danger"} mr-1`}></i>{item.status_name}</span><br/>
                                
                                <span className="text-muted"><i className="fa fa-map-pin text-danger mr-1"></i>{item.residence.residence_name || <i>Not assigned</i>}</span>

                              </div>
                              <Link 
                                  to={`/student/update/${item.initials}`} 
                                  state={{record:item}}
                                  className="btn ripple btn-primary mt-3">
                                    <i className='fa fa-pencil-alt'/>&nbsp;
                                    Edit</Link>&nbsp;

                                
                                <Link to={`/students/profile/${item.id}`}
                                      state={{studentId:item.id}}
                                  className="btn ripple btn-success mt-3">
                                     <i className='fa fa-user'/>&nbsp;Profile</Link>
                            </div>
                          </div>
                        </div> 
          )}
         </div>

          {!transactions && <ActivityLoader/>}
          {transactions && !transactions.students &&  <CallOut title="info" description="No student results found"/>}
      </div>
    }



    const RenderStaffResults=()=>{
      return <div className="tab-pane active" id="staff">
           
              {!transactions && <ActivityLoader/>}
              {transactions &&  <CallOut title="info" description="No staff results found"/>}
      </div>
    }


    const RenderUserResults=()=>{
      return <div className="tab-pane active" id="user">

        {!transactions && <ActivityLoader/>}
        {transactions  && <CallOut title="info" description="No user results found"/>}
      </div>
    }
   




    const onClickTab=(activeTab)=>{
        setActiveTab(activeTab)
    }

   
        return (
          <>
          <PageContainer title="Finder">
          <div className="row">
              <div className="col-md-12">
                <AlertError type="light" message={<span><b><i className="fa fa-search txt-primary"/> Search results for: </b><i>{query}</i></span>}/>
              

              <div className="card">
                            <div className="card-header p-2">
                                <ul className="nav nav-pills">
                                {!tabEdit && <>
                                <li className="nav-item"><a className={`nav-link ${activeTab==="main"?"active":""}`} href="#" onClick={()=>onClickTab("main")}>Student results</a></li>
                                <li className="nav-item"><a className={`nav-link ${activeTab==="staff"?"active":""}`} href="#" onClick={()=>onClickTab("staff")}>Staff results</a></li>
                                <li className="nav-item"><a className={`nav-link ${activeTab==="user"?"active":""}`} href="#" onClick={()=>onClickTab("user")}>User results</a></li>
                                </>}

                                {tabEdit && <>
                                <li className="nav-item"><a className="nav-link" href="#main" data-toggle="tab">Search Results</a></li>
                                <li className="nav-item"><a className="nav-link active" href="#edit" data-toggle="tab">Edit Record</a></li>

                                </>}

                                </ul>
                            </div>
                            {/* <!-- /.card-header --> */}
                            <div className="card-body">
                                <div className="tab-content">
                                {!tabEdit && <>
                                {activeTab==="main" && RenderStudentResults()}
                                {activeTab==="staff" && RenderStaffResults()}
                                {activeTab==="user" && RenderUserResults()}
                                </>}
                                {tabEdit}
                                {/* <!-- /.tab-pane --> */}
                                </div>
                                {/* <!-- /.tab-content --> */}
                            </div>
                                    {/* <!-- /.card-body --> */}
                            </div>
         

						
          </div>
          </div>
          </PageContainer>
          </>
        );
    }


export default SystemFinderPage;