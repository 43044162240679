import React, { Component, useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import ItemNotFound from '../../Components/Common/ItemNotFound';
import WarnDelete from '../../Components/Common/WarnDelete';
import PageContainer from '../../Components/Headings/PageContainer';
import ReportFormatSelector from '../../Components/Report/ReportFormatSelector';
import ProfileCard from '../../Components/Student/ProfileCard';
import ProfileContacts from '../../Components/Student/ProfileContacts';
import StudentReports from '../../Components/Student/StudentReports';
import ReportContext from '../../Context/ReportContext';

import functions from '../../util/functions';
import ajaxStudent from '../../util/remote/ajaxStudent';

const StudentProfile =(props)=> {

    const params = useLocation();
    const navigate = useNavigate();

    const [studentId, setStudentId] = useState(params.state?params.state.studentId:false);
    const [studentInfo, setStudentInfo] = useState(false);
    const [modal, setModal] = useState(false)
    const [info, setInfo] = useState(false)
    const { currentReportId } = useContext(ReportContext);

    useEffect(()=>{
        if(!params.state)
        {
            navigate("/")
        }
    }, [])

   
    useEffect(()=>{
        if(studentId){
            getStudentInfo()
        }
    }, [studentId])



  const getStudentInfo =async()=>{
    setStudentInfo(false)
    const server_response = await ajaxStudent.getStudentInfo(studentId);
    if(server_response.status==="OK")
    {
        setStudentInfo(server_response.details)
    }else{
        navigate("/")
    }
}


const terminateBilling=async()=>{

    // const server_response = await ajaxSchool.deactivateBilling(this.state.studentId)
    // functions.closePopup("mdl_terminate_bill")
    // if(server_response.status==="OK")
    // {
    //     this.setState({
    //         info:<AlertSuccess message={server_response.message}/>
    //     })
    //     this.getStudentInfo();
    // }else{
    //     this.setState({
    //         info:<AlertError message={server_response.message}/>
    //     })
    // }
}


const activateBilling=async()=>{

    // const server_response = await ajaxSchool.activateBilling(this.state.studentId)
    // functions.closePopup("mdl_activate_bill")

    // if(server_response.status==="OK")
    // {
    //     this.setState({
    //         info:<AlertSuccess message={server_response.message}/>
    //     })
    //     this.getStudentInfo();
    // }else{
    //     this.setState({
    //         info:<AlertError message={server_response.message}/>
    //     })
    // }
}



const handleTerminateBilling=()=>{
    // this.setState({
    //     modal:false
    // }, ()=>{

    //     this.setState({
    //         modal: <WarnDelete 
    //         title="Deactivate Billing" 
    //         description={"Confirm that you want to deactivate this student. The student will nolonger be invoiced."}
    //         id="mdl_terminate_bill"
    //         buttonType="danger"
    //         onConfirm={this.terminateBilling}
    //         buttonLabel="Deactivate"
    //         />
    //     }, ()=>{functions.openPopup("mdl_terminate_bill")})

    // })
   
}

const handleActivateBilling=()=>{
    // this.setState({
    //     modal:false
    // }, ()=>{

    //     this.setState({
    //         modal: <WarnDelete 
    //         title="Activate Billing" 
    //         description={"Confirm that you want to activate this student. The student will now be active for invoicing."}
    //         id="mdl_activate_bill"
    //         buttonType="success"
    //         onConfirm={this.activateBilling}
    //         buttonLabel="Activate"
    //         />
    //     }, ()=>{functions.openPopup("mdl_activate_bill")})

    // })
   
}





const RenderControls=()=>{
    return (
        <div className='float-sm-right'>
            <ol className='button-listing'>
               {studentInfo.status*1===1 && <></>}
                <li>
                    <Link to="#" className="btn ripple btn-secondary mb-1" onClick={getStudentInfo}>
                        <i className="fa fa-redo mr-1"></i>&nbsp;Refresh</Link>
                </li>
            </ol>
           

            
        </div>
      
    )
  }




        return (

            <PageContainer 
                title='Student Profile'
                headerNav={<RenderControls/>}
                
                >
                {modal}
                {/* <CallOut
                    title='Students Profile'
                    description='Shows the students information and their fees profiling.'
                /> */}

                <div className="row">
                    <div className="col-md-3">
                        {studentInfo && 
                            <ProfileCard 
                                profile={studentInfo}
                                methods={{
                                    terminateBilling:handleTerminateBilling,
                                    activateBilling:handleActivateBilling
                                }}
                            />}

                    </div>

                    <div className="col-md-9">
                        {info}
                        <div className="card">
                            <div className="card-header p-2">
                                <ul className="nav nav-pills">
                                    <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Profile Info</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Report Viewer</a></li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content">
                                   

                                    <div className="active tab-pane" id="timeline">
                                        
                                            <div className='row'>
                                                    <div className='col-md-5'>
                                                    {studentInfo && <ProfileContacts contacts={studentInfo.contact_info}/>}
                                                    </div>
                                                    <div className='col-md-7'>
                                                    {studentInfo && <StudentReports studentId={studentId}/>}
                                                    </div>
                                            </div>

                                        
                                    </div>


                                    <div className="tab-pane" id="settings">
                                        {/** Content goes here */}
                                        {studentInfo && <ReportFormatSelector/> }
                                        {!currentReportId && <ItemNotFound description="No report available for preview"/>}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>

        )
    

}

export default StudentProfile;
