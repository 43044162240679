import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ReportContext from "../../../Context/ReportContext";

const ListReports = (props)=>{

    const { reportList, setCurrentReportId } = useContext(ReportContext);

    const onChangeReport=(id)=>{
            setCurrentReportId(id)
    }

    return(
        <div className="card">
        <div className="card-header">
        <h3 className="card-title">Report Listing</h3>
        
        <div className="card-tools">
             <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
            </button>
        </div>
        </div>
        <div className="card-body p-0">

        <ul className="nav nav-pills flex-column">
           {Array.isArray(reportList) && reportList.map((item, key)=>
            <li className="nav-item" key={key}>
                <Link to="#" className="nav-link" style={{fontSize:"12px"}} onClick={()=>onChangeReport(item.id)}>
                    <i className="fa fa-file txt-primary"></i> {item.title}
                </Link>
                </li>
           ) } 
           
        </ul>

        </div>
    </div>
    )
}

export default ListReports