import React, { Component } from 'react';

class ItemNotFound extends Component {
    render() {
        return (
            <div className="error-page">
					<div className="">
						{/* <h2>404</h2> */}
					</div>
					<div className="">
					{/* <h3><i className="fa fa-warning text-warning"></i>{this.props.title || "404"}</h3> */}
						
					</div>
					<p>{this.props.description || "Not found"}</p>
				</div>
        );
    }
}

export default ItemNotFound;