import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CheckList from '../Common/List/CheckList';

class StudentFilter extends Component {
   

    render() {
        return (
            <div className="col-xl-3 col-lg-12 d-none d-xl-block custom-leftnav">
                <div className="main-content-left-components">
                    
                <CheckList 
                        title="Classes" 
                        id="class"
                        list={
                                [
                                    {label:"S.1", id:"1"},
                                    {label:"S.1", id:"2"}
                                ]
                            }
                        />



                    <CheckList 
                        title="Streams" 
                        id="class_streams"
                        list={
                                [
                                    {label:"S.1 East", id:"1"},
                                    {label:"S.1 West", id:"2"}
                                ]
                            }
                        />

                    
                    <CheckList 
                        title="Houses" 
                        id="houses"
                        list={
                                [
                                    {label:"St. Andrews", id:"1"},
                                    {label:"St. Thomas", id:"2"}
                                ]
                            }
                        />


                </div>
        </div>
        );
    }
}

export default StudentFilter;