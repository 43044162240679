import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import TextInput from '../../Components/Common/TextInput';
import ConfigMenu from '../../Components/Config/ConfigMenu';
import PageContainer from '../../Components/Headings/PageContainer';
import StaffContext from '../../Context/StaffContext';
import ajaxStaff from '../../util/remote/ajaxStaff';

const NewStaffPage=()=>{

    const { getStaff }  = useContext(StaffContext)

   const [loading, setLoading] = useState(false);
   const [gender, setGender] = useState('');
   const [info, setInfo] = useState(false);



   const initialValues={
       firstName:'',
       lastName:'',
       mobileNumber:''
   }

   const [formValues, setFormValues] = useReducer((curVals, newVals)=>({...curVals, ...newVals}), initialValues)
   
   const {firstName, lastName, mobileNumber} = formValues;

  
   
   const confirmStaff =async(event)=>{

        event.preventDefault();
        if(firstName.length>0 && lastName.length>0 && mobileNumber.length>0 && gender.length>0)
        {
            const server_response = await ajaxStaff.registerStaff({
                    first_name : firstName,
                    last_name : lastName,
                    gender : gender,
                    contact :mobileNumber
                });


            if(server_response.status==="OK")
            {
                setInfo(<AlertSuccess message={server_response.message}/>)
                setFormValues(initialValues);
                getStaff();
            }else{
                setInfo(<AlertError message={server_response.message}/>)
            }

        }else{
            setInfo(<AlertError message="Select gender and class, then try again"/>)
        }

   }

   const handleFormChange =(event)=>{
        const {name, value} = event.target
        setFormValues({[name]:value})
   }

   

   
        const RenderGender=()=>{

            const options=[{label:"Male", value:"Male"}, {label:"Female", value:"Female"}];
            
            
            return <TextInput
                        label="Gender"
                        type="select" 
                        leftIcon="fa fa-user"
                        placeholder="Select Gender...." 
                        options={options}
                        onChange={(item)=>{setGender(item.value)}}
                        leftComponent={<i className="fa fa-user"></i>}
                        />
            
            }





            const RenderControls=()=>{
                return (
                    <div className='float-sm-right'>
                        <ol className='button-listing'>
                          
                            {/* <li>
                                <Link to="/student/import" className="btn ripple btn-secondary mb-1">
                                    <i className="fa fa-upload mr-1"></i>&nbsp;Import Students</Link>
                            </li> */}
                        </ol>
                       
            
                        
                    </div>
                  
                )
              }

   
        return (
            <PageContainer 
                title="New Staff"
                headerNav={<RenderControls/>}
                >
                <div className="row">
                    <div className="col-md-9">
                        <CallOut title="Staff Registration" description="Staff Registration Number will be generated automatically"/>
                        {info}
                        <form className="form-horizontal" method="post" onSubmit={confirmStaff}>
                        <div className="row">
                       
                        <div className="col-md-12">


                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Basic info</h3>
                                </div>

                                <div className="card-body">
            
                                
                                        <TextInput
                                            type="text"
                                            label="Last Name"
                                            name="lastName"
                                            placeholder="Enter Surname"
                                            leftIcon="fa fa-user"
                                            value={lastName}
                                            onChange={handleFormChange} 
                                            required
                                        />

                                     <TextInput
                                        type="text"
                                        label="First Name"
                                        name="firstName"
                                        placeholder="First Name"
                                        leftIcon="fa fa-user"
                                        value={firstName}
                                        onChange={handleFormChange} 
                                        required
                                        />

                                        {RenderGender()}

                                        <TextInput
                                            type="text"
                                            label="Mobile Number"
                                            name="mobileNumber"
                                            placeholder="Mobile Number"
                                            leftIcon="fa fa-phone"
                                            value={mobileNumber}
                                            onChange={handleFormChange} 
                                        />





                                <div className="row">&nbsp;</div>
                                
                            

                                </div>
                            </div>{/** close card */}



                        </div>{/** Left */}


                    
                      </div>




                    <div className="modal-footer no-border">
                               {!loading && <button type="submit" className="btn btn-primary">Save Staff</button>}
                            </div>



                    </form> 


                    </div>{/** body end */}



                    <ConfigMenu/>

                

                </div>

        </PageContainer>
        
        );
    
}

export default NewStaffPage;