import React, { Component, useContext, useEffect, useReducer, useState } from 'react';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import TextInput from '../../Components/Common/TextInput';
import TopBar from '../../Components/Common/TopBar';
import PageContainer from '../../Components/Headings/PageContainer';
import PopNewReportExam from '../../Components/Report/PopNewReportExam';
import ConfigContext from '../../Context/ConfigContext';
import ReportContext from '../../Context/ReportContext';
import functions from '../../util/functions';
import ajaxReport from '../../util/remote/ajaxReport';

const NewReportPage=()=>{

    const {allClasses, allTerms, allExams, reportFormats, advancedScales, primaryScales} = useContext(ConfigContext);
    const {getReports} = useContext(ReportContext)

   const [loading, setLoading] = useState(false);
   const [classId, setClassId] = useState('');
   const [title, setTitle] = useState('');
   const [info, setInfo] = useState(false);
   const [term, setTerm] = useState('');
   const [exam, setExam] = useState(false)
   const [classLoader, setClassLoader] = useState(true);
   const [mdlNewExam, setMdlNewExam] = useState(false);
   const [selectedTests, setSelectedTests] = useState([]);
   const [reportFormat, setReportFormat] = useState('');
   const [primaryScale, setPrimaryScale] = useState('');
   const [advancedScale, setAdvancedScale] = useState('');


   useEffect(()=>{

        if(allClasses)
        {
            setClassLoader(false)
        }

   }, [allClasses])

   useEffect(()=>{
       if(exam)
       {
        setMdlNewExam(false);
        setInfo(false)
        setTimeout(()=>{
            setMdlNewExam(<PopNewReportExam exam={exam} onConfirm={setExamMark}/>)

        }, 10)

       }
   }, [exam])

   useEffect(()=>{
        if(mdlNewExam)
        {
            functions.openPopup("mdl-new-report-exam")
        }
   }, [mdlNewExam])

 

   const initialValues={
       reportTitle:'',
       reportDate:''
   }

   const [formValues, setFormValues] = useReducer((curVals, newVals)=>({...curVals, ...newVals}), initialValues)
   
   const {reportTitle, reportDate} = formValues;

   const setExamMark=(selectedExam)=>{
       const newSelection = [];
       for(var i=0; i<selectedTests.length; i++)
       {    
            newSelection.push(selectedTests[i]);
            if(selectedTests[i].test_id*1 === selectedExam.test_id*1)
            {
                setInfo(<AlertError message={"Exam already added to this report"}/>)
                return false;
            }
       }
       newSelection.push(selectedExam)
       setSelectedTests(newSelection)
   }
   
   const confirmReport =async(event)=>{

        event.preventDefault();
        if(reportTitle.length>0 && reportDate.length>0 && classId>0 && term>0 && reportFormat>0)
        {
            if(selectedTests.length===0)
            {
                setInfo(<AlertError message={"Add report exams and try again."}/>)
                return false;
            }
            const server_response = await ajaxReport.createReport({
                    "class_id" : classId,
                    "report_date" : reportDate,
                    "term_id" : term,
                    "title" : reportTitle,
                    "tests":selectedTests,
                    "report_format":reportFormat,
                    "primary_scale": primaryScale,
                    "advanced_scale":advancedScale
                });


            if(server_response.status==="OK")
            {
                setInfo(<AlertSuccess message={server_response.message}/>)
                setFormValues(initialValues);
                getReports();
            }else{
                setInfo(<AlertError message={server_response.message}/>)
            }

        }else{
            setInfo(<AlertError message="Complete all fields and try again"/>)
        }

   }

   const handleFormChange =(event)=>{
        const {name, value} = event.target
        setInfo(false)
        setFormValues({[name]:value})
   }

   

    const RenderClassRoom=()=>{

        const optionsClasses=[];
        if(Array.isArray(allClasses))
        {
            allClasses.map((item, key)=>
            optionsClasses.push({label:item.class_name, value:item.class_id})
            )
        }

        
        return( <>
                <TextInput
                    label="For Class"
                    type="select" 
                    // leftIcon="fa fa-university"
                    placeholder="Select class...." 
                    options={optionsClasses}
                    onChange={(item)=>{setClassId(item.value)}}
                    // leftComponent={<i className="fa fa-university"></i>}
                    loading={classLoader}
                    />


                   </>)
        
        }

    const RenderTerm=()=>{

            const optionsTerm=[];
            if(Array.isArray(allTerms))
            {
                allTerms.map((item, key)=>
                optionsTerm.push({label:item.term, value:item.term_id})
                )
            }
    
    
            
            return( <>
                    <TextInput
                        label="For Term"
                        type="select" 
                        placeholder="Select term...." 
                        options={optionsTerm}
                        onChange={(item)=>{setTerm(item.value)}}
                        />
    
    
                       </>)
            
    }



    const RenderReportFormats=()=>{

        const optionsFormat=[];
        if(Array.isArray(reportFormats))
        {
            reportFormats.map((item, key)=>
            optionsFormat.push({label:item.format_name + "(" + item.description + ")", value:item.format_id})
            )
        }


        
        return( <>
                <TextInput
                    label="Report Format"
                    type="select" 
                    placeholder="Select format...." 
                    options={optionsFormat}
                    onChange={(item)=>{setReportFormat(item.value)}}
                    />


                   </>)
        
    }



    const RenderPrimaryScales=()=>{

        const optionScales=[];
        if(Array.isArray(primaryScales))
        {
            primaryScales.map((item, key)=>
            optionScales.push({label:item.title, value:item.scale_id})
            )
        }


        
        return( <>
                <TextInput
                    label="Primary Scale"
                    type="select" 
                    placeholder="Select Primary Scale...." 
                    options={optionScales}
                    onChange={(item)=>{setPrimaryScale(item.value)}}
                    />


                   </>)
        
    }



    const RenderAdvancedScales=()=>{

        const optionScales=[];
        if(Array.isArray(advancedScales))
        {
            advancedScales.map((item, key)=>
                optionScales.push({label:item.scale_name + "(" + item.description + ")", value:item.scale_id})
            )
        }


        
        return( <>
                <TextInput
                    label="Advanced Scale"
                    type="select" 
                    placeholder="Select Advanced Scale...." 
                    options={optionScales}
                    onChange={(item)=>{setAdvancedScale(item.value)}}
                    />


                   </>)
        
    }
    
       

    const RenderTests=()=>{

        
        return(
           
            <div className="card">
            <div className="card-header">
            <h3 className="card-title">Exam Check List</h3>

            <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
                </button>
            </div>
            </div>
            <div className="card-body p-0">
            <ul className="nav nav-pills flex-column">
                {Array.isArray(selectedTests) && selectedTests.map((item, key)=>
                 <li className="nav-item" key={key}>
                    <div className="icheck-primary ml-4">
                        <input type="checkbox" value={item.test_id} id={"tests-" + item.test_id} checked={true}/>
                        <label htmlFor={"tests-" + item.test_id} className="checklist-link">{item.exam_name} <span id={item.test_id + "-out-of"}> / {item.out_of}</span> </label>
                    </div>
                </li>
                )}
               
               
            </ul>
            </div>
                                            
        </div>        

        )
    }


    const RenderTestSelect=()=>{

        const optionsTests=[];
        if(Array.isArray(allExams))
        {
            allExams.map((item, key)=>
            optionsTests.push({label:item.exam_name, value:item.id})
            )
        }
        
        return( <>
                <TextInput
                    label="Report Exams"
                    type="select" 
                    placeholder="Select to add exam...." 
                    options={optionsTests}
                    onChange={(item)=>{setExam(item)}}
                    />


                   </>)
        
    }

   
        return (
            <PageContainer title="New Report">
                {mdlNewExam}
                <div className="row">
                    <div className="col-md-12">
                        <CallOut title="Create Academic Reports" description="Only students appearing in the marksheet will be considered in the new report."/>
                        {info}
                        <form className="form-horizontal" method="post" onSubmit={confirmReport}>
                        <div className="row">
                       
                        <div className="col-md-8">


                            <div className="card">
                                <div className="card-header">
                                    {/* <h3 className="card-title">Basic info</h3> */}
                                </div>

                                <div className="card-body">

                                     {RenderReportFormats()}

                                    { RenderClassRoom() }
            
                                    <TextInput
                                        type="text"
                                        label="Report Title"
                                        name="reportTitle"
                                        placeholder="Report Title"
                                        // leftIcon="fa fa-note"
                                        value={reportTitle}
                                        onChange={handleFormChange} 
                                        required
                                    />

                                     <TextInput
                                        type="date"
                                        label="Report Date"
                                        name="reportDate"
                                        placeholder="Report Date"
                                        // leftIcon="fa fa-date"
                                        value={reportDate}
                                        onChange={handleFormChange} 
                                        required
                                        />

                                   

                                    { RenderTerm() }  

                                    {RenderTestSelect()}

                                    {RenderPrimaryScales()}

                                    {RenderAdvancedScales()}
                                           
                                        


                                <div className="row">&nbsp;</div>
                                
                                <div className="modal-footer no-border">
                                    {!loading && <button type="submit" className="btn btn-primary">Save Report</button>}
                                </div>

                                </div>
                            </div>{/** close card */}



                        </div>{/** Left */}

                        <div className="col-md-4">
                                {RenderTests()}
                        </div>


                      
                      
                      </div>




                   



                    </form> 


                    </div>{/** body end */}

                

                </div>

        </PageContainer>
        
        );
    
}

export default NewReportPage;