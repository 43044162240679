import React, { Component } from 'react';
import ConfigContext from '../../../Context/ConfigContext';
import dictionary from '../../../util/dictionary';
import ajaxConfig from '../../../util/remote/ajaxConfig';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class EditSchoolProfile extends Component {

    static contextType = ConfigContext;

    state={
        schoolName:'',
        address:'',
        telephone:'',
        motto:'',
        website:'',
        email:'',
        shortName:'',
        info:false,
        actualFile:"",
        fileSource:""

    }

    componentDidMount() {
        const {schoolProfile} = this.context;
        if(schoolProfile)
        {
            this.setState({
                schoolName:schoolProfile.school_name || "",
                address: schoolProfile.address || "",
                telephone: schoolProfile.telephone || "",
                website: schoolProfile.website || "",
                email:schoolProfile.email_address || "",
                motto: schoolProfile.motto || "",
                shortName: schoolProfile.short_name || ""
            })
        }
       
    }

  

    handleFormChange=(event)=>{
        this.setState({
           [event.target.name]:event.target.value
        })
    }


   handleFileChange=(event)=>{
        this.setState({
            fileSource:event.target.value
        })
        let files=event.target.files;
        let reader=new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload=(e)=>{
            this.setState({
                actualFile:{file:  e.target.result}
            })
            
        }
}

   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {schoolName, motto, address, email, website, shortName, telephone}=this.state;
        
        const data={
            "school_name":schoolName,
            "telephone":telephone,
            "address":address,
            "email_address":email,
            "motto":motto,
            "short_name":shortName,
            "website":website,
            "badge":this.state.actualFile
        }
       
        if(schoolName.length>0 && motto.length>0 && address.length>0 && email.length>0 && telephone.length>0 && shortName.length>0)
        {
          
            const server_response = await ajaxConfig.updateSchoolInfo(data);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    RenderRoles=()=>{
        const {userRoles} = this.context;
        const optionRoles=[];
        Array.isArray(userRoles) && userRoles.map((item, key)=>
            optionRoles.push({label:item.role_name, value:item.role_id})
        )
        return  <TextInput
        type="select"
        label="User Role"
        placeholder="Select User Role...."
        leftIcon="fa fa-user"
        onChange={this.setRoleId} 
        options={optionRoles}
        required
        wrap
        />
    }


    render() {
        return (
            
    <SystemModal id="mdl-edit-school" title="Edit School Profile" type="modal-lg">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="School Name"
                            placeholder="School Name"
                            leftIcon="fa fa-info"
                            value={this.state.schoolName}
                            name="schoolName"
                            onChange={this.handleFormChange} 
                            required
                            />

                <TextInput
                    type="text"
                    label="School Address"
                    placeholder="School Address"
                    leftIcon="fa fa-map-pin"
                    value={this.state.address}
                    name="address"
                    onChange={this.handleFormChange} 
                    required
                    />


               <TextInput
                    type="text"
                    label="School Motto"
                    placeholder="School Motto"
                    leftIcon="fa fa-info"
                    value={this.state.motto}
                    name="motto"
                    onChange={this.handleFormChange} 
                    required
                    />


               <TextInput
                    type="text"
                    label="Telephone"
                    placeholder="Telephone"
                    leftIcon="fa fa-phone"
                    value={this.state.telephone}
                    name="telephone"
                    onChange={this.handleFormChange} 
                    required
                    />


                <TextInput
                    type="text"
                    label="Email Address"
                    placeholder="Email address"
                    leftIcon="fa fa-envelope"
                    value={this.state.email}
                    name="email"
                    onChange={this.handleFormChange} 
                    required
                    />
               
    
               <TextInput
                    type="text"
                    label="Website"
                    placeholder="Website"
                    leftIcon="fa fa-globe"
                    value={this.state.website}
                    name="website"
                    onChange={this.handleFormChange} 
                    />
                <TextInput
                    type="text"
                    label="Short Name"
                    placeholder="Short Name"
                    leftIcon="fa fa-info"
                    value={this.state.shortName}
                    name="shortName"
                    onChange={this.handleFormChange} 
                    required
                    />


                <TextInput
                    type="file"
                    label="School Badge"
                    placeholder="Website"
                    leftIcon="fa fa-info"
                    value={this.state.fileSource}
                    name="badge"
                    onChange={this.handleFileChange} 
                    />


       
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default EditSchoolProfile;