/* eslint-disable import/no-anonymous-default-export */
import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('eschool@user');
const apiHost=dictionary.apiHost

export default {


    async login(username, password) {
      let data = {
        account_name: username,
        password: password
      }
      try {
        let response = await fetch(apiHost + 'login',
          {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },


   async resetPassword(password, rePassword) {
        let data = {
          password: password,
          re_password:rePassword
        }
        try {
          let response = await fetch(apiHost + 'user/password/reset',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },





      async captiveLogin(username, password) {
        let data = {
          username: username,
          password: password
        }
        
        try {
          let response = await fetch(dictionary.captiveHost + "login/captive",
            {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

    

}