import React, { Component, useContext, useEffect, useState } from 'react';
import { ConfigConsumer } from '../../Context/ConfigContext';
import ReportContext from '../../Context/ReportContext';
import StaffContext from '../../Context/StaffContext';
import dictionary from '../../util/dictionary';
import functions from '../../util/functions';
import ajaxConfig from '../../util/remote/ajaxConfig';
import ajaxReport from '../../util/remote/ajaxReport';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import ListItem from '../Common/List/ListItem';
import SelectSearch from '../Common/SelectSearch';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';
import GeneralCommentWriter from './Elements/GeneralCommentWriter';

const PopReportComments =(props)=> {

    const [info, setInfo] = useState(false)
    const [self, setSelf] = useState("mdl-report-comment");
    const [commentId, setCommentId] = useState("");
    const [reportComments, setReportComments] = useState(false);
    const [activeComment, setActiveComment] = useState(false)
    const [teacherId, setTeacherId] = useState("");



    const onChangeComment=(option)=>setCommentId(option.value)

    const {staffList} = useContext(StaffContext);


    const {reportCommentListing, generateReports} = useContext(ReportContext);

    useEffect(()=>{
        getReportComments();
    }, [])

    const getReportComments=async()=>{
        const server_response = await ajaxReport.getReportComments(props.report.report_info.id);
        if(server_response.status==="OK")
        {
            setReportComments(server_response.details)
        }else{
            setReportComments("404")
            setInfo(<AlertError type="info" message={server_response.message}/>)
        }
    }


    const RenderComment=()=>{

        const optionsListing=[];

        if(Array.isArray(reportCommentListing))
        {
            reportCommentListing.map((item, key)=>
            optionsListing.push({label:item.title, value:item.id})
            )
         }



      
            return(
                <TextInput
                    label="Set Report Comment"
                    leftIcon="fa fa-comment"
                    type="select" 
                    placeholder="Select comment...." 
                    options={optionsListing}
                    onChange={onChangeComment}
                    leftComponent={<i className="fa fa-comment txt-primary"/>}
                    wrap
                />
            )

          
        }



        const onChangeTeacher=(option)=>setTeacherId(option.value)

        const RenderTeacher=()=>{

            const optionsListing=[];
    
            if(Array.isArray(staffList))
            {
                staffList.map((item, key)=>
                optionsListing.push({label:item.last_name + " " + item.first_name, value:item.staff_id})
                )
             }
    
                return(
                    <SelectSearch 
                        placeholder="Select search" 
                        className="form-control"
                        options={optionsListing}
                        onChange={onChangeTeacher}
                        />
                )
    
              
            }
    

   
    

   const confirmSave=async(event)=>{
        event.preventDefault();
  
        if(commentId>0)
        {   
            setInfo(<ActivityLoader/>)
           const server_response = await ajaxReport.addReportComment(props.report.report_info.id, commentId);
           if(server_response.status==="OK")
           {
            setInfo(<AlertSuccess message={server_response.message}/>);
            getReportComments();
           }else{
            setInfo(<AlertError message={server_response.message}/>)
           }

        }else{
            
            setInfo(<AlertError message={"Select comment and try again"}/>)
        
        }
    }


    const refresh=(updated)=>{
        setActiveComment(false);
        if(updated)
        {
            getReportComments();
        }
        
    }


    const deleteCommentField=async(fieldId)=>{

        const server_response = await ajaxReport.deleteReportCommentField(fieldId);
        if(server_response.status==="OK")
        {
            getReportComments();
            generateReports();
            setInfo(<AlertSuccess message={server_response.message}/>)
        }else{
            setInfo(<AlertError type="info" message={server_response.message}/>)

        }

    }


    const RenderItem=(propItem)=>{
        const item = propItem.item;
        if(item.comment.scope.id*1===2)
        {
            if(activeComment && item.id===activeComment.id)
            {   
                return <GeneralCommentWriter field={item} onConfirm={refresh}/>

            }else{

                return <ListItem 
                title={item.comment.title}
                subtitle={item.value}
                delete={()=>{deleteCommentField(item.id)}}
                edit={()=>{setActiveComment(item)}}
                listIcon="fa fa-comment"
                />
            }
           
        }else{
            return <ListItem 
            title={item.comment.title}
            delete={()=>{deleteCommentField(item.id)}}
            listIcon="fa fa-comment txt-primary"
            component={<RenderTeacher/>}
        />
        }
    }



        return (
            
            <SystemModal id={self} title={"Change Report Comments"} type="modal-xl">
            <form method="post" className="form-horizontal" onSubmit={confirmSave}>
                <div className='row'>
                    <div className='col-12'>
                    {info}

                    {!reportComments && <ActivityLoader/>}
                    <ul className="todo-list" data-widget="todo-list">
                    {Array.isArray(reportComments) && reportComments.map((item, key)=>
                        <RenderItem item={item} key={key}/>
                    )}
                    </ul>
                    </div>
                </div>


                    <div className='row'>
                        <div className='col-8'>
                            {RenderComment()}
                        </div>
                        <div className='col-4'>
                            <button type="submit" className="btn btn-success" style={{marginTop:"35px"}}>Add Comment</button>
                        </div>
                    </div>
                   
                    
                    
                    <div className="modal-footer no-border">
                       
                    </div>

            </form> 

        

            </SystemModal>
        );
    
}

export default PopReportComments;