import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import SystemNotify from '../NavMenus/SystemNotify';
import ProfileControls from '../NavMenus/ProfileControls';
import TableTools from './Filter/TableTools';
import SearchContext from '../../Context/SearchContext';


const TopBar=()=>{



  const {query, setQuery } = useContext(SearchContext);

  const [searchString, setSearchString] = useState(query);

  const navigate = useNavigate();

     
     

	 
	const onSearch = (event)=>{
		event.preventDefault();
		setQuery(searchString)
    navigate("/finder")
 }



    
       
    return(
		<>
		  <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link to="#" className="nav-link side-trigger" data-widget="pushmenu" role="button"><i className="fas fa-bars"></i></Link>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <Link to="/student/listing" className="nav-link">Class Lists</Link>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <Link to="/marks/new" className="nav-link">Marks Entry</Link>
      </li>
    </ul>

    <form className="form-inline ml-3" method="get" onSubmit={onSearch}>
      <div className="input-group input-group-sm">
        <input className="form-control form-control-navbar" onChange={(e)=>setSearchString(e.target.value)} value={searchString} type="search" placeholder="Search" aria-label="Search"/>
        <div className="input-group-append">
          <button className="btn btn-navbar" type="submit">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form>

    <TableTools/>

    {/* Right navbar links */}
    <ul className="navbar-nav ml-auto">
      {/* Messages Dropdown Menu */}
      <ProfileControls/>
      <SystemNotify/>
      {/* <li className="nav-item">
        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button"><i
            className="fas fa-th-large"></i></a>
      </li> */}
    </ul>
  </nav>

		 </>
       )
    

}

export default TopBar