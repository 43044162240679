import React, { useContext, useState } from "react";
import ReportContext from "../../../Context/ReportContext";
import ajaxReport from "../../../util/remote/ajaxReport";
import ActivityLoader from "../../Common/ActivityLoader";

const CommentWriter=(props)=>{

    const [remark, setRemark] = useState(props.field.value?props.field.value.value:"");
    const [loading, setLoading] = useState(false);
    const { generateReports } = useContext(ReportContext)

    const confirmRemark=async()=>{
        if(remark.length>0)
        {   
            setLoading(true)
            const server_response = await ajaxReport.writeReportComment(
                props.field.field.report_id,
                props.field.field.comment.id,
                props.field.student_id,
                remark
            );
            setLoading(false)
            if(server_response.status==="OK"){
                props.onConfirm()
                //refresh reports
                generateReports(false)
            }else{

            }
        }
    }

    return(
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around" }}>
            <div style={{flex:3}}>
                <textarea 
                    className="ReportComments" 
                    placeholder="Enter remark here" 
                    onChange={(e)=>{setRemark(e.target.value)}}
                    style={{width:"95%", height:"40px", maxHeight:"40px", maxWidth:"95%", minHeight:"20px", minWidth:"85%", color:"#0099E4", fontfamily:"Arial, Helvetica, sans-serif", fontSize:"14px"}} 
                    value={remark}/>
            </div>
            <div style={{flex:1}}>
                {!loading && <input 
                    type="button" 
                    value="Comment"
                    className="btn btn-sm btn-primary" 
                    style={{height:"40px"}}
                    onClick={confirmRemark}
                    />}

                 {loading && 
                 <button className="btn btn-sm btn-primary"  style={{height:"40px"}}>
                    <ActivityLoader inline size={25} color={"#FFFFFF"}/>
                 </button>
                 }
            </div>
        </div>

    )
}

export default CommentWriter;