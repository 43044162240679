import React, { useContext, useState } from "react";
import ReportContext from "../../../Context/ReportContext";
import ActivityLoader from "../../Common/ActivityLoader";

const TextWriter=(props)=>{

    const [remark, setRemark] = useState(props.value);
    const [loading, setLoading] = useState(false);
    
    const confirm=async()=>{
       
    }

    return(
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around" }}>
            <div style={{flex:3}}>
                <input type="text" 
                    className="ReportComments" 
                    placeholder={"Type here... "}
                    onChange={(e)=>{setRemark(e.target.value)}}
                    style={{width:"95%", color:"#0099E4", fontfamily:"Arial, Helvetica, sans-serif", fontSize:"14px"}} 
                    value={remark}/>
            </div>
            <div style={{flex:1}}>
                {!loading && <input 
                    type="button" 
                    value="Save"
                    className="btn btn-sm btn-primary" 
                    style={{height:"40px"}}
                    onClick={confirm}
                    />}

                 {loading && 
                 <button className="btn btn-sm btn-primary"  style={{height:"40px"}}>
                    <ActivityLoader inline size={25} color={"#FFFFFF"}/>
                 </button>
                 }
            </div>
        </div>

    )
}

export default TextWriter;