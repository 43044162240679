import React, { Component, useContext } from 'react';
import LeftSideBar from '../Components/Common/LeftSideBar';
import TitleValueCard from '../Components/Common/TitleValueCard';
import TopBar from '../Components/Common/TopBar';
import ContentHeader from '../Components/Headings/ContentHeader';
import CurrentPopulation from '../Components/Home/CurrentPopulation';
import TermlyReports from '../Components/Home/TermlyReports';
import ConfigContext from '../Context/ConfigContext';
import StaffContext from '../Context/StaffContext';

const DashboardPage=(props)=> {
        const {totalSubjects, stdCurrentPeriod, totalClasses} = useContext(ConfigContext)
        const {totalStaff} = useContext(StaffContext)
        return (
            <>
            <TopBar/>
            <LeftSideBar/>
            <div className="content-wrapper">
                <ContentHeader title="Dashboard"/>
                <section className="content">
                    <div className="container-fluid">

                        <div className="row">

                            <TitleValueCard 
                                title="Staff" 
                                value={totalStaff}
                                icon={<span className="info-box-icon bg-success elevation-1"><i className="fas fa-chalkboard-teacher"></i></span>}
                                />

                            <TitleValueCard 
                                title="Classes/Streams" 
                                value={totalClasses}
                                icon={<span className="info-box-icon bg-warning elevation-1"><i className="fas fa-chalkboard"></i></span>}
                                />

                             <TitleValueCard 
                                title="Subjects" 
                                value={totalSubjects}
                                icon={<span className="info-box-icon bg-primary elevation-1"><i className="fas fa-book-open"></i></span>}
                                />

                            <TitleValueCard 
                                title="Academic Period" 
                                value={stdCurrentPeriod}
                                icon={<span className="info-box-icon bg-success elevation-1"><i className="fas fa-clock"></i></span>}
                                />


                        </div>

                        <div className='row'>
                                <div className='col-sm-12 col-xl-4 col-lg-4'>
                                        <CurrentPopulation/>
                                </div>



                        <div className="col-sm-12 col-xl-4 col-lg-4">
							<TermlyReports/>
						</div>



                        </div>

                           

                    </div>
            </section>
        </div>

         </>
        );
    }


export default DashboardPage;