import React from 'react';
const StudentHeading = (props)=> {
        return (
        <>
            {props.title && <span style={{float:"left", width:"100%", textAlign:"center"}}>
            <h3 style={{fontSize:"15px"}}>{props.title}</h3></span>}
            <table className="Reports" border="0" width="100%" style={{fontSize:"13px"}}>
            <tbody>
            <tr>
            <td>
                <b>NAME: </b>{props.studentInfo.full_name} &nbsp;&nbsp;
                <b>REG NO: </b>{props.studentInfo.reg_no}&nbsp;&nbsp;
                <b>SEX: </b>{props.studentInfo.sex}&nbsp;&nbsp;
                <b>CLASS: </b>{props.report.class.class_name}<br/><br/>
                
                <b>TERM: </b>{props.report.term.term}&nbsp;&nbsp;
                
                <b>YEAR: </b>{props.report.year}&nbsp;&nbsp;
                
                {/* <b>COMBINATION: </b> N/A&nbsp;&nbsp; */}
                
                <b>NO. IN FORM:</b> {props.total && <>{props.total.total_p}</>}
            </td>
            <td style={{color:"#900"}}>
                <b>PAYMENT CODE: {props.studentInfo.other_reg}</b>
            </td>
            </tr>
            </tbody>
        </table>
        </>
        );
    }


export default StudentHeading;