import React, { Component, useContext, useEffect, useReducer, useState } from 'react';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import SelectSearch from '../../Components/Common/SelectSearch';
import TextInput from '../../Components/Common/TextInput';
import TopBar from '../../Components/Common/TopBar';
import PageContainer from '../../Components/Headings/PageContainer';
import PageTitle from '../../Components/Headings/PageTitle';
import ConfigContext from '../../Context/ConfigContext';
import StudentContext from '../../Context/StudentContext';
import ajaxConfig from '../../util/remote/ajaxConfig';
import ajaxStudent from '../../util/remote/ajaxStudent';

const ImportStudentPage=()=>{

    const {allClasses} = useContext(ConfigContext);
    const {getStudents} = useContext(StudentContext)

   const [loading, setLoading] = useState(false);
   const [classId, setClassId] = useState('');
   const [gender, setGender] = useState('');
   const [info, setInfo] = useState(false);
   const [streamList, setStreamList] = useState(false);
   const [classLoader, setClassLoader] = useState(true);
   const [streamId, setStreamId] = useState('');
   const [fileSource, setFileSource] = useState('');
   const [actualCsvFile, setActualCsvFile] = useState('');

   useEffect(()=>{

        if(allClasses)
        {
            setClassLoader(false)
        }

   }, [allClasses])

   useEffect(()=>{
        if(classId>0)
        {   setClassLoader(true)
            getStreams();
        }
   }, [classId])


   
   const getStreams=async()=>{

        const server_response = await ajaxConfig.getClassStreams(classId);
        setClassLoader(false)
        if(server_response.status==="OK")
        {
            setStreamList(server_response.details);
        }else{
            setStreamList("404")
        }

   }
   
   const confirmStudent =async(event)=>{

        event.preventDefault();
        if(classId>0 && fileSource.length>0)
        {
            setLoading(true);
            const server_response = await ajaxStudent.importStudents({
                "class_id":classId,
                "file":actualCsvFile,
                "stream_id":streamId>0?streamId:0
             });

             setLoading(false)
            if(server_response.status==="OK")
            {
                setInfo(<AlertSuccess message={server_response.message}/>)
                setActualCsvFile("");
                setFileSource("");
                getStudents();
            }else{
                setInfo(<AlertError message={server_response.message}/>)
            }

        }else{
            setInfo(<AlertError message="Complete all fields, then try again"/>)
        }

   }


   const handleFileChange=(event)=>{
        setFileSource(event.target.value)
            let files=event.target.files;
            let reader=new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload=(e)=>{
                   setActualCsvFile({file:  e.target.result})
                
            }
   }


    const RenderClassRoom=()=>{

        const optionsClasses=[];
        if(Array.isArray(allClasses))
        {
            allClasses.map((item, key)=>
            optionsClasses.push({label:item.class_name, value:item.class_id})
            )
        }


        const optionStreams=[];
        if(Array.isArray(streamList))
        {
            streamList.map((item, key)=>
            optionStreams.push({label:item.stream_name, value:item.stream_id})
            )
        }
        
        return( <>
                <TextInput
                    label="Class"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder="Select class...." 
                    options={optionsClasses}
                    onChange={(item)=>{setClassId(item.value)}}
                    leftComponent={<i className="fa fa-university"></i>}
                    loading={classLoader}
                    />

               {Array.isArray(streamList) && <TextInput
                    label="Stream"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder="Select Stream...." 
                    options={optionStreams}
                    onChange={(item)=>{setStreamId(item.value)}}
                    leftComponent={<i className="fa fa-university"></i>}
                    />}

                   </>)
        
        }

   
       

   
        return (
            <PageContainer 
                title="Import Students"
                >
                <div className="row">
                    <div className="col-md-12">
                        <CallOut title="CSV Sheet format" description="First Name, Last Name, Sex(Male/Female)"/>
                        {info}
                        <form className="form-horizontal" method="post" onSubmit={confirmStudent}>
                        <div className="row">
                       
                      
                        <div className="col-md-12">
                                <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">CSV File details</h3>
                                        </div>

                                        <div className="card-body">
                    
                                            {RenderClassRoom()}
                                            <TextInput
                                                type="file"
                                                label="CSV File"
                                                name="csvFile"
                                                placeholder="Browse CSV file"
                                                leftIcon="fa fa-file"
                                                value={fileSource}
                                                onChange={handleFileChange} 
                                                required
                                                />

                                        <div className="row">&nbsp;</div>
                                        </div>
                                    </div>{/** close card */}
                        </div> {/** Left */}

                       

                      
                      </div>




                    <div className="modal-footer no-border">
                               {!loading && <button type="submit" className="btn btn-success">Start Upload</button>}
                               {loading && <button type="button" className="btn btn-success" style={{width:"100px"}}><ActivityLoader inline size={20} color="#FFFFFF"/></button>}
                            </div>



                    </form> 


                    </div>{/** body end */}

                

                </div>

        </PageContainer>
        
        );
    
}

export default ImportStudentPage;