import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import EditClassRoom from '../../Components/Config/ClassRoom/EditClassRoom';
import ConfigMenu from '../../Components/Config/ConfigMenu';
import EditSubject from '../../Components/Config/Subject/EditSubject';
import NewSubject from '../../Components/Config/Subject/NewSubject';
import PageContainer from '../../Components/Headings/PageContainer';
import { ConfigConsumer } from '../../Context/ConfigContext';
import functions from '../../util/functions';

class SubjectPage extends Component {

    state={
        currentRecord:false,
        transactionId:'',
        mdlCrud:false
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }

    newRecord=()=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <NewSubject refresh={props.getAllSubjects}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-new-subject"))
        })
    }

    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>

                {this.state.currentRecord && <li>
                        <Link 
                            to={`/configuration/subjects/${this.state.currentRecord.subject_id}`} 
                            state={{record:this.state.currentRecord}}
                            className="btn ripple btn-warning mb-1">
                            <i className="fa fa-edit mr-1"></i>&nbsp;Manage Subject</Link>
                    </li>}
                  <li>
                        <Link to="#" className="btn ripple btn-success mb-1" onClick={this.newRecord}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;Create New</Link>
                    </li>
                {/* <li>
                    <div className="margin">
                    <div className="btn-group">
                    <button type="button" className="btn btn-default">Options ...</button>
                    <button type="button" className="btn btn-default dropdown-toggle dropdown-icon" data-toggle="dropdown">
                      <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu" role="menu">
                      <a className="dropdown-item" href="#">Subject Types</a>
                      <a className="dropdown-item" href="#">Subject Categories</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#">Manage Subject Tutors...</a>
                    </div>
                  </div>
                  </div>

                </li> */}

                  
                  
                    <li>
                        <Link to="/configurations" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                    </li>
                  
                </ol>
               
    
                
            </div>
          
        )
      }
    

      editRecord=(record)=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <EditSubject record={record} refresh={props.getAllSubjects}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-edit-subject"))
        })
    }


    RenderTransactions=()=>{
        return(
            <ConfigConsumer>
                {props=>{
                    const list = props.allSubjects;
                    return(
                        <div className="table-responsive table-ellipsis">
                        <table className="table expandable-table table-striped text-nowrap mb-0">
                            <thead>
                                <tr>
                                <th>No.</th>                                   
                                <th>Subject Name</th>
                                <th>Level</th> 
                                <th>Abbr</th>
                                <th>Category</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(list) && list.map((item, key)=>
                                <tr 
                                    className={`${item.subject_id===this.state.transactionId?'flag-row':'hot-tr'}`}
                                    style={{cursor:'default'}}
                                    onClick={()=>this.onSelectRecord(item.subject_id, item)}
                                    key={key}>
                                    <td>{key+1}</td>
                                    <td>{item.subject}</td>
                                    <td>{item.level.level}</td>
                                    <td>{item.abbreviation}</td>
                                    <td>{item.category.category}</td>
                                    <td>{item.type.type_name}</td>
                                    <td>
                                        <span className='action'>
                                            <button type="button" className="btn btn-primary btn-flat btn-pri btn-sm" onClick={()=>this.editRecord(item)}>
                                                <i className='fa fa-pen'/>&nbsp;Edit</button>
                                            <button type="button" className="btn btn-danger btn-flat btn-pri btn-sm">
                                                <i className='fa fa-trash-alt'/>&nbsp;Delete
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            )}
            
                        </tbody>
                        </table>
                        {!list && <ActivityLoader/>}
                    </div>
                    )
                }}
            </ConfigConsumer>
           
        )
    }


    render() {
        return (
            <PageContainer 
                title="Subjects"
                history={{title:"Configurations", link:"/configuration"}}
                headerNav={<this.RenderControls/>}
                >
                        {this.state.mdlCrud}
                        <div className="row">
                            <div className='col-md-9 col-sm-12'>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Subjects</h3>
                                    </div>
                                    <div className="card-body">

                                    <this.RenderTransactions/>

                                    </div>
                                </div>
                        

                            </div>

                            <ConfigMenu/>
                       
                         
                        </div>

                     
        </PageContainer>
        );
    }
}

export default SubjectPage;