import React, { useContext, useState } from 'react';
import dictionary from '../../../util/dictionary';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';
import ajaxReport from '../../../util/remote/ajaxReport';
import ReportContext from '../../../Context/ReportContext';

const ConfigNewComment =(props)=> {

    const [commentTitle, setCommentTitle] = useState("");
    const [commentScope, setCommentScope] = useState("");
    const [commentTag, setCommentTag] = useState("");
    const [info, setInfo] = useState("");
    const { getReportCommentConfig } = useContext(ReportContext)



    const RenderScope=()=>{

      
                const optionsListing=[
                    {label:"General", value:"2"},
                    {label:"Specific", value:"1"}
                ];

                return(
                    <TextInput
                        label="Comment Scope"
                        leftIcon="fa fa-bookmark"
                        type="select" 
                        placeholder="Select comment scope...." 
                        options={optionsListing}
                        onChange={(e)=>setCommentScope(e.value)}
                        leftComponent={<i className="fa fa-bookmark"/>}
                        wrap
                    />
                )
       
        }


        const RenderTag=()=>{

      
            const optionsListing=[
                {label:"NORMAL", value:"NORMAL"},
                {label:"FEES-BALANCE", value:"FEES-BALANCE"},
                {label:"FEES-AMOUNT", value:"FEES-AMOUNT"}
            ];

            return(
                <TextInput
                    label="Comment Tag"
                    leftIcon="fa fa-bookmark"
                    type="select" 
                    placeholder="Select tag...." 
                    options={optionsListing}
                    onChange={(e)=>setCommentTag(e.value)}
                    leftComponent={<i className="fa fa-tag"/>}
                    wrap
                />
            )
   
    }

   
    const confirmSave=async(event)=>{
        event.preventDefault();
  
        if(commentTitle.length>0 && commentScope.length>0 && commentTag.length>0)
        {
            const server_response = await ajaxReport.configureNewComment(
                {
                    "comment_title":commentTitle,
                    "comment_scope":commentScope,
                    "tag":commentTag
                }
            );
            if(server_response.status==="OK")
            {
                setInfo(<AlertSuccess message={server_response.message}/>)
                setCommentTitle("")
                //Refresh comments
                getReportCommentConfig()
            }else{
                setInfo(<AlertError message={server_response.message}/>)
            }

        }else{
            setInfo(<AlertError message={dictionary._completeFields}/>)
        }
    }


  
    return (
            
    <SystemModal id="mdl-new-comment" title="Configure New Comment">
    <form method="post" className="form-horizontal" onSubmit={confirmSave}>
               {info}
               <TextInput
                    type="text"
                    label="Comment Title"
                    placeholder="Comment Title"
                    leftIcon="fa fa-comment"
                    value={commentTitle}
                    onChange={(e)=>{setCommentTitle(e.target.value)}} 
                    required
                    wrap
                    />
               {RenderScope()}
               {RenderTag()}
    

            <div className="row">&nbsp;</div>


       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Comment</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    
}

export default ConfigNewComment;