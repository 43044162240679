import React, { Component, useContext, useEffect, useReducer, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import TextInput from '../../Components/Common/TextInput';
import PageContainer from '../../Components/Headings/PageContainer';
import ContactPerson from '../../Components/Student/ContactPerson';
import ConfigContext from '../../Context/ConfigContext';
import StudentContext from '../../Context/StudentContext';
import ajaxConfig from '../../util/remote/ajaxConfig';
import ajaxStudent from '../../util/remote/ajaxStudent';

const UpdateStudentPage=()=>{

    const params = useLocation();
    const navigate = useNavigate();

    const [studentInfo, setStudentInfo] = useState(params.state?params.state.record:false);

    useEffect(()=>{
        if(!params.state)
        {
            navigate("/")
        }
    }, [])

    const {allClasses, residenceListing} = useContext(ConfigContext);
    const {getStudents} = useContext(StudentContext)

   const [loading, setLoading] = useState(false);
   const [classId, setClassId] = useState(studentInfo.class.class_id);
   const [gender, setGender] = useState(studentInfo.sex);
   const [residenceId, setResidenceId] = useState(studentInfo.residence.id);
   const [statusName, setStatusName] = useState(studentInfo.status_name)

   const [info, setInfo] = useState(false);
   const [streamList, setStreamList] = useState(false);
   const [classLoader, setClassLoader] = useState(true);
   const [streamId, setStreamId] = useState(studentInfo.stream.stream_id);
   const [streamName, setStreamName] = useState(studentInfo.stream.stream_name);
   const [actualFile, setActualFile] = useState("");
   const [fileSource, setFileSource] = useState("");
   const [status, setStatus] = useState(studentInfo.status);
   useEffect(()=>{

        if(allClasses)
        {
            setClassLoader(false)
        }

   }, [allClasses])

   useEffect(()=>{
        if(classId>0)
        {   setClassLoader(true)
            getStreams();
        }
   }, [classId])


   useEffect(()=>{
        if(streamList)
        {
            for(var i=0; i<streamList.length; i++)
            {
                if(streamList[i].stream_id*1===streamId*1)
                {
                    setStreamName(streamList[i].stream_name)
                    return true;
                }
            }
        }
   }, [streamList])


   const initialValues={
       firstName:studentInfo.first_name,
       lastName:studentInfo.last_name,
       birthday:studentInfo.DOB?studentInfo.DOB.input_date:"",
       admissionDate:studentInfo.admission_date?studentInfo.admission_date.input_date:"",
       customID:studentInfo.other_reg
   }

   const [formValues, setFormValues] = useReducer((curVals, newVals)=>({...curVals, ...newVals}), initialValues)
   
   const {firstName, lastName, birthday, admissionDate, customID} = formValues;

   const getStreams=async()=>{

        const server_response = await ajaxConfig.getClassStreams(classId);
        setClassLoader(false)
        if(server_response.status==="OK")
        {
            setStreamList(server_response.details);
        }else{
            setStreamList("404")
        }

   }



   const handleFileChange=(event)=>{
        
    setFileSource(event.target.value)

    let files=event.target.files;
    let reader=new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload=(e)=>{
        setActualFile({file:  e.target.result})
    }
}

   



   const confirmStudent =async(event)=>{

        event.preventDefault();
        if(firstName.length>0 && lastName.length>0 && classId>0 && gender.length>0 && residenceId.length>0)
        {
            const data={
                first_name : firstName,
                last_name : lastName,
                sex : gender,
                class_id : classId,
                stream_id : streamId,
                birthday : birthday,
                admission_date : admissionDate,
                custom_id : customID,
                photo: actualFile,
                student_id: studentInfo.id,
                residence_id: residenceId,
                status: status
            };

            setLoading(true)
            const server_response = await ajaxStudent.updateStudent(data);
            setLoading(false)

            if(server_response.status==="OK")
            {
                setInfo(<AlertSuccess message={server_response.message}/>)
                // setFormValues(initialValues);
                setActualFile("");
                setFileSource("");
                getStudents();
            }else{
                setInfo(<AlertError message={server_response.message}/>)
            }

        }else{
            setInfo(<AlertError message="Select gender and class, then try again"/>)
        }

   }

   const handleFormChange =(event)=>{
        const {name, value} = event.target
        setFormValues({[name]:value})
   }

   

    const RenderClassRoom=()=>{

        const optionsClasses=[];
        if(Array.isArray(allClasses))
        {
            allClasses.map((item, key)=>
            optionsClasses.push({label:item.class_name, value:item.class_id})
            )
        }


        const optionStreams=[];
        if(Array.isArray(streamList))
        {
            streamList.map((item, key)=>
            optionStreams.push({label:item.stream_name, value:item.stream_id})
            )
        }
        
        return( <>
                <TextInput
                    label="Class"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder={studentInfo.class.class_name} 
                    options={optionsClasses}
                    onChange={(item)=>{setClassId(item.value)}}
                    leftComponent={<i className="fa fa-university"></i>}
                    loading={classLoader}
                    />

               {Array.isArray(streamList) && <TextInput
                    label="Stream"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder={streamName} 
                    options={optionStreams}
                    onChange={(item)=>{setStreamId(item.value)}}
                    leftComponent={<i className="fa fa-university"></i>}
                    />}

                   </>)
        
        }

   
        const RenderGender=()=>{

            const options=[{label:"Male", value:"Male"}, {label:"Female", value:"Female"}];
            
            
            return <TextInput
                        label="Gender"
                        type="select" 
                        leftIcon="fa fa-user"
                        placeholder={gender} 
                        options={options}
                        onChange={(item)=>{setGender(item.value)}}
                        leftComponent={<i className="fa fa-user"></i>}
                        />
            
            }


    

  const RenderStatus=()=>{

            const options=[{label:"Current Student", value:"1"}, {label:"Former Student", value:"0"}];
            
            
            return <TextInput
                        label="Status"
                        type="select" 
                        leftIcon="fa fa-check"
                        placeholder={statusName} 
                        options={options}
                        onChange={(item)=>{setStatus(item.value)}}
                        leftComponent={<i className="fa fa-check"></i>}
                        />
            
            }



            const RenderControls=()=>{
                return (
                    <div className='float-sm-right'>
                        <ol className='button-listing'>
                          
                            {/* <li>
                                <Link to="/student/import" className="btn ripple btn-secondary mb-1">
                                    <i className="fa fa-upload mr-1"></i>&nbsp;Import Students</Link>
                            </li> */}
                        </ol>
                       
            
                        
                    </div>
                  
                )
              }












              const RenderResidence=()=>{

                const options=[];
    
                if(Array.isArray(residenceListing)){
                    residenceListing.forEach((item)=>{
                        options.push({label:item.residence_name, value: item.id})
                    })
                }
                
                
                return <TextInput
                            label="Residence"
                            type="select" 
                            leftIcon={true}
                            placeholder={studentInfo.residence.residence_name || "Select residence...."} 
                            options={options}
                            onChange={(item)=>{setResidenceId(item.value)}}
                            leftComponent={<i className="fa fa-map-pin"></i>}
                            />
                
                }
    
    
    





   
        return (
            <PageContainer 
                title="Edit Student Profile"
                headerNav={<RenderControls/>}
                >
                <div className="row">
                    <div className="col-md-9">
                        <CallOut title="Profile Update" description="You can change a group of students' class by promoting them in the academics section."/>
                        {info}
                        <form className="form-horizontal" method="post" onSubmit={confirmStudent}>
                        <div className="row">
                       
                        <div className="col-md-12">


                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Basic info</h3>
                                </div>

                                <div className="card-body">
            
                                
                            
                                        <TextInput
                                            type="text"
                                            label="Last Name"
                                            name="lastName"
                                            placeholder="Enter Surname"
                                            leftIcon="fa fa-user"
                                            value={lastName}
                                            onChange={handleFormChange} 
                                            required
                                        />

                                     <TextInput
                                        type="text"
                                        label="First Name"
                                        name="firstName"
                                        placeholder="First Name"
                                        leftIcon="fa fa-user"
                                        value={firstName}
                                        onChange={handleFormChange} 
                                        required
                                        />

                                
                                        <TextInput
                                            type="date"
                                            label="Birthday"
                                            name="birthday"
                                            placeholder="Birthday"
                                            leftIcon="fa fa-calendar"
                                            value={birthday}
                                            onChange={handleFormChange} 
                                        />

                                        {RenderGender()}

                                        <TextInput
                                            type="file"
                                            label="Student Photo"
                                            name="studentImage"
                                            placeholder="Select photo"
                                            leftIcon="fa fa-image"
                                            value={fileSource}
                                            onChange={handleFileChange} 
                                        />




                                <div className="row">&nbsp;</div>
                                
                            

                                </div>
                            </div>{/** close card */}



                        </div>{/** Left */}


                        <div className="col-md-12">


                        <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Admission info</h3>
                                </div>

                                <div className="card-body">
            
                                    {RenderClassRoom()}
                                

                                    {/* <div className="row">&nbsp;</div> */}

                                        <TextInput
                                            type="date"
                                            label="Admission"
                                            placeholder="Admission Date"
                                            leftIcon="fa fa-calendar"
                                            value={admissionDate}
                                            name="admissionDate"
                                            onChange={handleFormChange} 
                                        />

                                
                                        <TextInput
                                            type="text"
                                            label="Custom ID"
                                            placeholder="Custom ID"
                                            leftIcon="fa fa-hashtag"
                                            value={customID}
                                            name="customID"
                                            onChange={handleFormChange} 
                                            
                                        />



                                        { RenderResidence()}

                                        { RenderStatus()}



                                <div className="row">&nbsp;</div>
                                
                            

                            </div>
                        </div>{/** close card */}



                     


                        </div> {/** Right */}

                      
                      </div>




                    <div className="modal-footer no-border">
                        {!loading && <button type="submit" className="btn btn-primary">Save Changes</button>}
                        {loading && <ActivityLoader/>}
                    </div>

                     

                    </form> 


                    </div>{/** body end */}


                    <div className="col-xl-3 col-lg-3 col-sm-12 d-xl-block">
                        <ContactPerson studentId={studentInfo.id}/>
                    </div>


                

                </div>

        </PageContainer>
        
        );
    
}

export default UpdateStudentPage;