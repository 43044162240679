import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import ListItem from '../../Components/Common/List/ListItem';
import EditClasStream from '../../Components/Config/ClassRoom/EditClasStream';
import NewClasStream from '../../Components/Config/ClassRoom/NewClasStream';
import ConfigMenu from '../../Components/Config/ConfigMenu';
import NewPaper from '../../Components/Config/Subject/NewPaper';
import SubjectPaper from '../../Components/Config/Subject/SubjectPaper';
import SubjectUnit from '../../Components/Config/Subject/SubjectUnit';
import PageContainer from '../../Components/Headings/PageContainer';
import functions from '../../util/functions';
import ajaxConfig from '../../util/remote/ajaxConfig';

const ManageSubjectPage =(props)=> {

    const params = useLocation();
    const navigate = useNavigate();
    const [root, setRoot] = useState(false);
    const [paperListing, setPaperListing] = useState(false);
    const [mdlCrud, buildMdlCrud] = useState(false);
    const [transactionId, setTransactionId] = useState("");
    const [currentRecord, setCurrentRecord] = useState(false);

    useEffect(()=>{
        if(!params.state)
        {
            navigate("/configuration/subjects")
        }else{
            setRoot(params.state.record)
        }
    }, [])
    
   

  

    const getPaperListing=async()=>{
      const server_response = await ajaxConfig.getSubjectPapers(root.subject_id);
      if(server_response.status==="OK")
      {
        setPaperListing(server_response.details)

      }else{

          setPaperListing("404")
      }
    }

    const onSelectRecord=(transactionId, currentRecord)=>{
        setTransactionId(transactionId);
        setCurrentRecord(currentRecord);
    }

    const newRecord=()=>{
       
        if(mdlCrud){
            buildMdlCrud(false);
            setTimeout(() => {
                buildMdlCrud(<NewPaper subjectId={root.subject_id} refresh={getPaperListing}/>)
            }, 10);
        }else{
            buildMdlCrud(<NewPaper subjectId={root.subject_id} refresh={getPaperListing}/>)

        }
    
    }

    const RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                    {/* <li>
                        <Link to="#" className="btn ripple btn-success mb-1" onClick={newRecord}>
                        <i className="fa fa-plus mr-1"></i>&nbsp;Add Paper</Link>
                    </li>
                    <li>
                        <Link to="/configuration/classes" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-edit mr-1"></i>&nbsp;Create Study Unit</Link>
                    </li> */}
                </ol>
               
    
                
            </div>
          
        )
      }
    




  


        return (
            <PageContainer 
                title="Manage Subject"
                history={{title:"Configurations", link:"/configuration/subjects"}}
                headerNav={<RenderControls/>}
                >
                        { mdlCrud }
                        <div className="row">
                            <div className='col-md-9 col-sm-12'>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{root.subject}</h3>
                                    </div>
                                    <div className="card-body">

                                   




            <div className="col-md-12">
                <SubjectPaper subjectId={root.subject_id}/>
                <div className="row mt-5"></div>
                <SubjectUnit subjectId={root.subject_id}/>
                    

          </div>










                                    </div>
                                </div>
                        

                            </div>

                            <ConfigMenu/>
                       
                         
                        </div>

                     
        </PageContainer>
        );
}

export default ManageSubjectPage;