import React, { useEffect, useState } from "react"
import functions from "../../../util/functions"
import GradingScale from "../../Securities/GradingScale"
import LetterHead from "../../Securities/LetterHead"
import StudentHeading from "../../Securities/StudentHeading"
import AdvancedScale from "../Elements/AdvancedScale"
import ClassTeacher from "../Elements/ClassTeacher"
import Disclaimer from "../Elements/Disclaimer"
import ReportRemarks from "../Elements/ReportRemarks"
import PopReportInitials from "../PopReportInitials"
//** BEST FOR ORDINARY LEVEL */
const ReportFormatOne = (props) =>{

    const [mdl, setMdl] = useState(false);

    useEffect(()=>{
        if(mdl)
        {
          functions.openPopup("mdl-report-initials")
          
        }
      }, [mdl])


    const onClickInitials=(paper_id)=>{

        setMdl(false);

        setTimeout(() => {
            setMdl(
            <PopReportInitials
                report_id={props.reportInfo.id}
                paper_id={paper_id}
            />
            )

        }, 10);

  }

    return(
        <>
        {mdl}
        <div id="report-card">

        <LetterHead 
            title={props.reportInfo.title}
            student={props.student.student_info}
            serial={props.student.serial_no}
            />

        <StudentHeading 
            studentInfo={props.student.student_info} 
            report={props.reportInfo} 
            total={props.totalStudents}
            />
      

        <table className="Reports" border="1" cellSpacing="0" width="100%" style={{border:"solid", fontFamily:"Arial, Helvetica, sans-serif", fontSize:"13px"}}>

            <thead>
                <tr>
                <th>SUBJECT</th>
                <th>PAPER</th>
                {Array.isArray(props.reportInfo.report_tests) && props.reportInfo.report_tests.map((item, key)=>
                    <th key={key}>{item.test.exam_name} ({item.out_of.total_c})</th>
                )}
                <th style={{textAlign:"center"}}>TOTAL</th>
                <th  style={{textAlign:"center"}}>GRADE</th>
                <th>REMARK</th>
                <th style={{textAlign:"center"}}>INITIALS</th>
                </tr>
            </thead>
            <tbody>
                {Array.isArray(props.student.student_marks.marks) && props.student.student_marks.marks.map((grade_record, grade_key)=> <React.Fragment key={grade_key}>
                    
                    {Array.isArray(grade_record.grades.paper_listing) && grade_record.grades.paper_listing.map((item_paper, paper_key)=>
                    <tr key={paper_key}>

                        {paper_key===0 && <td rowSpan={grade_record.grades.total_papers}>{grade_record.subject.subject}</td>}

                        <td key={paper_key}>{item_paper.paper_code}</td>
                        

                        

                        {/* Print exam marks */}
                        {Array.isArray(props.reportInfo.report_tests) && props.reportInfo.report_tests.map((period_mark, period_mark_key)=>
                            <td key={period_mark_key}>
                                { grade_record.grades.marks[period_mark.test_id] && grade_record.grades.marks[period_mark.test_id][item_paper.paper_id] &&
                                
                                <span>{grade_record.grades.marks[period_mark.test_id][item_paper.paper_id].mark}</span>}
                            </td>
                        )}

                        <td  style={{textAlign:"center"}}><b>{grade_record.grades.total_mark[item_paper.paper_id]}</b></td>
                        <td  style={{textAlign:"center"}}><b>{grade_record.grades.grade[item_paper.paper_id].grade}</b></td>
                        <td>{grade_record.grades.grade[item_paper.paper_id].auto_comment}</td>
                        <td style={{textAlign:"center", cursor:"pointer"}} onClick={()=>onClickInitials(item_paper.paper_id)} >
                            {props.initials && props.initials[item_paper.paper_id] && props.initials[item_paper.paper_id].initials}
                        </td>

                    </tr>
                    )}

                </React.Fragment>
                )}


                  {Array.isArray(props.reportInfo.report_tests) &&<tr>
                    <td>&nbsp;</td>
                    <td style={{textAlign:"right"}}><b>&nbsp;</b></td>
                    <td colSpan={props.reportInfo.report_tests.length} style={{textAlign:"left", background:"#FFF", color:"#000"}}><b>DIV {props.student.student_marks.ordinary_grade.division}</b></td>


                    <td style={{textAlign:"center"}}><b>AGG&nbsp;</b></td>
                    <td style={{textAlign:"center", background:"#000", color:"#FFF"}}><b>{props.student.student_marks.ordinary_grade.aggregates}</b></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    
                </tr>}
               
            </tbody>

        </table>
        <ReportRemarks remarks={props.student.report_comments} noBorders={true}/>

        <GradingScale/>
        {/* <GradingScale/> */}
        {/* <ClassTeacher/> */}

        <Disclaimer/>
        <p style={{pageBreakAfter:"always"}}></p>

</div>
</>
    )

}

export default ReportFormatOne