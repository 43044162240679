import React, { useEffect, useState } from 'react';
import AlertError from '../Components/Common/AlertError';
import ajaxReport from '../util/remote/ajaxReport';

const ReportContext = React.createContext();

export const ReportConsumer=ReportContext.Consumer;

export const ReportProvider=(props)=> {

        const [page, setPage] = useState(1);
        const [reportList, setReportList] = useState(false);
        const [metaData, setMetaData] = useState(false);
        const [currentReportId, setCurrentReportId] = useState(false);
        const [currentReport, setCurrentReport] = useState(false);
        const [reportCards, setReportCards] = useState("500");
        const [loading, setLoading] = useState(false);
        const [info, setInfo] = useState(false);
        const [studentListing, setStudentListing] = useState(false);
        const [totalStudents, setTotalStudents] = useState(false);
        const [reportCommentListing, setReportCommentListing] = useState(false);
        const [termlyReports, setTermlyReports] = useState(false);
        const [marksheet, setMarksheet] = useState(false);
        const [currentPageNumber, setCurrentPageNumber] = useState(1);
        const [studentMeta, setStudentMeta] = useState(false);
        const [totalReportCount, setTotalReportCount] = useState("...");
        const [currentReportCount, setCurrentReportCount] = useState("...");
        const [viewReport, setViewReport] = useState(false);
        const [studentId, setStudentId] = useState(false)


        useEffect(()=>{
            getReports();
        }, [page])

        useEffect(()=>{
            
            if(currentReportId){
                generateReports(true);
                if(!studentId) generateMarksheet(true);
            }

        }, [currentReportId, studentId])



        useEffect(()=>{
            getReportCommentConfig();
            listTermlyReports();
            getTotalReports();
            getCurrentTotalReports();
        }, [])

        const getReports=async()=>{
            const server_response = await ajaxReport.getReports({
                "page":page
            })

            if(server_response.status==="OK")
            {
                setReportList(server_response.details.list)
                setMetaData(server_response.details.meta)
            }else{
                setMetaData("404")
            }
        }

        const generateReports=async(init)=>{
            if(init)
            {
                setReportCards(false)
                setViewReport(false)

            }
            const server_response = await ajaxReport.generateReport({
                "report_id":currentReportId,
                "page":currentPageNumber,
                "student_id":studentId
            });
            if(server_response.status==="OK")
            {
                setReportCards(server_response.details.report_info);
                setStudentListing(server_response.details.students.student_listing);
                setTotalStudents(server_response.details.students.total_students);
                setCurrentReport(server_response.details);
                setStudentMeta(server_response.details.students.meta_data);
                setCurrentPageNumber(server_response.details.students.meta_data.current_page)

            }else{
                setReportCards("404");
                setInfo(<AlertError message={server_response.message}/>)
                setTotalStudents(false);
                setCurrentReport(false);
            }
        }
    
        const getReportCommentConfig=async()=>{

            const server_response = await ajaxReport.listReportCommentConfig();

            if(server_response.status==="OK")
            {
               setReportCommentListing(server_response.details)

            }else{
                setReportCommentListing(false)
            }
        }

        const listTermlyReports=async()=>{

            const server_response = await ajaxReport.getCurrentReports();

            if(server_response.status==="OK")
            {
               setTermlyReports(server_response.details)

            }else{
                setTermlyReports("404")
            }
        }




        const generateMarksheet=async(init)=>{
            if(init)
            {
                setMarksheet(false)

            }
            const server_response = await ajaxReport.generateMarksheet({
                "report_id":currentReportId
            });

            if(server_response.status==="OK")
            {
                setMarksheet(server_response.details);

            }else{
                // alert(server_response.message);
                setMarksheet("404");
            }
        }





        const fetchMore=()=>{

            // setCurrentPageNumber(currentPageNumber+1);
            setLoading(true);
        
            setTimeout(async() => {
                const data = {
                    "report_id":currentReportId,
                    "page":currentPageNumber*1 + 1
                }

                const server_response = await ajaxReport.generateReport(data);
        
                setLoading(false);
        
                if (server_response.status === "OK") {

                    setCurrentPageNumber(server_response.details.students.meta_data.current_page)
                    const incoming = server_response.details.students.student_listing;
                    setStudentListing({...studentListing, ...incoming })
                    setStudentMeta(server_response.details.students.meta_data);

                  
                }
        
        
            }, 10);
        
        }


        const getTotalReports=async()=>{
            const server_response = await ajaxReport.callCountAllReports()
            if(server_response.status==="OK")
            {
                setTotalReportCount(server_response.details.total_p)
            }
        }

        const getCurrentTotalReports=async()=>{
            const server_response = await ajaxReport.callCountCurrentReports()
            if(server_response.status==="OK")
            {
                setCurrentReportCount(server_response.details.total_p)
            }
        }
     
        return (
           <ReportContext.Provider value={
                {
                  reportList,
                  reportCards,
                  loading,
                  currentReportId,
                  currentReport,
                  studentListing,
                  totalStudents,
                  reportCommentListing,
                  termlyReports,
                  marksheet,
                  studentMeta,
                  currentReportCount,
                  totalReportCount,
                  viewReport,
                  getReports,
                  setCurrentReportId,
                  setReportCommentListing,
                  generateReports,
                  generateMarksheet,
                  listTermlyReports,
                  fetchMore,
                  getTotalReports,
                  getCurrentTotalReports,
                  setViewReport,
                  setStudentId,
                  getReportCommentConfig
                }
               }>
               {props.children}
           </ReportContext.Provider>
        );
    
}

export default ReportContext;