import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StudentContext from '../../Context/StudentContext';
import ActivityLoader from '../Common/ActivityLoader';
import BarChart from '../Graph/BarChart';

class CurrentPopulation extends Component {
  static contextType = StudentContext

    render() {

      const {studentPopulation} = this.context;
      if(studentPopulation){
          const labels = [];
          const values = [];

          studentPopulation.classes.map((item)=>{
            labels.push(item.class_name)
            values.push(studentPopulation.break_down[item.class_id].total)
          })

          const data={
            label:labels,
            value:values
          }


        return (
            <div className="card">
              <div className="card-header border-0">
                <div className="d-flex justify-content-between">
                  <h3 className="card-title">Students Population</h3>
                  <Link to="#">View Report</Link>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <p className="d-flex flex-column">
                    <span className="text-bold text-lg">{studentPopulation.total_population.total_p}</span>
                    <span>Students</span>
                  </p>
                  <p className="ml-auto d-flex flex-column text-right">
                    <span className="text-success">
                      <i className="fas fa-graduation-cap"></i> ....
                    </span>
                    <span className="text-muted">Alumni</span>
                  </p>
                </div>

                <div className="position-relative mb-4">
                  {/* <canvas id="sales-chart" height="200"></canvas> */}
                  <BarChart data={data} title="Current Population"/>
                </div>

               
              </div>
            </div>
        );
    }else{

        return <ActivityLoader/>

    }
  }
}

export default CurrentPopulation;