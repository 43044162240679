import React, { useContext, useEffect, useState } from 'react';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import PageContainer from '../../Components/Headings/PageContainer';
import MarkEntryFilter from '../../Components/Marks/MarkEntryFilter';
import MarkEntryContext from '../../Context/MarkEntryContext';


import { Grid, Input } from 'react-spreadsheet-grid'
import ajaxMark from '../../util/remote/ajaxMark';



const EnterMarksPage =(props)=> {

    const { 
            info,
            marksList,
            getMarksEntryForm
        } = useContext(MarkEntryContext);


        const [rows, setRows] = useState(Array.isArray(marksList)?marksList:[]);

        useEffect(()=>{
            if(Array.isArray(marksList))
            {
                setRows(marksList)
            }
        }, [marksList])

        // A callback called every time a value changed.
        // Every time it save a new value to the state.
        const onMarkChange = (rowId, field) => (value) => {
            // Find the row that is being changed
            addMark(rowId, value)
           
        }

        const onRemarkChange = (rowId, field) => (value) => {
            addRemark(rowId, value)
           
        }
        
        const initColumns = () => [
          
            {
                title: () => 'No.',
                value: (row, { focus }) => {
                    return (
                        <Input
                            value={row.n}
                            focus={focus}
                        />
                    );
                }
                }, {
                title: () => 'Reg No.',
                value: (row, { focus }) => {
                    return (
                        <Input
                        value={row.student.reg_no}
                        focus={focus}
                    />
                    );
                }
                },

                {
                    title: () => 'Student Name',
                    value: (row, { focus }) => {
                        return (
                            <Input
                            value={row.student.first_name + " " + row.student.last_name}
                            focus={focus}
                        />
                        );
                    }
                },


                {
                    title: () => 'Class',
                    value: (row, { focus }) => {
                        return (
                            <Input
                            value={row.student.class.class_name}
                            focus={focus}
                        />
                        );
                    }
                },


                {
                    title: () => 'Mark',
                    value: (row, { focus }) => {
                        return (
                            <Input
                                value={row.mark==null?"":row.mark}
                                focus={focus}
                                onChange={onMarkChange(row.id, 'name')}
                        />
                        );
                    }
                },


                {
                    title: () => 'Remark',
                    value: (row, { focus }) => {
                        return (
                            <Input
                            value={row.remark==="N/A"?"":row.remark}
                            focus={focus}
                            onChange={onRemarkChange(row.id, 'name')}
                        />
                        );
                    }
                    }



          ]



        const RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                    {/* <li>
                        <Link to="#" className="btn ripple btn-success mb-1">
                        <i className="fa fa-edit mr-1"></i>&nbsp;Create Custom List</Link>
                    </li>
                    <li>
                        <Link to="/configuration/classes" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-bookmark mr-1"></i>&nbsp;Alumni</Link>
                    </li> */}
                </ol>
               
    
                
            </div>
          
        )
        }


        const addMark=async(id, value)=>{
            const server_response = await ajaxMark.enterMark({
                mark_id : id,
                mark : value
            })
            if(server_response.status==="OK")
            {
                getMarksEntryForm()
            }
        }

        const addRemark=async(id, value)=>{
            const server_response = await ajaxMark.enterMark({
                mark_id : id,
                remark : value
            })
            if(server_response.status==="OK")
            {
                getMarksEntryForm()
            }
        }
    

        return (
            <PageContainer 
                title="Enter Marks"
                // history={{title:"Configurations", link:"/configuration"}}
                headerNav={<RenderControls/>}
                >
                        <div className="row">
                            <div className='col-md-9 col-sm-12'  style={{height:"550px", overflow:"auto"}}>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Enter marks for current term: Select class/stream and subject</h3>
                                    </div>
                                    <div className="card-body">

                                    {/* {RenderTransactions()} */}

                                    {Array.isArray(marksList) && <Grid
                                        columns={initColumns()}
                                        rows={rows}
                                        isColumnsResizable
                                        onColumnResize={()=>{return false}}
                                        getRowKey={row => row.id}
                                
                                    />}

                                
                                    {!marksList && <ActivityLoader/>}
                                    {info}
                                    {marksList===500 && <AlertError type="info" message="Select class and subject to continue"/>}


                                    </div>
                                </div>
                        

                            </div>

                            <MarkEntryFilter/>
                       
                         
                        </div>

                     
        </PageContainer>
        );
}

export default EnterMarksPage;