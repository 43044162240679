import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import PageContainer from '../../Components/Headings/PageContainer';
import MarkSheetFormatOne from '../../Components/Report/Template/MarkSheet/MarkSheetFormatOne';
import MarkSheetSelector from '../../Components/Report/Template/MarkSheet/MarkSheetSelector';
import ReportContext from '../../Context/ReportContext';
import functions from '../../util/functions';




const MarkSheetPage =(props)=> {

    const {info, marksheet} = useContext(ReportContext);
    let navigate = useNavigate();

    useEffect(()=>{
        if(marksheet==="404")
        {
           navigate("/report/card")
        }
    }, [])

    const RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                     <li>
                        <Link to="#" onClick={onPrint} className="btn ripple btn-primary mb-1">
                        <i className="fa fa-print mr-1"></i>&nbsp;Print</Link>
                    </li>
                      {/* 
                    <li>
                        <Link to="/configuration/classes" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-bookmark mr-1"></i>&nbsp;Alumni</Link>
                    </li> */}
                </ol>
               
    
                
            </div>
          
        )
        }


       

    const onPrint=()=>{
        functions.printElement("marksheet-print");
    }
        
       

        return (
            <PageContainer 
                title="General Marks Sheet"
                // history={{title:"Configurations", link:"/configuration"}}
                headerNav={<RenderControls/>}
                >
                        <div className="row">
                            <div className='col-md-12 col-sm-12'  style={{height:"550px", overflow:"auto"}}>

                                <div className="card">
                                    <div className="card-header">
                                       {marksheet && <h3 className="card-title">{marksheet.report_info.title}</h3>}
                                    </div>
                                    <div className="card-body" id="marksheet-print">
                                        {info}
                                        
                                        {marksheet && marksheet!=="404"&& <MarkSheetSelector marksheet={marksheet}/>}
                                              

                                        {!marksheet && <ActivityLoader/>}
                                        {marksheet==="404" && <AlertError type="info" message="Failed to load marksheet"/>}
                                    </div>
                                </div>
                        

                            </div>

                          
                         
                        </div>

                     
        </PageContainer>
        );
}

export default MarkSheetPage;