import React, { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import ReportContext from "../../Context/ReportContext"
import functions from "../../util/functions"
import ActivityLoader from "../Common/ActivityLoader"
import PopReportPreview from "../Report/PopReportPreview"

const TermlyReports=()=>{

    const {termlyReports, setCurrentReportId} = useContext(ReportContext)
    const [mdlPreview, setMdlPreview] = useState(false)

    let navigate = useNavigate();

    const onChangeReport=(id)=>{
        setCurrentReportId(id)
        navigate("/report/card", {replace:true})
    }

    useEffect(()=>{
        if(mdlPreview)
        {
            functions.openPopup("mdl-report-preview")
        }
    }, [mdlPreview])

    const onClickReport=(item)=>{
        if(mdlPreview)
        {
            setMdlPreview(false);
            setTimeout(() => {
                setMdlPreview(<PopReportPreview title={item.title} info={item}/>)
            }, 10);
        }else{
            setMdlPreview(<PopReportPreview title={item.title} info={item}/>)

        }
    }

    return(
        <>
        {mdlPreview}
        <div className="card custom-card">
								<div className="card-body">
									<div>
										<h6 className="card-title mb-1">Current Academic Reports</h6>
									</div>
								</div>
								<div className="card-body scroll-widget overflow-auto border-top" style={{maxHeight:"250px"}}>
									<div className="activity-block">
                                        {!termlyReports && <ActivityLoader/>}
										<ul className="task-list">
											
                                            {Array.isArray(termlyReports) && termlyReports.map((item, key)=><li key={key}>
												<i className="task-icon bg-success"></i>
												<h6 onClick={()=>onChangeReport(item.id)}><Link to="#">{item.title}</Link>
                                                <small className="float-right text-muted tx-11">{item.report_date.short_date}</small></h6>
												<span className="text-muted tx-12">{item.class.class_name + " Term " + item.term.term + " " + item.year}</span>
											</li>)}
										
										</ul>
									</div>
								</div>
							</div>
                </>
    )

}

export default TermlyReports